<template>
  <v-container v-if="$opoIden.value == 'Bomberos'" style="margin-top: 50px">
    <h1 style="margin-bottom: 35px">¿Qué vamos a estudiar hoy?</h1>
    <v-card style="background: white; border-radius: 0px; padding: 25px">
      <CustomTestComponent />
    </v-card>
    <p></p>
  </v-container>
  <FalladasTestComponent v-else></FalladasTestComponent>
</template>

<script>
import CustomTestComponent from "../components/CustomTestComponent.vue";
import Clases from "../components/Clases.vue";
import FalladasTestComponent from "../components/FalladasTestComponent.vue";
// import { getTestsByTag } from "@/utils.js";
export default {
  name: "MainView",
  components: { CustomTestComponent, FalladasTestComponent },
  data() {
    return {
      // oficiales: [],
      // officialSelected: null,
    };
  },
  // watch: {
  //   officialSelected: function () {
  //     this.$router.push({
  //       name: "test",
  //       params: { testId: this.officialSelected.id, mode: "regular" },
  //     });
  //   },
  // },
  // async mounted() {
  //   this.oficiales = await getTestsByTag("OFICIALES");
  //   function compare(a, b) {
  //     if (a.title < b.title) {
  //       return -1;
  //     }
  //     if (a.title > b.title) {
  //       return 1;
  //     }
  //     return 0;
  //   }
  //   this.oficiales.sort(compare);
  // },
};
</script>

<style></style>
