<template>
  <v-card outlined>
    <v-card-text>
      <p class="display-1 text--primary">Administración de contribuidores</p>
      <p class="caption-3 text--secondary">
        Estás viendo esto porque eres administrador
      </p>
      <v-row v-if="loading" align="center" justify="center">
        <v-col class="text-center">
          <v-progress-circular :size="100" indeterminate />
        </v-col>
      </v-row>
      <div v-else>
        <v-card outlined>
          <v-card-title>
            <v-btn
              style="float: right"
              class="mx-2"
              fab
              dark
              small
              color="blue"
              @click="loadContributors"
            >
              <v-icon dark> mdi-refresh </v-icon>
            </v-btn>
            <v-text-field
              v-model="search"
              label="Búsqueda | Añadir contribuidor por email"
              prepend-inner-icon="mdi-magnify"
              single-line
              filled
              dense
              rounded
              hide-details
            />
            <v-btn
              style="float: right"
              class="mx-2"
              fab
              dark
              small
              color="green"
              @click="addContributor"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-card-title>
          <v-data-table
            :headers="contributorsTable.headers"
            :items="contributorsData"
            :search="search"
            :sort-by="['email']"
            :sort-desc="true"
            class="elevation-1"
            dense
          >
            <template v-slot:item.actions="{ item }">
              <v-icon small @click="deleteItem(item)"> mdi-close </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  getFirestore,
  collection,
  doc,
  query,
  where,
  getDocs,
  getDoc,
  setDoc,
  addDoc,
  onSnapshot,
  deleteDoc,
} from "firebase/firestore";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  signOut,
} from "firebase/auth";

export default {
  name: "AdministrarContributors",
  data() {
    return {
      search: "",
      loading: false,
      userEmailToAdd: null,
      contributorsTable: {
        headers: [
          {
            text: "ID de usuario",
            value: "uid",
          },
          {
            text: "Email",
            value: "email",
          },
          {
            text: "Nombre",
            value: "displayname",
          },
          {
            text: "Roles",
            value: "claims",
          },
          {
            text: "Acciones",
            value: "actions",
            sortable: false,
          },
        ],
      },
      contributorsData: [],
    };
  },
  mounted() {
    //this.loadContributors()
  },
  methods: {
    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    async addContributor() {
      if (!this.validateEmail(this.search.trim()))
        alert("El email no es válido");
      else {
        if (
          confirm(
            '¿Seguro que quieres añadir el rol de contribuidor a "' +
              this.search.trim() +
              '"?'
          )
        ) {
          await this.updateClaim("contributor", true, this.search.trim());
        }
      }
    },
    async updateClaim(claim, valueToUpdate, emailToUpdate) {
      this.loading = true;
      const db = getFirestore();
      const auth = getAuth();
      const user = auth.currentUser;
      const request = {
        requester: user.email,
        requestedAt: new Date(),
        claim: claim,
        valueToUpdate: valueToUpdate,
        emailToUpdate: emailToUpdate,
      };
      const docRef = await addDoc(
        collection(db, "admin/users", "update_claims_requests"),
        request
      );
      //console.log(docRef);
      onSnapshot(docRef, async (snap) => {
        const { error, success } = snap.data();
        if (error) {
          alert(`An error occured: ${error}`);
          this.loading = false;
        }
        if (success) {
          alert("El cambio se ha realizado correctamente");
          this.loading = false;
        }
      });
    },
    async deleteItem(item) {
      if (
        confirm(
          "¿Seguro que quieres retirar el rol de contribuidor a " +
            item.email +
            "?"
        )
      ) {
        const index = this.contributorsData.indexOf(item);
        await this.updateClaim("contributor", false, item.email);
        this.contributorsData.splice(index, 1);
      }
    },
    async loadContributors() {
      this.loading = true;
      const db = getFirestore();
      const auth = getAuth();
      const user = auth.currentUser;
      const request = {
        requester: user.email,
        requestedAt: new Date(),
        claim: "contributor",
      };
      const docRef = await addDoc(
        collection(db, "admin/users", "list_contributors_requests"),
        request
      );
      console.log(docRef);
      onSnapshot(docRef, async (snap) => {
        const { error, users } = snap.data();
        if (error) {
          alert(`An error occured: ${error}`);
          this.loading = false;
        }
        if (users) {
          this.contributorsData = users;
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style></style>
