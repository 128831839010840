var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"q"}},[(_vm.imgUrl)?_c('v-img',{staticStyle:{"margin-bottom":"20px"},attrs:{"src":_vm.imgUrl,"aspect-ratio":16 / 9,"contain":""}}):_vm._e(),_c('h4',{staticStyle:{"text-align":"start"}},[_vm._v(" "+_vm._s(_vm.number)+".- "+_vm._s(_vm.question.question.text)+" ")]),_c('v-radio-group',{attrs:{"value":_vm.selected,"mandatory":false,"readonly":_vm.readonly || _vm.revealed}},_vm._l((Object.keys(_vm.question.question.options).sort()),function(t){return _c('v-radio',{key:t,attrs:{"value":t},on:{"click":function($event){return _vm.onClick(t)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [((_vm.readonly || _vm.revealed) && _vm.question.question.correcta == t)?_c('div',[_c('strong',{staticStyle:{"color":"darkgreen"}},[_vm._v(_vm._s(_vm.question.question.options[t]))])]):_c('div',[_vm._v(" "+_vm._s(_vm.question.question.options[t])+" ")])]},proxy:true}],null,true)})}),1),(
      (_vm.readonly || _vm.revealed) &&
      (('detalle' in _vm.question.question &&
        _vm.question.question.detalle !== null &&
        _vm.question.question.detalle.length > 0) ||
        _vm.detailImgUrl)
    )?_c('div',{staticClass:"text-body-2 explicacion"},[_c('div',[_vm._v(" "+_vm._s(_vm.question.question.detalle)+" "),(_vm.detailImgUrl)?_c('v-img',{staticStyle:{"margin-bottom":"20px"},attrs:{"src":_vm.detailImgUrl,"aspect-ratio":16 / 9,"contain":""}}):_vm._e()],1)]):_vm._e(),(_vm.readonly || _vm.revealed)?_c('v-row',{staticStyle:{"font-weight":"600"}},[_c('v-col',[_c('v-btn',{staticStyle:{"font-size":"1em"},attrs:{"outlined":!_vm.inventada,"block":"","color":"error"},on:{"click":function($event){_vm.inventada = true;
          _vm.deducida = false;
          _vm.segura = false;}}},[_vm._v("Inventada")])],1),_c('v-col',[_c('v-btn',{staticStyle:{"font-size":"1em"},attrs:{"block":"","outlined":!_vm.deducida,"color":"orange","dark":""},on:{"click":function($event){_vm.inventada = false;
          _vm.deducida = true;
          _vm.segura = false;}}},[_vm._v("Deducida")])],1),_c('v-col',[_c('v-btn',{staticStyle:{"font-size":"1em"},attrs:{"block":"","outlined":!_vm.segura,"color":"success"},on:{"click":function($event){_vm.inventada = false;
          _vm.deducida = false;
          _vm.segura = true;}}},[_vm._v("Segura")])],1)],1):_vm._e(),(!_vm.last)?_c('v-divider',{staticStyle:{"margin-top":"20px"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }