import Vue from "vue";
import VueRouter from "vue-router";
//import store from "@/store";
import EntrarView from "@/views/EntrarView";
import Profile from "@/views/Profile";
import Verify from "@/views/Verify";
import getCurrentUser from "../getCurrentUser";
import MainView from "@/views/MainView";
import AlreadyLogged from "@/views/AlreadyLogged";
import Simulations from '@/views/Simulations';
import ResetPassword from "@/components/ResetPassword";
import TestExam from "@/views/TestExam";
import TestInput from "@/views/TestInput";
import TestCategory from "@/views/TestCategory";
import SubmissionReview from "@/views/SubmissionReview";
import AdminPanel from "@/views/AdminPanel";
import ContributorPanel from "@/views/ContributorPanel";
import NotFound from "@/views/NotFound.vue";
import SubmissionHistory from "@/components/SubmissionHistory.vue";
import MyCollections from "@/views/QuestionCollections";
import Collection from "@/views/SingleCollection";
import MyImpugnations from "@/components/MyImpugnations";
import Ranking from "@/components/Ranking";
import FalladasTestComponent from "@/components/FalladasTestComponent";
import OficialesComponent from "@/components/OficialesComponent";
import Clases from '@/components/Clases';
import OpoIdSelector from '@/components/OpoIdenSelector';
import DocumentViewer from "@/components/DocumentViewer.vue";
import TestsPorTemas from '@/components/TestsPorTemas.vue';
import { getDoc, doc, getFirestore } from "firebase/firestore";

Vue.use(VueRouter);

const NONE = 0;
const REGISTERED = 1;
const VALIDATED = 2;
const PREMIUM = 3;
const ADMIN = 4;
const CONTRIBUTOR = 5;

const routes = [
  {
    name: "entrar",
    path: "/entrar",
    component: EntrarView,
    meta: { accessLevel: NONE },
  },
  {
    name: "reset_password",
    path: "/reset_password",
    component: ResetPassword,
    meta: { accessLevel: NONE },
  },
  {
    name: "verify_email",
    path: "/verify",
    component: Verify,
    meta: { accessLevel: REGISTERED },
  },
  {
    name: "opo_selector",
    path: "/oposicion",
    component: OpoIdSelector,
    meta: { accessLevel: REGISTERED },
  },
  {
    name: "already_logged",
    path: "/already_logged",
    component: AlreadyLogged,
    meta: { accessLevel: REGISTERED },
  },
  {
    name: "home",
    path: "/",
    component: MainView,
    meta: { accessLevel: VALIDATED, title: "Plataforma Oposiciones" },
  },
  {
    name: "profile",
    path: "/profile",
    component: Profile,
    meta: { accessLevel: VALIDATED, title: "Mi perfil" },
  },
  {
    name: "submission_review",
    path: "/sub_review/:submissionId",
    component: SubmissionReview,
    meta: { accessLevel: VALIDATED },
    props: true,
  },
  {
    name: "history",
    path: "/history",
    component: SubmissionHistory,
    meta: { accessLevel: VALIDATED, title: "Historial" },
    props: true,
  },
  {
    name: "falladas_test",
    path: "/falladas",
    component: FalladasTestComponent,
    meta: { accessLevel: VALIDATED, title: "Test de errores" },
    props: true,
  },
  {
    name: "oficiales",
    path: "/oficiales",
    component: OficialesComponent,
    meta: { accessLevel: VALIDATED, title: "Test oficiales" },
    props: true,
  },
  {
    name: "impugnations",
    path: "/impugnations",
    component: MyImpugnations,
    meta: { accessLevel: VALIDATED, title: "Impugnaciones" },
    props: true,
  },
  {
    name: "ranking",
    path: "/ranking",
    component: Ranking,
    meta: { accessLevel: VALIDATED, title: "Ranking" },
  },
  {
    name: "collections",
    path: "/mycollections",
    component: MyCollections,
    meta: { accessLevel: VALIDATED, title: "Mis colecciones" },
  },
  {
    name: "collection",
    path: "/collection/:collectionId",
    component: Collection,
    meta: { accessLevel: VALIDATED, title: "Colección" },
    props: true,
  },
  {
    name: "clases",
    path: "/clases",
    component: Clases,
    meta: { accessLevel: PREMIUM, title: "Clases" },
  },
  {
    name: "document",
    path: "/document",
    component: DocumentViewer,
    meta: { accessLevel: PREMIUM, title: "Documento" },
    props: true
  },
  {
    name: "content",
    path: "/content/:code?",
    component: TestCategory,
    meta: { accessLevel: PREMIUM, title: "Temario" },
    props: true
  },
  {
    name: "test",
    path: "/test/:testId",
    component: TestExam,
    meta: { accessLevel: PREMIUM },
    props: true,
  },
  {
    name: "simulacros",
    path: "/simulacros",
    component: Simulations,
    meta: { accessLevel: VALIDATED },
  },
  {
    name: "tests",
    path: "/tests",
    component: TestsPorTemas,
    meta: { accessLevel: VALIDATED },
  },
  {
    name: "simulacro",
    path: "/simulacro/:testId",
    component: TestExam,
    meta: { accessLevel: VALIDATED },
    props: true,
  },
  {
    name: "test_input",
    path: "/test_input/:testIdToEdit?",
    component: TestInput,
    meta: { accessLevel: CONTRIBUTOR },
    props: true,
  },
  {
    name: "admin",
    path: "/admin",
    component: AdminPanel,
    meta: { accessLevel: ADMIN },
    props: false,
  },
  {
    name: "contributor",
    path: "/contributor",
    component: ContributorPanel,
    meta: { accessLevel: CONTRIBUTOR },
    props: false,
  },
  {
    name: "not_found",
    path: "/*",
    component: NotFound,
    meta: { accessLevel: NONE },
    props: false,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title || "Plataforma Oposiciones";
  const user = await getCurrentUser();
  if (user) {
    const userFirestore = (await getDoc(doc(getFirestore(), 'users', user.uid))).data()
    if (!userFirestore.opoId) {
      if (to.name !== 'opo_selector') {
        next({ name: "opo_selector" });
      }
    }
    else {
      Vue.prototype.$opoIden.value = userFirestore.opoId;
    }
  }
  if (to.name == "entrar" && user) {
    next({ name: "already_logged" });
  }
  if (to.meta.accessLevel == NONE) {
    next();
  } else {
    if (to.meta.accessLevel == REGISTERED) {
      if (user) next();
      else next({ path: "/entrar" });
    } else if (to.meta.accessLevel == VALIDATED) {
      if (user && user.emailVerified) next();
      else if (!user) next({ path: "/entrar" });
      else if (!user.emailVerified) next({ path: "/verify" });
    } else if (to.meta.accessLevel == PREMIUM) {
      if (
        user &&
        user.emailVerified &&
        (user.premium || user.premiumAcademy || user.userIsContributor)
      )
        next();
      else if (!user) next({ path: "/entrar" });
      else if (!user.emailVerified) next({ path: "/verify" });
      else if (
        !(user.premium || user.premiumAcademy || user.userIsContributor)
      ) {
        alert("Necesitas una suscripcion para acceder al contenido");
        next({ path: "/profile" });
      }
    } else if (to.meta.accessLevel == CONTRIBUTOR) {
      if (user && user.emailVerified && user.userIsContributor) next();
      else if (!user) next({ path: "/entrar" });
      else if (!user.emailVerified) next({ path: "/verify" });
      else if (!user.userIsContributor) {
        alert("Necesitas ser contribuidor");
        next({ path: "/profile" });
      }
    } else if (to.meta.accessLevel == ADMIN) {
      if (user && user.emailVerified && user.userIsAdmin) next();
      else if (!user) next({ path: "/entrar" });
      else if (!user.emailVerified) next({ path: "/verify" });
      else if (!user.userIsAdmin) {
        alert("Acceso denegado");
        next({ path: "/" });
      }
    }
  }
});

export default router;
