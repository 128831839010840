<template>
  <v-container>
    <h1
      style="
        padding-bottom: 20px;
        font-family: 'Saira Semi Condensed', sans-serif;
      "
    >
      Clases
      <v-icon @click="newItemDialog = true">mdi-plus-box</v-icon>
    </h1>
    <div
      v-if="!loading"
      style="padding-bottom: 20px; padding-top: 10px; cursor: pointer"
    >
      <v-data-table
        :headers="itemsTable.headers"
        :items="itemsData"
        :search="search"
        :sort-by="['endDate']"
        :sort-desc="true"
        class="elevation-1"
        no-data-text="No hay clases"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
        <template v-slot:item.color="{ item }">
          <v-chip x-small :color="item.color"></v-chip>
        </template>
        <template v-slot:item.recorded_class_url="{ item }">
          <template v-if="item.recorded_class_url">{{
            item.recorded_class_url
          }}</template>
          <v-chip
            v-else
            x-small
            @click="
              itemBeingEdited = item;
              recordedURLDialog = true;
            "
          >
            Introducir
          </v-chip>
        </template>
      </v-data-table>
      <v-snackbar
        v-model="showErrorSnackbar"
        timeout="3000"
        text
        color="error"
        style="margin-bottom: 60px"
      >
        <span>{{ snackError }}</span>
      </v-snackbar>
      <v-snackbar
        v-model="showSuccessSnackbar"
        timeout="3000"
        text
        color="success"
        style="margin-bottom: 60px"
      >
        <span>{{ snackSuccess }}</span>
      </v-snackbar>
    </div>

    <v-dialog v-model="recordedURLDialog" persistent width="450">
      <v-card class="pa-2">
        <v-card-title>Introduce el link de la clase grabada</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="recordedURLDialogValue"
            label="Link de la clase grabada"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="pa-2">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              recordedURLDialogValue = null;
              recordedURLDialog = false;
            "
            >Cancelar</v-btn
          >
          <v-btn tile color="success" @click="setRecordedURL">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="newItemDialog" persistent width="450">
      <v-card class="pa-4">
        <v-card-title>Nueva clase</v-card-title>
        <v-card-text>
          <v-select
            v-model="newItemData.opoId"
            :items="availableOpoIds"
            label="Oposición"
          ></v-select>
          <v-text-field v-model="newItemData.name" label="Nombre de la clase" />
          <v-text-field
            v-model="newItemData.start"
            type="datetime-local"
            label="Inicio"
          ></v-text-field>
          <v-text-field
            v-model="newItemData.end"
            type="datetime-local"
            label="Fin"
          ></v-text-field>
          <v-text-field
            v-model="newItemData.testId"
            label="Test ID (opcional)"
            :rules="[
              (value) =>
                value.length == 0 ||
                value.length == 20 ||
                'Los test ID son de 20 caracteres',
            ]"
          />
          <v-text-field
            v-model="newItemData.live_class_url"
            type="link"
            label="Enlace clase en directo"
          ></v-text-field>
          <v-color-picker
            v-model="newItemData.color"
            hide-canvas
            hide-inputs
            hide-sliders
            :swatches="swatches"
            show-swatches
          ></v-color-picker>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              newItemData = {};
              newItemDialog = false;
            "
            >Cancelar</v-btn
          >
          <v-btn tile color="success" @click="submitItem">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { getClassesByOpoId } from "@/utils.js";
import {
  addDoc,
  collection,
  doc,
  getFirestore,
  getDoc,
  deleteDoc,
  updateDoc,
} from "@firebase/firestore";
import { isReactNative } from "@firebase/util";

export default {
  data: () => ({
    loading: true,
    search: "",
    rawItems: null,
    itemsData: null,
    itemsTable: {
      headers: [
        {
          text: "Opo ID",
          value: "opoId",
          sortable: true,
        },
        {
          text: "Nombre",
          value: "name",
          sortable: false,
        },
        {
          text: "Inicio",
          value: "start",
        },
        {
          text: "Fin",
          value: "end",
          sortable: false,
        },
        {
          text: "Test ID",
          value: "testId",
          sortable: false,
        },
        {
          text: "Link Directo",
          value: "live_class_url",
          sortable: false,
        },
        {
          text: "Link Grabada",
          value: "recorded_class_url",
          sortable: false,
        },
        {
          text: "Color",
          value: "color",
          sortable: false,
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
        },
      ],
    },
    snackError: "",
    snackSuccess: "",
    showErrorSnackbar: false,
    showSuccessSnackbar: false,
    newItemDialog: false,
    availableOpoIds: ["Bomberos", "Mossos d'Esquadra"],
    newItemData: {},
    swatches: [
      ["#F44336", "#E91E63", "#9C27B0"],
      ["#3F51B5", "#2196F3", "#4CAF50"],
      ["#FF9800", "#795548", "#607D8B"],
    ],
    recordedURLDialogValue: null,
    recordedURLDialog: false,
    itemBeingEdited: null,
  }),
  async created() {
    await this.loadItems();
  },
  watch: {
    snackError() {
      this.showErrorSnackbar = true;
    },
    snackSuccess() {
      this.showSuccessSnackbar = true;
    },
  },
  methods: {
    mapItems(rawItems) {
      return rawItems.map((item) => {
        return {
          id: item.id,
          opoId: item.opoId,
          name: item.name,
          start: item.start.toLocaleString(),
          end: item.end.toLocaleString(),
          color: item.color,
          live_class_url: item.live_class_url,
          recorded_class_url: item.recorded_class_url,
          testId: item.testId,
        };
      });
    },
    async loadItems() {
      this.loading = true;
      try {
        this.rawItems = await getClassesByOpoId("all");
        this.itemsData = this.mapItems(this.rawItems);
      } catch (error) {
        alert(error);
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    isValidURL(str) {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(str);
    },
    async validateNewItemData() {
      if (!this.newItemData.opoId) {
        this.snackError = "Selecciona una oposición";
      } else if (!this.newItemData.name) {
        this.snackError = "Introduce un nombre válido";
      } else if (!this.newItemData.name) {
        this.snackError = "Introduce un nombre válido";
      } else if (
        !this.newItemData.live_class_url ||
        !this.isValidURL(this.newItemData.live_class_url)
      ) {
        this.snackError = "Introduce un link de clase en directo válido";
      } else if (!this.newItemData.start) {
        this.snackError = "Introduce una fecha de inicio válida";
      } else if (!this.newItemData.end) {
        this.snackError = "Introduce una fecha de fin válida";
      } else if (!this.newItemData.color) {
        this.snackError = "Selecciona un color para la clase";
      } else if (
        new Date(this.newItemData.end) < new Date(this.newItemData.start)
      ) {
        this.snackError = "La fecha de fin es anterior a la inicio";
      } else {
        return true;
      }
    },
    async submitItem() {
      if (!(await this.validateNewItemData())) {
        return;
      } else {
        try {
          this.newItemData.start = new Date(this.newItemData.start);
          this.newItemData.end = new Date(this.newItemData.end);
          this.newItemData.color = this.newItemData.color.hex;
          this.newItemData.timed = true;
          const docRef = await addDoc(
            collection(getFirestore(), "classes"),
            this.newItemData
          );
          this.snackSuccess =
            "Clase añadida satisfactoriamente con ID " + docRef.id;
          this.newItemDialog = false;
          await this.loadItems();
        } catch (error) {
          this.snackError = "Algo no fue como debía";
        }
      }
    },
    async deleteItem(item) {
      if (
        confirm(
          "¿Seguro que quieres la clase " +
            item.name +
            "? Esta acción es definitiva y no se puede deshacer"
        )
      ) {
        const index = this.itemsData.indexOf(item);
        this.itemsData.splice(index, 1);
        const db = getFirestore();
        await deleteDoc(doc(db, "classes", item.id));
        this.snackSuccess = "La clase ha sido eliminada";
      }
    },
    async setRecordedURL() {
      const item = this.itemBeingEdited;
      if (!this.isValidURL(this.recordedURLDialogValue)) {
        this.snackError = "Introduce un link válido";
        return;
      }
      try {
        await updateDoc(doc(getFirestore(), "classes", item.id), {
          recorded_class_url: this.recordedURLDialogValue,
        });
        this.snackSuccess = "Link de clase grabada correctamente actualizado";
        await this.loadItems();
        this.recordedURLDialog = false;
        this.recordedURLDialogValue = null;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
</style>