<template>
  <div id="q">
    <v-img
      v-if="imgUrl"
      :src="imgUrl"
      :aspect-ratio="16 / 9"
      style="margin-bottom: 20px"
      contain
    />
    <h4 style="text-align: start">
      {{ number }}.- {{ question.question.text }}
    </h4>
    <v-radio-group
      :value="selected"
      :mandatory="false"
      :readonly="readonly || revealed"
    >
      <v-radio
        v-for="t in Object.keys(question.question.options).sort()"
        :key="t"
        :value="t"
        @click="onClick(t)"
      >
        <template v-slot:label>
          <div v-if="(readonly || revealed) && question.question.correcta == t">
            <strong style="color: darkgreen">{{
              question.question.options[t]
            }}</strong>
          </div>
          <div v-else>
            {{ question.question.options[t] }}
          </div>
        </template>
      </v-radio>
    </v-radio-group>
    <div
      v-if="
        (readonly || revealed) &&
        (('detalle' in question.question &&
          question.question.detalle !== null &&
          question.question.detalle.length > 0) ||
          detailImgUrl)
      "
      class="text-body-2 explicacion"
    >
      <div>
        {{ question.question.detalle }}
        <v-img
          v-if="detailImgUrl"
          :src="detailImgUrl"
          :aspect-ratio="16 / 9"
          style="margin-bottom: 20px"
          contain
        />
      </div>
    </div>
    <v-row v-if="readonly || revealed" style="font-weight: 600">
      <v-col
        ><v-btn
          :outlined="!inventada"
          block
          color="error"
          style="font-size: 1em"
          @click="
            inventada = true;
            deducida = false;
            segura = false;
          "
          >Inventada</v-btn
        ></v-col
      >
      <v-col
        ><v-btn
          block
          :outlined="!deducida"
          color="orange"
          dark
          style="font-size: 1em"
          @click="
            inventada = false;
            deducida = true;
            segura = false;
          "
          >Deducida</v-btn
        ></v-col
      >
      <v-col
        ><v-btn
          block
          :outlined="!segura"
          color="success"
          style="font-size: 1em"
          @click="
            inventada = false;
            deducida = false;
            segura = true;
          "
          >Segura</v-btn
        ></v-col
      >
    </v-row>
    <v-divider v-if="!last" style="margin-top: 20px" />
  </div>
</template>

<script>
export default {
  name: "Question",
  props: {
    question: {
      type: Object,
      required: true,
    },
    last: Boolean,
    readonly: Boolean,
    number: {
      type: Number,
      required: true,
    },
    instant: Boolean,
  },
  data() {
    return {
      selected: this.question.answer,
      revealed: this.instant && !!this.question.answer,
      imgUrl: this.question.question.imgUrl,
      detailImgUrl: this.question.question.detailImgUrl,
      inventada: false,
      deducida: false,
      segura: false,
    };
  },
  watch: {
    selected() {
      this.$emit("option-selected", this.question.index, this.selected);
      if (this.instant) {
        this.revealed = true;
      }
    },
    inventada() {
      this.$emit("set-certainty", this.question.index, "inventada");
    },
    deducida() {
      this.$emit("set-certainty", this.question.index, "deducida");
    },
    segura() {
      this.$emit("set-certainty", this.question.index, "segura");
    },
  },
  created() {},
  methods: {
    onClick: function (answer) {
      if (!(this.readonly || this.revealed)) {
        if (this.selected === answer) {
          this.selected = null;
        } else {
          this.selected = answer;
        }
      }
    },
  },
};
</script>

<style scoped>
#q {
  margin-bottom: 20px;
  padding-left: 10%;
  padding-right: 10%;
  alignment: center;
}
.explicacion {
  background: hsl(0, 0%, 90%);
  padding: 16px;
  text-align: justify;
  margin-bottom: 25px;
}
</style>
