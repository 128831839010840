var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',{staticStyle:{"padding-bottom":"20px","font-family":"'Saira Semi Condensed', sans-serif"}},[_vm._v(" Calendario ")]),_c('v-row',{staticClass:"fill-height"},[_c('v-col',[_c('v-sheet',{attrs:{"height":"64"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{staticClass:"mr-4",attrs:{"tile":"","outlined":"","color":"grey darken-2"},on:{"click":_vm.setToday}},[_vm._v(" Hoy ")]),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.prev}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-left ")])],1),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.next}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-right ")])],1),(_vm.$refs.calendar)?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e(),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"tile":"","outlined":"","color":"grey darken-2"}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.typeToLabel[_vm.type]))]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.type = 'day'}}},[_c('v-list-item-title',[_vm._v("Día")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'week'}}},[_c('v-list-item-title',[_vm._v("Semana")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'month'}}},[_c('v-list-item-title',[_vm._v("Mes")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = '4day'}}},[_c('v-list-item-title',[_vm._v("4 días")])],1)],1)],1)],1)],1),_c('v-sheet',{attrs:{"height":"600"}},[_c('v-calendar',{ref:"calendar",attrs:{"color":"primary","events":_vm.events,"event-color":_vm.getEventColor,"type":_vm.type,"weekdays":_vm.weekdays},on:{"click:event":_vm.showEvent,"click:more":_vm.viewDay,"click:date":_vm.viewDay},model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"activator":_vm.selectedElement,"offset-x":""},model:{value:(_vm.selectedOpen),callback:function ($$v) {_vm.selectedOpen=$$v},expression:"selectedOpen"}},[_c('v-card',{attrs:{"color":"grey lighten-4","width":"400px","flat":""}},[_c('v-toolbar',{attrs:{"color":_vm.selectedEvent.color,"dark":""}},[_c('v-toolbar-title',[_c('strong',[_c('small',[_vm._v(_vm._s(_vm.selectedEvent.name))])])]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.selectedOpen = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('div',[(
                    _vm.selectedEvent.live_class_url &&
                    !_vm.selectedEvent.recorded_class_url
                  )?_c('v-chip',{staticClass:"my-3",staticStyle:{"cursor":"pointer","display":"block"},attrs:{"tile":"","label":"","large":"","outlined":"","color":_vm.selectedEvent.color},on:{"click":function($event){return _vm.openLink(_vm.selectedEvent.live_class_url)}}},[_c('v-icon',{staticClass:"mx-2"},[_vm._v("mdi-link")]),_vm._v(" Clase en directo ")],1):(
                    _vm.selectedEvent.recorded_class_url &&
                    _vm.selectedEvent.recorded_class_url.length > 0
                  )?_c('v-chip',{staticClass:"my-3",staticStyle:{"cursor":"pointer","display":"block"},attrs:{"tile":"","label":"","large":"","outlined":"","color":_vm.selectedEvent.color},on:{"click":function($event){return _vm.openLink(_vm.selectedEvent.recorded_class_url)}}},[_c('v-icon',{staticClass:"mx-2"},[_vm._v("mdi-play-box")]),_vm._v(" Clase grabada ")],1):_vm._e(),(_vm.selectedEvent.testId)?_c('v-chip',{staticClass:"my-3",staticStyle:{"cursor":"pointer","display":"block"},attrs:{"tile":"","label":"","large":"","outlined":"","color":_vm.selectedEvent.color},on:{"click":function($event){return _vm.$router.push({
                      name: 'test',
                      params: { testId: _vm.selectedEvent.testId },
                    })}}},[_c('v-icon',{staticClass:"mx-2"},[_vm._v("mdi-checkbox-marked-outline")]),_vm._v(" Test ")],1):_vm._e(),(_vm.selectedEvent.documentUrl)?_c('v-chip',{staticClass:"my-3",staticStyle:{"cursor":"pointer","display":"block"},attrs:{"tile":"","label":"","large":"","outlined":"","color":_vm.selectedEvent.color},on:{"click":function($event){return _vm.$router.push({
                      name: 'document',
                      params: { resourceUrl: _vm.selectedEvent.documentUrl },
                    })}}},[_c('v-icon',{staticClass:"mx-2"},[_vm._v("mdi-file-pdf-box")]),_vm._v(" Temario ")],1):_vm._e()],1)])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }