<template>
  <v-progress-circular v-if="loading" :size="100" indeterminate />

  <v-container v-else>
    <v-dialog v-model="uploading" persistent width="450">
      <v-card color="black" dark>
        <v-card-text style="padding: 20px">
          Subiendo tu test... Esto puede tardar unos segundos
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col
        ><v-btn
          class="mx-2"
          style="margin-bottom: 15px"
          dark
          color="blue"
          @click="submitTest()"
        >
          CONFIRMAR
        </v-btn></v-col
      >
      <v-col
        ><v-text-field
          v-model="title"
          placeholder="Título del test"
          hint="Título del test"
          autocomplete="off"
          solo
          style="width: 600px; font-size: 22px"
      /></v-col>
    </v-row>

    <v-select
      v-if="!loading"
      v-model="tags"
      :items="availableCategories"
      label="Categorías"
      multiple
      chips
    />
    <v-text-field
      v-model="numOptions"
      type="number"
      autocomplete="off"
      label="Número de respuestas"
    />
    <!-- <v-checkbox v-model="isTimed" label="Simulacro" />
    <div v-if="isTimed" style="width: 300px">
      <v-radio-group v-model="simulation_mode">
        <v-radio label="Abierto" :value="SimulationMode.OPEN" />
        <v-radio
          label="Solo compradores"
          :value="SimulationMode.ONLY_PURCHASERS"
        />
        <v-radio
          label="Compradores y alumnos"
          :value="SimulationMode.PURCHASERS_AND_PREMIUM"
        />
        <v-radio label="Solo alumnos" :value="SimulationMode.ONLY_PREMIUM" />
      </v-radio-group>
      <v-text-field
        v-model="maxSeconds"
        type="number"
        autocomplete="off"
        label="Duración examen en segundos"
      />
      <v-text-field
        v-model="startDate"
        type="datetime-local"
        label="Fecha de inicio"
      />
      <v-text-field
        v-model="endDate"
        type="datetime-local"
        label="Fecha de finalización"
      />
      <v-text-field
        v-model="resultsDate"
        type="datetime-local"
        label="Fecha de resultados"
      />
    </div> -->
    <v-row
      v-for="(q, index) in questions"
      :key="index"
      style="padding-bottom: 20px; font-size: 3em"
    >
      {{ index + 1 }}
      <v-col :style="index % 2 == 0 ? 'background-color: white;' : ''">
        <v-textarea
          v-model="q.text"
          auto-grow
          outlined
          rows="1"
          type="text"
          autocomplete="off"
          label="Pregunta"
        />
        <v-select
          v-model="q.dificulty"
          :items="['Fácil', 'Media', 'Difícil']"
          label="Dificultad"
        />
        <v-select
          v-model="q.tags"
          :items="availableCategories"
          label="Categorías"
          multiple
          chips
        ></v-select>
        <v-img
          v-if="q.imgUrl"
          :src="q.imgUrl"
          :aspect-ratio="16 / 9"
          :height="200"
          style="margin-bottom: 20px"
          contain
        />
        <v-btn
          v-if="q.imgUrl"
          color="error"
          x-small
          @click="
            q.imgUrl = null;
            q.questionImage = null;
          "
        >
          Quitar imagen
        </v-btn>
        <v-file-input
          v-model="q.questionImage"
          prepend-icon="mdi-camera"
          accept="image/png, image/jpeg"
          label="Subir nueva imagen de pregunta"
          show-size
          :multiple="false"
          v-on="{ change: (e) => onChangeFileInput(e, q) }"
        />

        <div>
          <v-textarea
            v-for="opt in Object.keys(q.options).sort()"
            :key="opt"
            v-model="q.options[opt]"
            auto-grow
            outlined
            rows="1"
            type="text"
            autocomplete="off"
            :label="opt"
          />
          <v-row>
            <v-radio-group v-model="q.correcta" row>
              <v-radio
                v-for="opt in Object.keys(q.options).sort()"
                :key="opt"
                :label="opt"
                color="red"
                :value="opt"
              />
            </v-radio-group>
          </v-row>
        </div>

        <v-textarea
          v-model="q.detalle"
          auto-grow
          outlined
          rows="1"
          type="text"
          autocomplete="off"
          label="Explicación"
        />
        <v-img
          v-if="q.detailImgUrl"
          :src="q.detailImgUrl"
          :aspect-ratio="16 / 9"
          :height="200"
          style="margin-bottom: 20px"
          contain
        />
        <v-btn
          v-if="q.detailImgUrl"
          color="error"
          x-small
          @click="
            q.detailImgUrl = null;
            q.detailImage = null;
          "
        >
          Quitar imagen
        </v-btn>
        <v-file-input
          v-model="q.detailImage"
          prepend-icon="mdi-camera"
          accept="image/png, image/jpeg"
          label="Subir nueva imagen de explicación"
          show-size
          :multiple="false"
          v-on="{ change: (e) => onChangeDetailFileInput(e, q) }"
        />
        <v-divider />
      </v-col>

      <v-btn
        class="mx-2"
        fab
        dark
        small
        color="primary"
        @click="questions.splice(index, 1)"
      >
        <v-icon dark> mdi-delete </v-icon>
      </v-btn>
    </v-row>

    <v-btn class="mx-2" fab dark color="red" @click="insertEmptyQuestion">
      <v-icon dark> mdi-plus </v-icon>
    </v-btn>
    <v-btn class="mx-2" fab dark color="blue" @click="submitTest()">
      <v-icon dark> mdi-upload </v-icon>
    </v-btn>
  </v-container>
</template>

<script>
//import { SimulationMode } from "@/models/simulation_mode/interface.ts";
import {
  addDoc,
  collection,
  setDoc,
  getDoc,
  doc,
  getFirestore,
  updateDoc,
} from "@firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
} from "@firebase/storage";
import getCurrentUser from "@/getCurrentUser";
import { getTags } from "@/utils";

// const OPEN = 0; // All registered users
// const ONLY_PURCHASERS = 1; // Only users that paid for the simulation
// const PURCHASERS_AND_PREMIUM = 2; // Members of the academy and payers
// const ONLY_PREMIUM = 3; // Only members of the academy

// const SimulationMode = {
//   OPEN: OPEN,
//   ONLY_PURCHASERS: ONLY_PURCHASERS,
//   PURCHASERS_AND_PREMIUM: PURCHASERS_AND_PREMIUM,
//   ONLY_PREMIUM: ONLY_PREMIUM,
// };

export default {
  name: "TestInput",
  // components: { QuestionInput },
  props: {
    testIdToEdit: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      title: null,
      questions: [],
      numOptions: 4,
      created: null,
      updated: null,
      tags: [],

      // isTimed: null,
      // maxSeconds: null,
      // startDate: null,
      // endDate: null,
      // resultsDate: null,
      // simulation_mode: null,
      // SimulationMode: SimulationMode,

      loading: true,
      availableCategories: null,
      isEditing: true,
      newTestId: null,
      currentUser: null,
      authorDisplayName: null,
      authorUserId: null,
      uploading: false,

      testId: null,
    };
  },
  async created() {
    this.availableCategories = await getTags(true, true);
    this.availableCategories = this.availableCategories.map((e) => {
      return { text: e.name, value: e.code };
    });

    this.db = getFirestore();
    this.currentUser = await getCurrentUser();

    if (this.testIdToEdit) {
      const test = await this.getExam(this.testIdToEdit);
      this.title = test.title;
      this.tags = test.tags;
      this.authorDisplayName = test.authorDisplayName;
      this.authorUserId = test.authorUserId;
      this.created = test.created;
      // this.isTimed = test.isTimed;
      // this.maxSeconds = test.maxSeconds;
      // this.simulation_mode = test.simulation_mode;

      // this.startDate = test.startDate
      //   ? test.startDate.toDate().toISOString().slice(0, 16)
      //   : null;
      // this.endDate = test.endDate
      //   ? test.endDate.toDate().toISOString().slice(0, 16)
      //   : null;
      // this.resultsDate = test.resultsDate
      //   ? test.resultsDate.toDate().toISOString().slice(0, 16)
      //   : null;
      this.questions = test.questions;
      this.loading = false;
    } else {
      this.newTestId = this.guidGenerator();
      this.authorDisplayName = this.currentUser.displayName;
      this.authorUserId = this.currentUser.uid;
      this.loading = false;
    }
    this.testId = this.newTestId ? this.newTestId : this.testIdToEdit;
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (
        !window.confirm(
          "¿Seguro que quieres salir? Los cambios no se guardarán."
        )
      ) {
        return;
      }
    }
    next();
  },
  methods: {
    onChangeFileInput(e, q) {
      if (q.questionImage) {
        const storage = getStorage();
        //const storageRef = ref(storage, 'question_images/qid.jpeg');
        const storageRef = ref(storage, `question_images/${q.qid}.jpeg`);
        console.log(q.questionImage);

        uploadBytes(storageRef, q.questionImage).then(() => {
          getDownloadURL(storageRef).then((downloadURL) => {
            console.log("File at ", downloadURL);
            q.imgUrl = downloadURL;
          });
          console.log("Uploaded a blob or file!");
        });
      } else {
        q.imgUrl = null;
      }
    },
    onChangeDetailFileInput(e, q) {
      if (q.detailImage) {
        const storage = getStorage();
        const storageRef = ref(storage, `detail_images/${q.qid}.jpeg`);
        console.log(q.detailImage);

        uploadBytes(storageRef, q.detailImage).then(() => {
          getDownloadURL(storageRef).then((downloadURL) => {
            console.log("File at ", downloadURL);
            q.detailImgUrl = downloadURL;
          });
          console.log("Uploaded a blob or file!");
        });
      } else {
        q.detailImgUrl = null;
      }
    },
    async getQuestion(qid) {
      const q = await getDoc(doc(this.db, "questions", qid));
      return { qid: q.id, ...q.data() };
    },
    async getExam(testId) {
      const slimTest = await getDoc(doc(this.db, "tests", testId));
      const slimTestData = slimTest.data();
      slimTestData.questions = await Promise.all(
        slimTestData.questionIds.map((qid) => this.getQuestion(qid))
      );
      return slimTestData;
    },
    preprocessQuestions(questions) {
      const cleaned = questions;
      cleaned.forEach((element) => {
        delete element.questionImage;
        delete element.detailImage;
      });
      return cleaned;
    },

    async registerQuestion(question) {
      const clean = question;
      delete clean.questionImage;
      delete clean.detailImage;
      question.tags = this.amplifyTags(question.tags);
      const questionRef = doc(this.db, "questions", question.qid);
      await setDoc(questionRef, clean);
    },

    async registerQuestions(questions) {
      await Promise.all(questions.map((q) => this.registerQuestion(q)));
    },

    amplifyTags(tags) {
      function amplify(tag) {
        if (!tag.includes("..")) return [tag];
        const nodes = tag.split("..");
        const subtags = [nodes[0]];
        let subst = nodes[0];
        const otherNodes = nodes.slice(1);
        for (const node of otherNodes) {
          subtags.push(subst + ".." + node);
          subst = subst + ".." + node;
        }
        return subtags;
      }
      function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      }
      const amplified = [];
      for (const tag of tags) {
        amplified.push(...amplify(tag));
      }
      return amplified.filter(onlyUnique);
    },

    async registerSlimTest() {
      const slimTest = {
        title: this.title,
        tags: this.amplifyTags(this.tags),
        questionIds: this.questions.map((q) => q.qid),
        length: this.questions.length,
        created: this.newTestId ? new Date() : this.created,
        updated: new Date(),
        authorDisplayName: this.authorDisplayName,
        authorUserId: this.authorUserId,
        userCreated: false,
      };
      const testRef = doc(this.db, "tests", this.testId);
      await setDoc(testRef, slimTest);
    },

    async registerRenderedTest() {
      const renderedTest = {
        title: this.title,
        tags: this.amplifyTags(this.tags),
        questionIds: this.questions.map((q) => q.qid),
        questions: this.preprocessQuestions(this.questions),
        length: this.questions.length,
        created: this.newTestId ? new Date() : this.created,
        updated: new Date(),
        authorDisplayName: this.authorDisplayName,
        authorUserId: this.authorUserId,
        updaterDisplayName: this.currentUser.displayName,
        updaterUserId: this.currentUser.uid,
        userCreated: false,
      };

      await addDoc(
        collection(this.db, "tests", this.testId, "renders"),
        renderedTest
      );
    },

    async registerTest() {
      await this.registerQuestions(this.questions);
      console.log("questions were registered!");
      await this.registerSlimTest();
      console.log("slim test registered!");
      await this.registerRenderedTest();
      console.log("rendered test registered!");
    },

    async submitTest() {
      if (this.isValid())
        if (confirm("¿Seguro que quieres subir el test?")) {
          try {
            this.uploading = true;
            await this.registerTest();
            alert(
              "El test se ha subido correctamente. El ID del test es " +
                this.testId
            );
            this.isEditing = false;
            this.$router.go(-1);
          } catch (error) {
            this.uploading = false;
            alert(error);
          }
        }
    },
    guidGenerator() {
      function makeid(length) {
        var result = "";
        var characters =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
          result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
          );
        }
        return result;
      }
      return makeid(20);
    },
    insertEmptyQuestion() {
      if (this.numOptions < 1 || this.numOptions > 10) {
        alert("Selecciona un número de opciones entre 1 y 10");
        return;
      }
      const q = {
        text: null,
        options: Object.fromEntries(
          [...Array(parseInt(this.numOptions)).keys()].map((x) => [
            String.fromCharCode("a".charCodeAt(0) + x),
            null,
          ])
        ),
        correcta: null,
        detalle: null,
        qid: this.guidGenerator(),
        questionImage: null,
        detailImage: null,
        dificulty: "Media",
        references: [],
        tags: this.amplifyTags(this.tags),
        appearsInTests: [this.testId],
        imgUrl: null,
        detailImgUrl: null,
        detailVideoUrl: null,
        authorDisplayName: this.currentUser.displayName,
        authorUserId: this.currentUser.uid,
        isContributed: false,
      };
      for (let i = 1; i <= 10; i++) {
        q[`r${i}`] = Math.random();
      }
      this.questions.push(q);
    },
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = "";
    },
    isValid() {
      let message = "";
      if (!this.title) message += "\n Falta el título";

      // if (this.isTimed) {
      //   if (!this.startDate)
      //     message += "\n Falta la fecha de inicio del simulacro";
      //   if (!this.endDate) message += "\n Falta la fecha de fin del simulacro";
      //   if (!this.resultsDate)
      //     message +=
      //       "\n Falta la fecha de publicación de resultados del simulacro";
      //   if (!this.maxSeconds) message += "\n Falta la duración del simulacro";
      //   if (this.simulation_mode == null)
      //     message += "\n Falta la modalidad del simulacro";
      // }

      for (let i = 0; i < this.questions.length; ++i) {
        const q = this.questions[i];

        let missing = false;
        for (const [_, optVal] of Object.entries(this.questions[i].options)) {
          if (!optVal) missing = true;
        }
        if (!(q.text && q.correcta) || missing) {
          message = message + "\n La pregunta " + (i + 1) + " está incompleta"; //TODO: Optionificar
        }
      }
      if (message) {
        alert(message);
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped>
/* .page {
  padding: 190px;
  padding-top: 40px;
  background: lightgray;
  height: 100%;
} */
</style>
