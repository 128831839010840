var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',{staticStyle:{"padding-bottom":"20px","font-family":"'Saira Semi Condensed', sans-serif"}},[_vm._v(" Impugnaciones ")]),_c('v-dialog',{attrs:{"persistent":"","max-width":"450"},model:{value:(_vm.impugnationResolutionDialog),callback:function ($$v) {_vm.impugnationResolutionDialog=$$v},expression:"impugnationResolutionDialog"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-card-title',[_vm._v(_vm._s(_vm.impugnationResolutionDecision)+" impugnación")]),_c('v-card-subtitle',{staticStyle:{"text-align":"start"}},[_vm._v(" Si quieres dar alguna aclaración al alumno hazlo aquí ")]),_c('v-row',{staticClass:"pa-3"},[_c('v-col',[_c('v-textarea',{attrs:{"label":"Explicación","outlined":"","counter":"120"},model:{value:(_vm.impugnationResolutionText),callback:function ($$v) {_vm.impugnationResolutionText=$$v},expression:"impugnationResolutionText"}})],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.impugnationResolutionDialog = false;
            _vm.impugnationResolutionText = '';}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"tile":"","color":"success"},on:{"click":_vm.resolveImpugnation}},[_vm._v(" "+_vm._s(_vm.impugnationResolutionDecision)+" ")])],1)],1)],1),(_vm.questionIdBeingEdited)?_c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.editQuestionDialog),callback:function ($$v) {_vm.editQuestionDialog=$$v},expression:"editQuestionDialog"}},[_c('QuestionEdit',{attrs:{"qid":_vm.questionIdBeingEdited,"availableCategories":_vm.availableCategories},on:{"close-dialog":function($event){_vm.editQuestionDialog = false}}})],1):_vm._e(),(_vm.loading)?_c('v-row',{attrs:{"align":"center","justify":"center"}},[(_vm.loading || !_vm.impugnationsData)?_c('v-data-table',{attrs:{"headers":_vm.impugnationsTable.headers,"loading":"","loading-text":"Cargando tus impugnaciones... Por favor espera"}}):_vm._e()],1):_c('div',[_c('v-chip-group',{attrs:{"mandatory":"","active-class":"primary--text"}},[_c('v-chip',{on:{"click":function($event){_vm.search = ''}}},[_vm._v("Todas")]),_c('v-chip',{on:{"click":function($event){_vm.search = ' Barrios'}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account-circle-outline ")]),_vm._v("Ramón")],1),_c('v-chip',{on:{"click":function($event){_vm.search = 'Arnau Velasco'}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account-circle-outline ")]),_vm._v("Arnau")],1),_c('v-chip',{on:{"click":function($event){_vm.search = 'Marcial'}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account-circle-outline ")]),_vm._v("Marcial")],1)],1),_c('div',{staticStyle:{"padding-bottom":"20px","padding-top":"10px","cursor":"pointer"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.impugnationsTable.headers,"items":_vm.impugnationsData,"search":_vm.search,"sort-by":['timestamp'],"sort-desc":true,"no-data-text":"Todo resuelto"},scopedSlots:_vm._u([{key:"item.edit_q",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){{
                _vm.impugnationBeingResolved = item.id;
                _vm.impugnationResolutionDecision = 'Rechazar';
                _vm.impugnationResolutionDialog = true;
              }}}},[_vm._v(" mdi-close ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){{
                _vm.impugnationBeingResolved = item.id;
                _vm.impugnationResolutionDecision = 'Aprobar';
                _vm.impugnationResolutionDialog = true;
              }}}},[_vm._v(" mdi-check ")])]}}])}),_c('v-snackbar',{staticStyle:{"margin-bottom":"60px"},attrs:{"timeout":"3000","text":"","color":"success"},model:{value:(_vm.showImpugnationSnackbar),callback:function ($$v) {_vm.showImpugnationSnackbar=$$v},expression:"showImpugnationSnackbar"}},[_vm._v(" Impugnación resuelta correctamente ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }