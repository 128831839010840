<template>
  <v-container v-if="!loading">
    <h1
      style="
        padding-bottom: 20px;
        font-family: 'Saira Semi Condensed', sans-serif;
      "
    >
      {{ collection.title
      }}<v-btn icon @click="deleteDeck">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </h1>

    <v-container light v-if="questions.length == 1">
      <p style="color: black; padding: 40px">
        Parece que la colección está vacía. <br />
        Añade preguntas desde un test ya realizado de tu
        <router-link :to="{ name: 'history' }">Historial</router-link>
      </p>
    </v-container>

    <v-dialog
      v-model="editCardDialog"
      :max-width="$vuetify.breakpoint.smAndDown ? 'auto' : '40%'"
    >
      <v-card>
        <v-img
          v-if="cardIdBeingEdited.question.imgUrl"
          :src="cardIdBeingEdited.question.imgUrl"
        ></v-img>
        <v-container style="padding: 30px">
          <v-form @submit.prevent="onCardEditConfirmation">
            <v-textarea
              v-model="cardIdBeingEdited.question.text"
              auto-grow
              outlined
              rows="1"
              row-height="15"
              type="text"
              label="Pregunta"
              readonly
            />
            <v-textarea
              v-model="
                cardIdBeingEdited.question.options[
                  cardIdBeingEdited.question.correcta
                ]
              "
              auto-grow
              outlined
              rows="1"
              row-height="15"
              type="text"
              label="Respuesta"
              readonly
            />
            <p
              v-if="cardIdBeingEdited.question.detalle"
              style="background: #ededed"
              class="pa-4"
            >
              {{ cardIdBeingEdited.question.detalle }}
            </p>
            <v-img
              v-if="cardIdBeingEdited.question.detailImgUrl"
              :src="cardIdBeingEdited.question.detailImgUrl"
            ></v-img>
            <div
              style="
                display: flex;
                justify-content: space-evenly;
                padding-top: 20px;
              "
            >
              <v-btn text @click="editCardDialog = false"> Cancelar </v-btn>
              <v-btn tile color="error" @click.stop="deleteCardById()">
                Eliminar
              </v-btn>
            </div>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="newCardDialog"
      :max-width="$vuetify.breakpoint.smAndDown ? 'auto' : '40%'"
      persistent
    >
      <v-card>
        <v-card-title> Nueva tarjeta </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="newCard.question"
            auto-grow
            label="Pregunta"
            rows="1"
            row-height="15"
            type="text"
          ></v-textarea>
          <v-textarea
            v-model="newCard.answer"
            auto-grow
            label="Respuesta"
            rows="1"
            row-height="15"
            type="text"
          ></v-textarea>
          <v-textarea
            v-model="newCard.detail"
            auto-grow
            label="Explicación (opcional)"
            rows="1"
            row-height="15"
            type="text"
          ></v-textarea>
        </v-card-text>
        <div class="pa-5" style="display: flex; justify-content: space-evenly">
          <v-btn
            text
            @click="
              newCard = {};
              newCardDialog = false;
            "
            >Cancelar</v-btn
          >
          <v-btn tile color="success" @click="addCard">Aceptar</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-container v-if="questions.length > 0">
      <v-layout row wrap>
        <v-flex v-for="q in questions" :key="q.id">
          <v-card
            flat
            style="margin: 30px; background-color: transparent"
            min-width="250px"
          >
            <div flat class="text-center" style="cursor: pointer">
              <v-img
                :aspect-ratio="4 / 3"
                class="media group-images"
                v-on="
                  q.question.text == '+'
                    ? {
                        click: (e) => {
                          newCardDialog = true;
                        },
                      }
                    : { click: (e) => editCard(e, q) }
                "
                :style="
                  q.question.text == '+'
                    ? 'font-weight: 700; font-size: 36px; color: white; border-bottom: 0px solid; background: linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), radial-gradient(at top center, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.40) 120%) #989898; background-blend-mode: multiply,multiply;'
                    : ''
                "
              >
                <div class="text">
                  {{ q.question.text }}
                </div>
              </v-img>
            </div>
          </v-card>
        </v-flex>
        <v-flex v-for="i in 15" :key="i">
          <v-card
            flat
            style="margin: 30px; background-color: transparent"
            min-width="250px"
          />
        </v-flex>
      </v-layout>
      <div style="width: 100%; text-align: center; margin-bottom: 10px">
        <v-btn
          text
          :loading="loadingMore"
          :disabled="itemsLimitReached"
          @click.prevent="loadMore"
          >Cargar más</v-btn
        >
      </div>
    </v-container>
  </v-container>
</template>

<script lang="ts">
import {
  deleteCollection,
  getCollection,
  getQuestionsByCollection,
  deleteQuestionOnCollection,
  guidGenerator,
  saveQuestion,
} from "@/utils";
import Vue from "vue";
export default Vue.extend({
  name: "SingleCollection",
  props: {
    collectionId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      questions: [],
      pageSize: 10,
      loadingMore: false,
      itemsLimitReached: false,
      collection: null,

      cardIdBeingEdited: {
        question: { options: [], correcta: "" },
      },
      cardsBak: null,
      editCardDialog: null,
      newCardDialog: false,
      newCard: {},
    };
  },
  async created() {
    this.collection = await getCollection(this.collectionId);
    await this.loadMore();
    this.questions.unshift({ id: "Add", question: { text: "+" } });
    this.loading = false;
  },
  methods: {
    async loadMore() {
      this.loadingMore = true;
      const newQs = await getQuestionsByCollection(
        this.collectionId,
        this.questions.length > 0
          ? this.questions[this.questions.length - 1].saved_at
          : null,
        this.pageSize
      );
      this.questions = this.questions.concat(newQs);
      if (!newQs || newQs.length == 0 || newQs.length < this.pageSize) {
        this.itemsLimitReached = true;
      }
      this.loadingMore = false;
    },
    async addCard() {
      const q = {
        question: {
          correcta: "a",
          options: {
            a: this.newCard.answer,
          },
          text: this.newCard.question,
          detalle: this.newCard.detail,
          qid: guidGenerator(),
          isContributed: true,
        },
      };
      await saveQuestion(q, this.collectionId, this.collection.title);
      this.questions.splice(1, 0, q);
      this.newCardDialog = false;
      this.newCard = {};
    },

    onCardEditConfirmation() {
      this.editCardDialog = false;
      // const docRef = firebase.firestore().collection('users').doc(this.$store.state.user.id).collection('flashcards').doc(this.cardIdBeingEdited.id);
      //     docRef.update({
      //       front: this.cardIdBeingEdited.front,
      //       back: this.cardIdBeingEdited.back,
      //       detail: this.cardIdBeingEdited.detail
      //     })
    },
    onContentEditCancel() {
      this.questions = JSON.parse(this.cardsBak);
      this.editCardDialog = false;
    },

    editCard(e, card) {
      this.cardIdBeingEdited = card;
      this.cardsBak = JSON.stringify(this.questions);
      this.editCardDialog = true;
    },

    async deleteCardById() {
      await deleteQuestionOnCollection(
        this.collectionId,
        this.cardIdBeingEdited.id
      );
      this.questions = this.questions.filter(
        (c) => c.id != this.cardIdBeingEdited.id
      );
      this.editCardDialog = false;
    },
    async deleteDeck() {
      if (
        confirm(
          "¿Seguro que quieres eliminar la colección? Esta acción no se puede deshacer"
        )
      ) {
        await deleteCollection(this.collectionId);
        alert("La colección ha sido borrada correctamente");
        this.$router.push({ name: "collections" });
      }
    },
  },
});
</script>

<style scoped>
.page-header {
  background-image: radial-gradient(
    circle 610px at 5.2% 51.6%,
    rgb(3, 6, 85) 0%,
    rgba(7, 3, 53, 1) 97.5%
  );
  padding: 50px;
}

.myspan {
  padding: 7px;
  background-color: rgba(26, 26, 26, 0.62);
  z-index: 2;
}

.page-title {
  font-size: 36px;
  font-family: "Saira Semi Condensed", sans-serif;
}

.group-images {
  align-items: center;
  position: relative;
  color: black;
  font-size: 18px;
  border-bottom: 4px solid;
  background-color: white;
  border-bottom-color: rgb(75, 75, 75);
  border-radius: 0.5em;
}

.media {
  border-radius: 3%;
}

.text {
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.block {
  display: block !important;
}
</style>
