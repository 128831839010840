<template>
  <v-list
    subheader
    style="
      text-align: start;
      border-color: indigo;
      border-style: solid;
      border-width: 1px;
    "
  >
    <v-subheader>¿Qué incluye la suscripción?</v-subheader>
    <v-list-item>
      <v-list-item-action>
        <v-icon> mdi-checkbox-marked-circle </v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>Acceso a miles de preguntas</v-list-item-title>
        <v-list-item-subtitle>
          Con contenidos que se actualizan cada mes
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item>
      <v-list-item-action>
        <v-icon> mdi-timer-check-outline </v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>Simulacros de examen cada mes</v-list-item-title>
        <v-list-item-subtitle>
          Formato oficial, con tiempo y con corrección
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item>
      <v-list-item-action>
        <v-icon> mdi-bookmark-box-multiple </v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>Colecciones de preguntas</v-list-item-title>
        <v-list-item-subtitle>
          Ideal para estudiar como a ti te venga bien
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item>
      <v-list-item-action>
        <v-icon>mdi-trophy</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>Clasificación y ranking</v-list-item-title>
        <v-list-item-subtitle>
          Para darte ese extra de motivación
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item>
      <v-list-item-action>
        <v-icon>mdi-video</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>Clases semanales</v-list-item-title>
        <v-list-item-subtitle>
          Con los mejores profesores que hay actualmente
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item>
      <v-list-item-action>
        <v-icon color="indigo">mdi-tag</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title
          ><strong style="font-size: xx-large"
            >64,99€</strong
          ></v-list-item-title
        >
        <v-list-item-subtitle> Facturados mensualmente </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <div style="padding: 10px">
      <v-btn
        color="indigo"
        dark
        tile
        block
        style="font-family: Avenir, Helvetica, Arial, sans-serif"
        @click="buttonClick"
      >
        <strong>Suscribirme</strong>
      </v-btn>
    </div>
  </v-list>
</template>

<script>
import { userClickedSubscribe } from "@/utils";
export default {
  methods: {
    buttonClick() {
      userClickedSubscribe();
      this.$emit("subscribe");
    },
  },
};
</script>
