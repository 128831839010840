<template>
  <v-container>
    <h1
      style="
        padding-bottom: 20px;
        font-family: 'Saira Semi Condensed', sans-serif;
      "
    >
      Simulacros
      <v-icon @click="newSimulationDialog = true">mdi-plus-box</v-icon>
    </h1>
    <div
      v-if="!loading"
      style="padding-bottom: 20px; padding-top: 10px; cursor: pointer"
    >
      <v-data-table
        :headers="simulationsTable.headers"
        :items="simulationsData"
        :search="search"
        :sort-by="['endDate']"
        :sort-desc="true"
        class="elevation-1"
        no-data-text="No hay simulacros"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn
            tile
            small
            color="primary"
            @click="corregirSimulacro(item.simulationId)"
            :disabled="new Date() <= new Date(item.resultsDate)"
            >Corregir</v-btn
          >
        </template>
      </v-data-table>
      <v-snackbar
        v-model="showErrorSnackbar"
        timeout="3000"
        text
        color="error"
        style="margin-bottom: 60px"
      >
        <span>{{ snackError }}</span>
      </v-snackbar>
      <v-snackbar
        v-model="showSuccessSnackbar"
        timeout="3000"
        text
        color="success"
        style="margin-bottom: 60px"
      >
        <span>{{ snackSuccess }}</span>
      </v-snackbar>
    </div>
    <v-dialog v-model="newSimulationDialog" persistent width="450">
      <v-card class="pa-4">
        <v-card-title>Nuevo simulacro</v-card-title>
        <v-card-text>
          <v-select
            v-model="newSimulationData.opoId"
            :items="availableOpoIds"
            label="Oposición"
          ></v-select>
          <v-text-field
            v-model="newSimulationData.title"
            label="Título del simulacro"
          />
          <v-text-field
            v-model="newSimulationData.testId"
            label="Test ID"
            :rules="[
              (value) =>
                value.length == 20 || 'Los test ID son de 20 caracteres',
            ]"
          />
          <v-text-field
            v-model="newSimulationData.maxSeconds"
            label="Duración en segundos"
            type="number"
            :hint="
              newSimulationData.maxSeconds
                ? Math.floor(newSimulationData.maxSeconds / 3600) +
                  ' h ' +
                  Math.floor((newSimulationData.maxSeconds % 3600) / 60) +
                  ' m'
                : ''
            "
          />
          <v-text-field
            v-model="newSimulationData.startDate"
            type="datetime-local"
            label="Inicio"
          ></v-text-field>
          <v-text-field
            v-model="newSimulationData.endDate"
            type="datetime-local"
            label="Fin"
          ></v-text-field>
          <v-select
            v-model="newSimulationData.correctionMode"
            :items="['Numérico', 'Apto/No Apto']"
            label="Resultado"
          ></v-select>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              newSimulationData = {};
              newSimulationDialog = false;
            "
            >Cancelar</v-btn
          >
          <v-btn tile color="success" @click="submitSimulation"
            >Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { getSimulations } from "@/utils.js";
import {
  addDoc,
  collection,
  doc,
  getFirestore,
  getDoc,
} from "@firebase/firestore";

export default {
  data: () => ({
    loading: true,
    search: "",
    rawSimulations: null,
    simulationsData: null,
    simulationsTable: {
      headers: [
        {
          text: "ID",
          value: "simulationId",
          sortable: false,
        },
        {
          text: "Opo ID",
          value: "opoId",
          sortable: true,
        },
        {
          text: "Título",
          value: "title",
          sortable: false,
        },
        {
          text: "Duración",
          value: "maxSeconds",
          sortable: false,
        },
        {
          text: "Test ID",
          value: "testId",
        },
        {
          text: "Inicio",
          value: "startDate",
        },
        {
          text: "Fin",
          value: "endDate",
          sortable: false,
        },
        {
          text: "Resultado",
          value: "correctionMode",
          sortable: false,
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
        },
      ],
    },
    snackError: "",
    snackSuccess: "",
    showErrorSnackbar: false,
    showSuccessSnackbar: false,
    newSimulationDialog: false,
    availableOpoIds: ["Bomberos", "Mossos d'Esquadra"],
    newSimulationData: {},
  }),
  async created() {
    await this.loadSimulations();
  },
  watch: {
    snackError() {
      this.showErrorSnackbar = true;
    },
    snackSuccess() {
      this.showSuccessSnackbar = true;
    },
  },
  methods: {
    async corregirSimulacro(simulationId) {
      const docRef = await addDoc(
        collection(
          getFirestore(),
          "contrib",
          "simulations",
          "correct_requests"
        ),
        { simulationId, requestedAt: new Date() }
      );
      alert("La corrección en segundo plano se ha iniciado");
      this.snackSuccess = "Se ha iniciado la corrección del simulacro";
    },
    mapSimulations(rawSimulations) {
      return rawSimulations.map((simulation) => {
        return {
          simulationId: simulation.id,
          opoId: simulation.opoId,
          title: simulation.title,
          testId: simulation.testId,
          startDate: simulation.startDate.toDate().toLocaleString(),
          endDate: simulation.endDate.toDate().toLocaleString(),
          resultsDate: simulation.resultsDate.toDate(),
          maxSeconds:
            Math.floor(simulation.maxSeconds / 3600) +
            " h " +
            Math.floor((simulation.maxSeconds % 3600) / 60) +
            " m",
          correctionMode:
            simulation.correctionMode == "binary" ? "Apto/No Apto" : "Numérico",
        };
      });
    },
    async loadSimulations() {
      this.loading = true;
      try {
        this.rawSimulations = await getSimulations();
        this.simulationsData = this.mapSimulations(this.rawSimulations);
      } catch (error) {
        alert(error);
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async validateNewSimulationData() {
      if (!this.newSimulationData.opoId) {
        this.snackError = "Selecciona una oposición";
      } else if (!this.newSimulationData.title) {
        this.snackError = "Introduce un título válido";
      } else if (!this.newSimulationData.testId) {
        this.snackError = "Introduce un ID de test";
      } else if (
        !this.newSimulationData.maxSeconds ||
        this.newSimulationData.maxSeconds < 600
      ) {
        this.snackError = "Introduce una duración válida";
      } else if (!this.newSimulationData.startDate) {
        this.snackError = "Introduce una fecha de inicio válida";
      } else if (!this.newSimulationData.endDate) {
        this.snackError = "Introduce una fecha de fin válida";
      } else if (
        new Date(this.newSimulationData.endDate) <
        new Date(this.newSimulationData.startDate)
      ) {
        this.snackError = "La fecha de fin es anterior a la inicio";
      } else {
        const docSnap = await getDoc(
          doc(getFirestore(), "tests", this.newSimulationData.testId)
        );
        if (!docSnap.exists) {
          this.snackError = "Introduce un ID de test que exista";
        } else {
          return true;
        }
      }
    },
    async submitSimulation() {
      console.log("submitting");
      if (!(await this.validateNewSimulationData())) {
        return;
      } else {
        try {
          this.newSimulationData.startDate = new Date(
            this.newSimulationData.startDate
          );
          this.newSimulationData.endDate = new Date(
            this.newSimulationData.endDate
          );
          this.newSimulationData.resultsDate = new Date(
            this.newSimulationData.endDate
          );
          this.newSimulationData.resultsDate.setSeconds(
            this.newSimulationData.resultsDate.getSeconds() + 60
          );
          this.newSimulationData.maxSeconds = parseInt(
            this.newSimulationData.maxSeconds
          );
          this.newSimulationData.correctionMode =
            this.newSimulationData.correctionMode == "Apto/No Apto"
              ? "binary"
              : "regular";
          const docRef = await addDoc(
            collection(getFirestore(), "simulations"),
            this.newSimulationData
          );
          this.snackSuccess =
            "Simulacro añadido satisfactoriamente con ID " + docRef.id;
          this.newSimulationDialog = false;
          await this.loadSimulations();
        } catch (error) {
          this.snackError = "Algo no fue como debía";
        }
      }
    },
  },
};
</script>

<style>
</style>