<template>
  <v-container>
    <v-row>
      <v-col>
        <introduccion-tests />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <administrar-tests />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <administrar-questions />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <admin-tags />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <admin-classes />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <admin-simulations />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <admin-impugnations />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AdministrarTests from "@/components/contributor/AdministrarTests.vue";
import AdministrarQuestions from "@/components/contributor/AdministrarQuestions.vue";
import IntroduccionTests from "@/components/contributor/IntroduccionTests.vue";
import AdminImpugnations from "@/components/contributor/AdminImpugnations.vue";
import AdminSimulations from "@/components/contributor/AdminSimulations.vue";
import AdminClasses from "@/components/contributor/AdminClasses.vue";
import AdminTags from "@/components/contributor/AdministrarCategorías.vue";
export default {
  name: "ContributorPanel",
  components: {
    AdministrarTests,
    AdministrarQuestions,
    IntroduccionTests,
    AdminImpugnations,
    AdminSimulations,
    AdminClasses,
    AdminTags,
  },
};
</script>
