<template>
  <v-card class="mx-auto" outlined>
    <v-card-text>
      <p class="display-1 text--primary">
        Creación de tests
      </p>
      <p class="caption-3 text--secondary">
        Estás viendo esto porque eres contribuidor
      </p>
      <div>
        <v-form @submit.prevent="crearTest">
          <v-btn type="submit" color="primary" class="mr-4">
            Crear test
          </v-btn>
        </v-form>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>


export default {
  name: "IntroduccionTests",
  methods: {
    crearTest() {
      this.$router.push({name: 'test_input'})
    },
  }
}
</script>
