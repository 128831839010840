<template>
  <div class="hero-image">
    <v-container
      style="
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <login-register
        :style="
          'padding: 25px; background: white; max-width: 400px; width: ' +
          loginWidth
        "
      />
    </v-container>
  </div>
</template>

<script>
import LoginRegister from "@/components/LoginRegister.vue";
export default {
  name: "EntrarView",
  components: {
    LoginRegister,
  },
  computed: {
    loginWidth: function () {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100%";
      } else {
        return "50%";
      }
    },
  },
};
</script>

<style>
.hero-image {
  /* background-image: url(https://firebasestorage.googleapis.com/v0/b/plataforma-de-tests.appspot.com/o/background.webp?alt=media&token=a8bdc01a-262f-4d97-8e7c-3c0aac116c41); */
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
</style>
