<template>
  <div
    class="group-link"
    @mouseover="mouseover"
    @mouseleave="mouseleave"
    @click="goToGroup"
  >
    <v-img
      dark
      class="group-image"
      :src="imgUrl"
      gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,.3)"
      height="250px"
    >
      <span class="myspan" :style="'background: ' + color + ';'">{{
        categoryInfo.name
      }}</span>
    </v-img>
  </div>
</template>
<script>
import Vue from "vue";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

export default Vue.extend({
  name: "CategoryCard",
  props: {
    categoryInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showInfo: false,
      imgUrl: null,
      color: "#444444",
    };
  },
  created() {
    this.scrFromStorage();
    this.color = this.categoryInfo.color ? this.categoryInfo.color : this.color;
  },
  methods: {
    scrFromStorage() {
      const storage = getStorage();
      getDownloadURL(ref(storage, `category_images/${this.categoryInfo.code}`))
        .then((url) => (this.imgUrl = url))
        .catch((error) => {
          // console.log(error);
          this.imgUrl = this.imgSrc();
        });
    },
    imgSrc() {
      return require("@/assets/stock" +
        ((this.categoryInfo.code.length + this.categoryInfo.name.length) % 10) +
        ".jpeg");
    },
    mouseover() {
      this.showInfo = true;
    },
    mouseleave() {
      this.showInfo = false;
    },

    goToGroup() {
      this.$router.push({
        name: "content",
        params: { code: this.categoryInfo.code },
      });
    },
  },
});
</script>

<style>
.group-image {
  align-items: center;
  position: relative;
  background: white;
  font-size: 24px;
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
}

.group-link {
  color: white;
  width: 333px;
  max-width: 90vw;
  cursor: pointer;
}

.myspan {
  padding: 7px;
  z-index: 2;
  font-family: "Saira Semi Condensed", sans-serif;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
