<template>
  <v-card class="mx-auto" outlined>
    <v-card-text>
      <p class="display-1 text--primary">Administración de tests</p>
      <p class="caption-3 text--secondary">
        Estás viendo esto porque eres contribuidor
      </p>
      <v-dialog v-model="duplicatingTest" persistent width="300">
        <v-card color="primary" dark>
          <v-card-text style="padding: 20px">
            Espera mientras duplicamos el test... Serás redirigido cuando acabe
          </v-card-text>
          <v-progress-linear
            indeterminate
            color="blue darken-4"
          ></v-progress-linear>
        </v-card>
      </v-dialog>
      <v-row v-if="loading" align="center" justify="center">
        <v-col class="text-center">
          <v-progress-circular :size="100" indeterminate />
        </v-col>
      </v-row>
      <div v-else-if="testsData">
        <div style="padding-bottom: 20px">
          <v-expansion-panels accordion>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Tests en la plataforma
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card>
                  <v-card-title>
                    <v-text-field
                      v-model="search"
                      label="Búsqueda"
                      prepend-inner-icon="mdi-magnify"
                      single-line
                      filled
                      dense
                      rounded
                      hide-details
                    />
                  </v-card-title>
                  <v-data-table
                    :headers="testsTable.headers"
                    :items="testsData"
                    :search="search"
                    :sort-by="['created']"
                    :sort-desc="true"
                    class="elevation-1"
                    dense
                  >
                    <template v-slot:item.actions="{ item }">
                      <v-icon small class="mr-2" @click="editItem(item)">
                        mdi-pencil
                      </v-icon>
                      <v-icon small @click="deleteItem(item)">
                        mdi-delete
                      </v-icon>
                    </template>
                    <template v-slot:item.duplicate="{ item }">
                      <v-icon small @click="duplicateTest(item.id, item.title)">
                        mdi-content-copy
                      </v-icon>
                    </template>
                  </v-data-table>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
      <div>
        <v-form @submit.prevent="loadTests">
          <v-btn type="submit" color="primary" class="mr-4">
            Cargar tests
          </v-btn>
        </v-form>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  getFirestore,
  collection,
  doc,
  query,
  where,
  getDocs,
  getDoc,
  setDoc,
  addDoc,
  onSnapshot,
  deleteDoc,
} from "firebase/firestore";

import { requestDuplicationOfTest } from "@/utils.js";

export default {
  name: "AdministrarTests",
  data: () => ({
    loading: false,
    testsData: null,
    search: "",
    duplicatingTest: false,
    testsTable: {
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Título",
          value: "title",
        },
        {
          text: "Categorías",
          value: "categories",
        },
        {
          text: "Preguntas",
          value: "numqs",
        },
        {
          text: "Subido el",
          value: "created",
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
        },
        {
          text: "Duplicar",
          value: "duplicate",
          sortable: false,
        },
      ],
    },
  }),
  methods: {
    async duplicateTest(testId, title) {
      if (confirm("Seguro que quieres duplicar el test " + title + "?")) {
        this.duplicatingTest = true;
        const docRef = await requestDuplicationOfTest(testId);
        onSnapshot(docRef, async (snap) => {
          const { success, error, testId } = snap.data();
          if (error) {
            alert(`Error: ${error}`);
            this.duplicatingTest = false;
          }
          if (success) {
            this.$router.push({
              name: "test_input",
              params: { testIdToEdit: testId },
            });
            this.duplicatingTest = false;
          }
        });
      }
    },
    mapTests(tests) {
      return tests.map((test) => {
        return {
          title: test.title,
          created: test.created
            ? test.created.toDate().toISOString().split("T")[0]
            : null,
          id: test.id,
          numqs: test.questionIds.length,
          categories: test.tags ? test.tags.join(", ") : "",
        };
      });
    },
    async loadTests() {
      this.loading = true;
      const db = getFirestore();
      const result = [];
      try {
        const querySnapshot = await getDocs(
          query(collection(db, "tests"), where("userCreated", "==", false))
        );
        querySnapshot.forEach(function (doc) {
          result.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        this.testsData = this.mapTests(result);
      } catch (error) {
        alert(error);
      } finally {
        this.loading = false;
      }
    },
    editItem(item) {
      this.$router.push({
        name: "test_input",
        params: { testIdToEdit: item.id },
      });
    },
    async deleteItem(item) {
      if (
        confirm(
          "¿Seguro que quieres eliminar el test " +
            item.title +
            "? Esta acción es definitiva y no se puede deshacer"
        )
      ) {
        const index = this.testsData.indexOf(item);
        this.testsData.splice(index, 1);
        const db = getFirestore();
        await deleteDoc(doc(db, "tests", item.id));
        alert("El test ha sido eliminado");
      }
    },
  },
};
</script>
