<template>
  <v-container>
    <h1
      style="
        padding-bottom: 20px;
        font-family: 'Saira Semi Condensed', sans-serif;
      "
    >
      Simulacros
      <!-- <v-icon small class="mx-2">mdi-information-outline</v-icon> -->
    </h1>
    <div v-if="!loading" style="border-radius: 0px; padding: 25px">
      <v-card-title>
        <v-text-field
          v-model="search"
          label="Búsqueda"
          prepend-inner-icon="mdi-magnify"
          single-line
          filled
          rounded
          hide-details
        />
      </v-card-title>
      <v-data-table
        @click:row="rowClicked"
        :headers="simulationsTable.headers"
        :items="simulationsData"
        :search="search"
        :sort-by="['endDate']"
        :sort-desc="true"
        class="elevation-1"
        style="cursor: pointer"
      >
      </v-data-table>
    </div>
    <h1
      style="
        padding-bottom: 20px;
        font-family: 'Saira Semi Condensed', sans-serif;
      "
    >
      Simulacros pasados
      <!-- <v-icon small class="mx-2">mdi-information-outline</v-icon> -->
    </h1>
    <div
      v-if="!loading && pastSimulationsData"
      style="border-radius: 0px; padding: 25px"
    >
      <v-card-title>
        <v-text-field
          v-model="search_prev"
          label="Búsqueda"
          prepend-inner-icon="mdi-magnify"
          single-line
          filled
          rounded
          hide-details
        />
      </v-card-title>
      <v-data-table
        @click:row="pastSimulationRowClicked"
        :headers="pastSimulationsTable.headers"
        :items="pastSimulationsData"
        :search="search_prev"
        :sort-by="['created']"
        :sort-desc="true"
        class="elevation-1"
        style="cursor: pointer"
      >
      </v-data-table>
    </div>
  </v-container>
</template>
  
  <script>
import { getSimulations } from "@/utils.js";
import { getTestsByTag } from "@/utils.js";

export default {
  name: "SimulationsView",
  data: () => ({
    loading: true,
    search: "",
    search_prev: "",
    rawSimulations: null,
    simulationsData: null,
    simulationsTable: {
      headers: [
        {
          text: "Título",
          value: "title",
          sortable: false,
        },
        {
          text: "Duración",
          value: "maxSeconds",
          sortable: false,
        },
        {
          text: "Inicio",
          value: "startDate",
        },
        {
          text: "Fin",
          value: "endDate",
          sortable: false,
        },
        {
          text: "Corrección",
          value: "resultsDate",
          sortable: false,
        },
      ],
    },
    pastSimulationsTable: {
      headers: [
        {
          text: "Título",
          value: "title",
        },

        {
          text: "Preguntas",
          value: "numqs",
        },
        {
          text: "Subido el",
          value: "created",
        },
      ],
    },
    pastSimulationsData: null,
    snackError: "",
    snackSuccess: "",
    showErrorSnackbar: false,
    showSuccessSnackbar: false,
  }),
  async created() {
    await Promise.all([this.loadSimulations(), this.loadPastSimulations()]);
    this.loading = false;
  },
  watch: {
    snackError() {
      this.showErrorSnackbar = true;
    },
    snackSuccess() {
      this.showSuccessSnackbar = true;
    },
  },
  methods: {
    rowClicked(item) {
      this.$router.push({
        name: "simulacro",
        params: { testId: item.simulationId },
      });
    },
    pastSimulationRowClicked(item) {
      this.$router.push({
        name: "test",
        params: { testId: item.id, mode: "regular" },
      });
    },
    mapPastSimulations(tests) {
      return tests.map((test) => {
        return {
          title: test.title,
          created: test.created
            ? test.created.toDate().toISOString().split("T")[0]
            : null,
          id: test.id,
          numqs: test.questionIds.length,
        };
      });
    },
    async loadPastSimulations() {
      if (this.$opoIden.value == "Mossos d'Esquadra") {
        this.simulation_tests = await getTestsByTag("SIMULACROS_MOSSOS");
      } else if (this.$opoIden.value == "Bomberos") {
        this.simulation_tests = await getTestsByTag("SIMULACROS");
      }
      this.pastSimulationsData = this.mapPastSimulations(this.simulation_tests);
    },
    mapSimulations(rawSimulations) {
      return rawSimulations.map((simulation) => {
        return {
          simulationId: simulation.id,
          opoId: simulation.opoId,
          title: simulation.title,
          testId: simulation.testId,
          startDate: simulation.startDate.toDate().toLocaleString(),
          endDate: simulation.endDate.toDate().toLocaleString(),
          resultsDate: simulation.resultsDate.toDate().toLocaleString(),
          maxSeconds:
            Math.floor(simulation.maxSeconds / 3600) +
            " h " +
            Math.floor((simulation.maxSeconds % 3600) / 60) +
            " m",
        };
      });
    },
    filterSimulations(rawSimulations) {
      return rawSimulations.filter((s) => s.opoId == this.$opoIden.value);
    },
    async loadSimulations() {
      this.loading = true;
      try {
        this.rawSimulations = await getSimulations();
        this.simulationsData = this.mapSimulations(
          this.filterSimulations(this.rawSimulations)
        );
      } catch (error) {
        alert(error);
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
  
  <style>
</style>