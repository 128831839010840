<template>
  <v-container>
    <h1>Hola</h1>
    <p>
      Has iniciado sesión como {{ auth.currentUser.email }}
    </p>
    <v-btn dark v-on:click="cerrarSesion" color="red">Cerrar sesión</v-btn>
    <p></p>
    <v-btn dark v-on:click="goHome" color="blue">Volver al inicio</v-btn>

  </v-container>
</template>

<script>
import { getAuth, signOut } from '@firebase/auth'
export default {
  name: 'AlreadyLogged',
  data() {
    return {
      auth: null
    }
  },
  mounted() {
    this.auth = getAuth();
  },
  methods: {
    cerrarSesion() {
      signOut(this.auth);
    },
    goHome() {
      this.$router.push({'name': 'home'})
    }
  }

}
</script>

<style>

</style>