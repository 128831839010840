import { getAuth, onAuthStateChanged } from "firebase/auth";


const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(getAuth(), (userFirebase) => {
      unsubscribe()
      if (userFirebase) {
        userFirebase.getIdTokenResult().then(decodedToken => {
          userFirebase.premium = ['premium', 'premium_mossos'].includes(decodedToken.claims.stripeRole);
          userFirebase.premiumAcademy = decodedToken.claims.premiumAcademy;
          //console.log(decodedToken.claims);
          userFirebase.userIsAdmin = decodedToken.claims.admin;
          userFirebase.userIsContributor = decodedToken.claims.contributor;
          //console.log(userFirebase);
          resolve(userFirebase);
        })
      } else resolve(null);
    }, reject)

  })
}

export default getCurrentUser;