<template>
  <v-container v-if="!loading">
    <h1
      style="
        padding-bottom: 10px;
        font-family: 'Saira Semi Condensed', sans-serif;
      "
    >
      Mis colecciones de preguntas
    </h1>
    <div class="page-subtitle">
      Puedes organizar tus preguntas guardadas en coleciones
    </div>

    <v-container style="padding-top: 20px">
      <v-layout row wrap>
        <v-flex v-for="deck in decks" :key="deck.id">
          <v-card
            flat
            dark
            style="margin: 30px; background-color: transparent"
            min-width="250px"
          >
            <div
              flat
              class="text-center"
              style="font-family: 'Saira Semi Condensed', sans-serif"
            >
              <v-img
                :aspect-ratio="4 / 3"
                class="media group-image-col"
                :style="
                  deck.title == '+'
                    ? 'font-size: 36px; color: white; border-bottom: 0px solid; background: linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), radial-gradient(at top center, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.40) 120%) #989898; background-blend-mode: multiply,multiply;'
                    : 'border-bottom: 0px solid;'
                "
                v-on="
                  deck.title == '+'
                    ? { click: openNewDeckDialog }
                    : { click: (e) => goToDeck(e, deck.id) }
                "
              >
                <div class="text">
                  {{ deck.title }}
                </div>
              </v-img>
            </div>
          </v-card>
        </v-flex>
        <v-flex v-for="i in 15" :key="i">
          <v-card
            flat
            style="margin: 30px; background-color: transparent"
            min-width="250px"
          />
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog
      v-model="newDeckDialog"
      :max-width="!$vuetify.breakpoint.smAndDown ? '30%' : 'auto'"
    >
      <v-card>
        <v-card-title>Nueva colección</v-card-title>
        <v-container style="padding: 30px">
          <v-form @submit.prevent="submitDeck">
            <v-text-field
              v-model="nombreNuevoMazo"
              type="text"
              label="Nombre de la colección"
            />
            <v-btn
              type="submit"
              color="primary"
              class="mr-4"
              :disabled="nombreNuevoMazo.length <= 0"
              @click.stop="newDeckDialog = false"
            >
              Crear
            </v-btn>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
import { getQuestionCollections, submitQuestionCollection } from "@/utils";
export default Vue.extend({
  name: "QuestionCollections",
  components: {
    //TeachingUnitCard
    //  TeachingGroupCard,
  },
  data() {
    return {
      loading: true,
      decks: null,
      nombreNuevoMazo: "",
      newDeckDialog: false,
    };
  },
  async created() {
    this.decks = await this.getDecks();
    this.sortDecks();
    this.loading = false;
  },
  methods: {
    sortDecks() {
      const realDecks = this.decks.filter((d) => d.title != "+");
      this.decks = realDecks.sort(function (a, b) {
        if (a.title.toUpperCase() > b.title.toUpperCase()) {
          return 1;
        }
        if (a.title.toUpperCase() < b.title.toUpperCase()) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
      this.decks.unshift({ id: "Add", title: "+" });
    },
    goToDeck(e, collectionId) {
      this.$router.push({
        name: "collection",
        params: { collectionId },
      });
    },
    openNewDeckDialog() {
      this.newDeckDialog = true;
    },
    async submitDeck() {
      if (this.nombreNuevoMazo == "+") {
        alert("Por favor elige otro nombre");
        this.nombreNuevoMazo = "";
        return;
      } else {
        const result = await submitQuestionCollection(this.nombreNuevoMazo);
        this.decks.push({ id: result.id, title: this.nombreNuevoMazo });
        this.sortDecks();
        this.nombreNuevoMazo = "";
        alert("Tu colección se ha creado correctamente");
      }
    },

    async getDecks() {
      return await getQuestionCollections();
    },
  },
});
</script>

<style scoped>
.myspan {
  padding: 7px;
  background-color: rgba(26, 26, 26, 0.62);
  z-index: 2;
}

.page-title {
  font-size: 36px;
}

.group-image-col {
  align-items: center;
  position: relative;
  color: white;
  font-size: 20px;
  border-radius: 0.5em;
  text-transform: uppercase;
  background: rgb(180, 179, 179);
}

.media {
  border-radius: 3%;
}
.media:hover {
  cursor: pointer;
}

.text {
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.block {
  display: block !important;
  height: 24px;
}
</style>
