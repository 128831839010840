<template>
  <div>
    <v-container>
      <h1
        style="padding: 20px; font-family: 'Saira Semi Condensed', sans-serif"
      >
        Mi cuenta
      </h1>

      <v-row justify="center">
        <v-col cols="12" sm="7" md="7" lg="7">
          <v-list
            v-if="userIsAdmin || userIsContributor"
            subheader
            style="text-align: start; background: #192f44"
            dark
          >
            <v-subheader>Panel de administración</v-subheader>
            <v-list-item v-if="userIsAdmin">
              <v-list-item-content>
                <span
                  >Ir a la página de
                  <a style="color: lightgoldenrodyellow" href="/admin"
                    >administrador</a
                  ></span
                >
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content>
                <span>
                  Ir a la página de
                  <a style="color: lightgoldenrodyellow" href="/contributor"
                    >contribuidor</a
                  ></span
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <p></p>
          <Pricing
            v-if="userIsLead && $opoIden.value == 'Bomberos'"
            @subscribe="suscribirme"
          />
          <PricingMossos
            v-else-if="userIsLead && $opoIden.value == `Mossos d'Esquadra`"
            @subscribe="suscribirmeMossos"
          />
          <p></p>
          <v-list subheader style="text-align: start">
            <v-subheader>General</v-subheader>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Nombre</v-list-item-title>
                <v-text-field
                  v-if="editingName"
                  v-model="nameBeingEdited"
                  :rules="[
                    (value) => !!value || 'Indica tu nombre',
                    (value) => value.length < 20 || 'Máximo 20 caracteres',
                  ]"
                  counter="20"
                ></v-text-field>
                <v-list-item-subtitle v-else>{{
                  auth.currentUser.displayName
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn v-if="!editingName" icon @click="editingName = true">
                  <v-icon color="grey lighten-1">mdi-pencil-outline</v-icon>
                </v-btn>
                <span v-else>
                  <v-btn icon @click="editingName = false">
                    <v-icon color="grey lighten-1">mdi-close</v-icon>
                  </v-btn>
                  <v-btn icon @click="updateDisplayName">
                    <v-icon color="grey lighten-1">mdi-check</v-icon>
                  </v-btn>
                </span>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Correo electrónico</v-list-item-title>
                <v-list-item-subtitle
                  >{{ auth.currentUser.email }}
                  {{
                    userEmailIsVerified ? "" : "(No verificado)"
                  }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Oposición</v-list-item-title>
                <v-select
                  v-if="editingOpo"
                  v-model="$opoIden.value"
                  :items="availableOpoIds"
                  @change="
                    editingOpo = false;
                    updateOpoId();
                  "
                ></v-select>
                <v-list-item-subtitle v-else>{{
                  $opoIden.value
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn v-if="!editingOpo" icon @click="editingOpo = true">
                  <v-icon color="grey lighten-1">mdi-pencil-outline</v-icon>
                </v-btn>
                <span v-else>
                  <v-btn icon @click="editingOpo = false">
                    <v-icon color="grey lighten-1">mdi-close</v-icon>
                  </v-btn>
                </span>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Estado de la suscripción</v-list-item-title>
                <v-list-item-subtitle v-if="userIsPremium"
                  >Activa
                  <span v-on:click="gestionarSuscripcion">
                    <a style="text-decoration: underline"
                      >Gestionar suscripción</a
                    >
                  </span>
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else-if="userIsPremiumAcademy"
                  >Activa y sincronizada con la academia</v-list-item-subtitle
                >
                <v-list-item-subtitle v-else-if="userIsLead"
                  ><strong>Inactiva</strong>.
                  <span v-on:click="suscribirme">
                    <a style="text-decoration: underline"
                      >Suscríbete para acceder al contenido</a
                    >
                  </span></v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <p></p>
          <v-list subheader style="text-align: start">
            <v-subheader>Mis estadísticas</v-subheader>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  >Nivel:
                  {{
                    Math.floor(
                      my_stats.total_aciertos /
                        my_stats.aciertos_para_subir_de_nivel
                    )
                  }}</v-list-item-title
                >
                <v-list-item-subtitle style="margin-top: 10px"
                  >Acierta preguntas para subir de nivel y conseguir logros
                </v-list-item-subtitle>

                <v-progress-linear
                  color="green"
                  rounded
                  height="30"
                  :value="
                    (100 *
                      (my_stats.total_aciertos %
                        my_stats.aciertos_para_subir_de_nivel)) /
                    my_stats.aciertos_para_subir_de_nivel
                  "
                  style="margin-top: 10px"
                  ><template v-slot:default="{ value }">
                    <small
                      >{{ my_stats.total_aciertos }} /
                      {{
                        my_stats.aciertos_para_subir_de_nivel *
                        Math.ceil(
                          my_stats.total_aciertos /
                            my_stats.aciertos_para_subir_de_nivel
                        )
                      }}</small
                    >
                  </template></v-progress-linear
                >
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Preguntas respondidas</v-list-item-title>

                <small style="margin-top: 10px">{{
                  my_stats.total_respondidas
                }}</small>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Preguntas acertadas</v-list-item-title>

                <small style="margin-top: 10px">{{
                  my_stats.total_aciertos
                }}</small>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Preguntas falladas</v-list-item-title>

                <small style="margin-top: 10px">{{
                  my_stats.total_fallos
                }}</small>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <p></p>
          <!-- <div v-if="userIsLead" id="leadZone">
            <v-btn x-large color="success" v-on:click="suscribirme"
              >Activar suscripción</v-btn
            >
          </div> -->
          <p></p>
        </v-col>
      </v-row>
      <div class="commonZone">
        <v-btn small text v-on:click="cerrarSesion">Cerrar sesión</v-btn>
        <p></p>
        <!-- <strong style="color: red; cursor: pointer" v-on:click="deleteAccount"
          >Eliminar mi cuenta</strong
        > -->
      </div>

      <v-dialog v-model="redirecting" persistent width="300">
        <v-card color="primary" dark>
          <v-card-text style="padding: 20px">
            Espera mientras te redirigimos...
          </v-card-text>
          <v-progress-linear
            indeterminate
            color="blue darken-4"
          ></v-progress-linear>
        </v-card>
      </v-dialog>
      <v-dialog v-model="syncing" persistent width="450">
        <v-card color="black" dark>
          <v-card-text style="padding: 20px">
            Vinculando cuenta... Esto puede tardar unos segundos
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";

import {
  getFirestore,
  collection,
  doc,
  query,
  where,
  getDocs,
  getDoc,
  setDoc,
  addDoc,
  onSnapshot,
  deleteDoc,
} from "firebase/firestore";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
  signOut,
} from "firebase/auth";

import Pricing from "@/components/Pricing";
import PricingMossos from "@/components/PricingMossos";

import {
  getAciertosCountFromUser,
  getFalladasCountFromUser,
  userHasActiveSubscription,
  update_opoIden,
} from "@/utils.js";

export default {
  name: "Profile",
  components: { Pricing, PricingMossos },
  data() {
    return {
      auth: null,
      redirecting: false,
      syncing: false,
      userIsPremium: false,
      userIsPremiumAcademy: false,
      userIsAdmin: false,
      userIsContributor: false,
      userIsLead: true,
      userEmailIsVerified: false,
      editingName: false,
      nameBeingEdited: "",
      editingOpo: false,
      availableOpoIds: ["Bomberos", "Mossos d'Esquadra"],
      my_stats: {
        total_respondidas: 1000,
        total_aciertos: 0,
        total_fallos: 400,
        nota_media: 5.5,
        aciertos_para_subir_de_nivel: 200,
      },
    };
  },
  async created() {
    this.auth = getAuth();
    this.userIsPremium = this.auth.currentUser.premium;
    this.userIsPremiumAcademy = this.auth.currentUser.premiumAcademy;
    this.userIsAdmin = this.auth.currentUser.userIsAdmin;
    this.userIsContributor = this.auth.currentUser.userIsContributor;
    this.userIsLead = !this.userIsPremium && !this.userIsPremiumAcademy;
    this.userEmailIsVerified = this.auth.currentUser.emailVerified;

    const res = await Promise.all([
      getAciertosCountFromUser(),
      getFalladasCountFromUser(),
    ]);
    this.my_stats.total_aciertos = res[0];
    this.my_stats.total_fallos = res[1];
    this.my_stats.total_respondidas = res[0] + res[1];

    this.nameBeingEdited = this.auth.currentUser.displayName;
  },
  methods: {
    updateOpoId() {
      update_opoIden(this.$opoIden.value);
    },
    updateDisplayName() {
      if (!this.nameBeingEdited || this.nameBeingEdited.length > 20) {
        alert(
          "Por favor indica tu nombre correctamente entre 1 y 20 caracteres"
        );
      } else {
        updateProfile(this.auth.currentUser, {
          displayName: this.nameBeingEdited.trim(),
        });
        this.editingName = false;
      }
    },
    cerrarSesion() {
      signOut(this.auth);
    },
    async suscribirme() {
      this.redirecting = true;
      const db = getFirestore();
      const auth = getAuth();
      const userId = auth.currentUser.uid;
      if (await userHasActiveSubscription("prod_LOLYHPY1wfpolH")) {
        alert(
          "Parece que ya tienes una suscripción activa. Por favor refresca la página y si el error persiste ponte en contacto con nosotros."
        );
        window.location.reload();
        this.redirecting = false;
        return;
      }
      const session = {
        price: "price_1MOo9LDY3Trg4oWTO5vgZZLz",
        allow_promotion_codes: true,
        success_url: window.location.href,
        cancel_url: window.location.href,
      };
      const docRef = await addDoc(
        collection(db, "users", userId, "checkout_sessions"),
        session
      );
      //console.log(docRef);
      onSnapshot(docRef, async (snap) => {
        const { error, sessionId } = snap.data();
        if (error) {
          alert(`An error occured: ${error.message}`);
        }
        if (sessionId) {
          const stripe = await loadStripe(
            "pk_live_51KhYDsDY3Trg4oWTijA9wHBHrkbB5wloQ64E00oSLN0JsRQnOmSBqrxalmgD62pmDVV1r4qLyt028KjBZrdu6eJ700hvB5HGN0",
            { locale: "es" }
          );
          stripe.redirectToCheckout({ sessionId });
        }
      });
    },
    async suscribirmeMossos() {
      this.redirecting = true;
      const db = getFirestore();
      const auth = getAuth();
      const userId = auth.currentUser.uid;
      if (await userHasActiveSubscription("prod_NCQshJc4XcoLTb")) {
        alert(
          "Parece que ya tienes una suscripción activa. Por favor refresca la página y si el error persiste ponte en contacto con nosotros."
        );
        window.location.reload();
        this.redirecting = false;
        return;
      }
      const session = {
        price: "price_1MS20NDY3Trg4oWTOoAoztDu",
        allow_promotion_codes: true,
        success_url: window.location.href,
        cancel_url: window.location.href,
      };
      const docRef = await addDoc(
        collection(db, "users", userId, "checkout_sessions"),
        session
      );
      //console.log(docRef);
      onSnapshot(docRef, async (snap) => {
        const { error, sessionId } = snap.data();
        if (error) {
          alert(`An error occured: ${error.message}`);
        }
        if (sessionId) {
          const stripe = await loadStripe(
            "pk_live_51KhYDsDY3Trg4oWTijA9wHBHrkbB5wloQ64E00oSLN0JsRQnOmSBqrxalmgD62pmDVV1r4qLyt028KjBZrdu6eJ700hvB5HGN0",
            { locale: "es" }
          );
          stripe.redirectToCheckout({ sessionId });
        }
      });
    },
    async gestionarSuscripcion() {
      this.redirecting = true;
      const db = getFirestore();
      const auth = getAuth();
      const userId = auth.currentUser.uid;
      const userSubCollection = "portal_requests";
      const request = { requestedAt: new Date() };
      const docRef = await addDoc(
        collection(db, "users", userId, userSubCollection),
        request
      );
      onSnapshot(docRef, async (snap) => {
        const { error, portalUrl } = snap.data();
        if (error) {
          alert(`An error occured: ${error}`);
        }
        if (portalUrl) {
          window.location.assign(portalUrl);
        }
      });
    },

    async syncAccount() {
      this.syncing = true;
      const db = getFirestore();
      const auth = getAuth();
      const userId = auth.currentUser.uid;
      const userEmail = auth.currentUser.email;
      const userSubCollection = "acc_sync_requests";
      const request = { requestedAt: new Date(), email: userEmail };
      const docRef = await addDoc(
        collection(db, "users", userId, userSubCollection),
        request
      );
      const un = onSnapshot(docRef, async (snap) => {
        const { error, success } = snap.data();
        if (error) {
          alert(`Algo no ha ido como debía: ${error}`);
          this.syncing = false;
          un();
        }
        if (success) {
          alert(
            "Tu cuenta se ha vinculado correctamente. Refresca la página para aplicar los cambios."
          );
          this.syncing = false;
          un();
        }
      });
    },

    async deleteAccount() {
      if (
        confirm(
          "¿Seguro que quieres eliminar tu cuenta? Esta acción es definitiva y no se puede deshacer. Para borrar la cuenta se necesita un inicio de sesión reciente."
        )
      ) {
        const db = getFirestore();
        const auth = getAuth();
        const userId = auth.currentUser.uid;
        await deleteDoc(doc(db, `users/${userId}`));
        try {
          await auth.currentUser.delete();
        } catch (error) {
          alert(`Algo no ha ido como debía: ${error}`);
        }
        alert("Tu cuenta ha sido correctamente eliminada");
        this.cerrarSesion();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
