<template>
  <v-container>
    <h1
      style="
        padding-bottom: 20px;
        font-family: 'Saira Semi Condensed', sans-serif;
      "
    >
      Tests por temas
    </h1>
    <div v-if="!loading" style="border-radius: 0px; padding: 25px">
      <v-card-title>
        <v-text-field
          v-model="search"
          label="Búsqueda"
          prepend-inner-icon="mdi-magnify"
          single-line
          filled
          rounded
          hide-details
        />
      </v-card-title>
      <v-data-table
        @click:row="rowClicked"
        :headers="testsTable.headers"
        :items="testsData"
        :search="search"
        :sort-by="['title']"
        :sort-desc="false"
        class="elevation-1"
        style="cursor: pointer"
      >
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import { getTestsByTag } from "@/utils.js";

export default {
  name: "TestsPorTemas",
  data() {
    return {
      tests: [],
      officialSelected: null,
      loading: true,
      testsData: null,
      search: "",
      duplicatingTest: false,
      testsTable: {
        headers: [
          {
            text: "Título",
            value: "title",
          },

          {
            text: "Preguntas",
            value: "numqs",
          },
          {
            text: "Subido el",
            value: "created",
          },
        ],
      },
    };
  },

  async mounted() {
    this.loading = true;
    if (this.$opoIden.value == "Mossos d'Esquadra") {
      this.tests = await getTestsByTag("MOSSOS");
    } else {
      this.tests = [];
    }
    this.testsData = this.mapTests(this.tests);
    function compare(a, b) {
      if (a.title < b.title) {
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }
      return 0;
    }
    this.testsData.sort(compare);
    this.loading = false;
  },
  methods: {
    rowClicked(item) {
      this.$router.push({
        name: "test",
        params: { testId: item.id, mode: "regular" },
      });
    },
    mapTests(tests) {
      return tests.map((test) => {
        return {
          title: test.title,
          created: test.created
            ? test.created.toDate().toISOString().split("T")[0]
            : null,
          id: test.id,
          numqs: test.questionIds.length,
        };
      });
    },
  },
};
</script>

<style>
</style>