var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',{staticStyle:{"padding-bottom":"20px","font-family":"'Saira Semi Condensed', sans-serif"}},[_vm._v(" Clases "),_c('v-icon',{on:{"click":function($event){_vm.newItemDialog = true}}},[_vm._v("mdi-plus-box")])],1),(!_vm.loading)?_c('div',{staticStyle:{"padding-bottom":"20px","padding-top":"10px","cursor":"pointer"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.itemsTable.headers,"items":_vm.itemsData,"search":_vm.search,"sort-by":['endDate'],"sort-desc":true,"no-data-text":"No hay clases"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.color",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"x-small":"","color":item.color}})]}},{key:"item.recorded_class_url",fn:function(ref){
var item = ref.item;
return [(item.recorded_class_url)?[_vm._v(_vm._s(item.recorded_class_url))]:_c('v-chip',{attrs:{"x-small":""},on:{"click":function($event){_vm.itemBeingEdited = item;
            _vm.recordedURLDialog = true;}}},[_vm._v(" Introducir ")])]}}],null,false,2730292724)}),_c('v-snackbar',{staticStyle:{"margin-bottom":"60px"},attrs:{"timeout":"3000","text":"","color":"error"},model:{value:(_vm.showErrorSnackbar),callback:function ($$v) {_vm.showErrorSnackbar=$$v},expression:"showErrorSnackbar"}},[_c('span',[_vm._v(_vm._s(_vm.snackError))])]),_c('v-snackbar',{staticStyle:{"margin-bottom":"60px"},attrs:{"timeout":"3000","text":"","color":"success"},model:{value:(_vm.showSuccessSnackbar),callback:function ($$v) {_vm.showSuccessSnackbar=$$v},expression:"showSuccessSnackbar"}},[_c('span',[_vm._v(_vm._s(_vm.snackSuccess))])])],1):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","width":"450"},model:{value:(_vm.recordedURLDialog),callback:function ($$v) {_vm.recordedURLDialog=$$v},expression:"recordedURLDialog"}},[_c('v-card',{staticClass:"pa-2"},[_c('v-card-title',[_vm._v("Introduce el link de la clase grabada")]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Link de la clase grabada"},model:{value:(_vm.recordedURLDialogValue),callback:function ($$v) {_vm.recordedURLDialogValue=$$v},expression:"recordedURLDialogValue"}})],1),_c('v-card-actions',{staticClass:"pa-2"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.recordedURLDialogValue = null;
            _vm.recordedURLDialog = false;}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"tile":"","color":"success"},on:{"click":_vm.setRecordedURL}},[_vm._v("Confirmar")])],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","width":"450"},model:{value:(_vm.newItemDialog),callback:function ($$v) {_vm.newItemDialog=$$v},expression:"newItemDialog"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-card-title',[_vm._v("Nueva clase")]),_c('v-card-text',[_c('v-select',{attrs:{"items":_vm.availableOpoIds,"label":"Oposición"},model:{value:(_vm.newItemData.opoId),callback:function ($$v) {_vm.$set(_vm.newItemData, "opoId", $$v)},expression:"newItemData.opoId"}}),_c('v-text-field',{attrs:{"label":"Nombre de la clase"},model:{value:(_vm.newItemData.name),callback:function ($$v) {_vm.$set(_vm.newItemData, "name", $$v)},expression:"newItemData.name"}}),_c('v-text-field',{attrs:{"type":"datetime-local","label":"Inicio"},model:{value:(_vm.newItemData.start),callback:function ($$v) {_vm.$set(_vm.newItemData, "start", $$v)},expression:"newItemData.start"}}),_c('v-text-field',{attrs:{"type":"datetime-local","label":"Fin"},model:{value:(_vm.newItemData.end),callback:function ($$v) {_vm.$set(_vm.newItemData, "end", $$v)},expression:"newItemData.end"}}),_c('v-text-field',{attrs:{"label":"Test ID (opcional)","rules":[
            function (value) { return value.length == 0 ||
              value.length == 20 ||
              'Los test ID son de 20 caracteres'; } ]},model:{value:(_vm.newItemData.testId),callback:function ($$v) {_vm.$set(_vm.newItemData, "testId", $$v)},expression:"newItemData.testId"}}),_c('v-text-field',{attrs:{"type":"link","label":"Enlace clase en directo"},model:{value:(_vm.newItemData.live_class_url),callback:function ($$v) {_vm.$set(_vm.newItemData, "live_class_url", $$v)},expression:"newItemData.live_class_url"}}),_c('v-color-picker',{attrs:{"hide-canvas":"","hide-inputs":"","hide-sliders":"","swatches":_vm.swatches,"show-swatches":""},model:{value:(_vm.newItemData.color),callback:function ($$v) {_vm.$set(_vm.newItemData, "color", $$v)},expression:"newItemData.color"}})],1),_c('v-card-actions',{staticClass:"pa-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.newItemData = {};
            _vm.newItemDialog = false;}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"tile":"","color":"success"},on:{"click":_vm.submitItem}},[_vm._v("Confirmar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }