<template>
  <div class="navigation-container">
    <div class="navigation-item">
      <v-select
        v-model="value.perPage"
        :items="['1', '3', '10', '20', '100']"
        label="Preguntas por página"
      />
    </div>
    <div class="navigation-item">
      <v-pagination
        v-model="value.page"
        :elevation="false"
        circle
        :disabled="value.disabled"
        :length="value.length"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
        :page="value.page"
        :total-visible="5"
      />
    </div>
    <div v-if="!value.finishEnabled" class="navigation-item">
      <v-select
        v-model="value.filter"
        :items="filterOptions"
        label="Mostrar preguntas"
      />
    </div>
    <div v-else class="navigation-item">
      <v-btn
        outlined
        :disabled="!value.finishEnabled"
        @click="$emit('finish-exam')"
      >
        Finalizar
      </v-btn>
    </div>
  </div>
</template>

<script>
// import { QuestionsToShow } from "@/views/TestExam.vue";

export default {
  name: "Pager",
  props: {
    value: Object
  },
  data() {
    return {
      filterOptions: [
        {
          value: 'ALL',//QuestionsToShow.ALL,
          text: "Todas"
        },
        {
          value: 'WRONG',//QuestionsToShow.WRONG,
          text: "Erróneas"
        }
      ]
    };
  },
  watch: {},
  methods: {}
}
</script>

<style scoped>
.navigation-container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding-top: 40px;
}

@media only screen and (min-width: 600px) {
  .navigation-item {
    margin-right: 20px;
    margin-left: 20px;
  }
}

.navigation-item {
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
}
</style>
