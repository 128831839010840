<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <v-btn v-if="!already" v-on:click="sendVer">Vuelve a enviar el correo de verificación</v-btn>
    <v-btn v-else v-on:click="goHome">Ir al inicio</v-btn>

  </div>
</template>

<script>
import { getAuth, 
         sendEmailVerification } from "firebase/auth";


export default {
  name: 'Verify',
  data() {
    return {
      auth: null,
      msg: 'Verifica tu correo electrónico',
      already: false
    }
  },
  mounted() {
    this.auth = getAuth();
    if(this.auth.currentUser.emailVerified) {
      this.msg = "Ya hemos verificado tu correo."
      this.already = true
    }
  },
  methods: {
    sendVer() {
      sendEmailVerification(this.auth.currentUser).then(() => {
        console.log('message sent!')
      });
    },
    goHome() {
      this.$router.push({name: 'home'})
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
