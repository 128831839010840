<template>
  <v-container>
    <v-row>
      <v-col>
        <!-- <p>
          Si eres alumno/a de
          <a href="https://oposicionesbombero.com" target="_blank"
            >Oposiciones Bombero</a
          >
          utiliza el mismo correo para tener tu suscripción vinculada
        </p> -->
        <v-row>
          <v-col v-if="loginVisible">
            <div>
              <h1 style="padding: 20px">Hola de nuevo</h1>
              <br />

              <v-form v-model="loginValid" :lazy-validation="false">
                <v-text-field
                  v-model="loginEmail"
                  label="Correo electrónico"
                  outlined
                  color="black"
                  style="border-radius: 0px; margin-bottom: -20px"
                  required
                ></v-text-field>
                <password :password.sync="loginPassword"></password>
                <div
                  style="
                    text-align: end;
                    margin-bottom: 30px;
                    margin-top: -10px;
                  "
                >
                  <router-link :to="{ name: 'reset_password' }">
                    No recuerdo la contraseña
                  </router-link>
                </div>
                <p style="font-size: 0.9em">
                  ¿Nuevo por aquí?
                  <strong
                    style="cursor: pointer; text-decoration: underline"
                    v-on:click="loginVisible = !loginVisible"
                    >Crea tu cuenta</strong
                  >
                </p>
                <v-btn
                  block
                  dark
                  tile
                  style="height: 44px"
                  color="black"
                  :loading="emailLoading"
                  @click="actionLoginEmail"
                >
                  Iniciar sesión
                </v-btn>
                <v-divider
                  style="margin-top: 24px; margin-bottom: 24px"
                ></v-divider>
                <div
                  id="gSignInWrapper"
                  @click.prevent="actionLoginGoogle"
                  style="display: flex"
                >
                  <div id="customBtn" class="customGPlusSignIn">
                    <span class="icon"></span>
                    <span class="buttonText">Continuar con Google</span>
                  </div>
                </div>
              </v-form>
            </div>
          </v-col>
          <v-col v-if="!loginVisible">
            <h1 style="padding: 20px">Crea tu cuenta</h1>
            <br />
            <v-form v-model="registerValid" :lazy-validation="false">
              <v-row>
                <v-col style="padding-bottom: 0px; padding-top: 0px">
                  <v-text-field
                    style="border-radius: 0px"
                    v-model="registerFirstName"
                    label="Nombre"
                    required
                    color="black"
                    outlined
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col style="padding-bottom: 0px; padding-top: 0px">
                  <v-text-field
                    v-model="registerLastName"
                    label="Apellidos"
                    required
                    color="black"
                    outlined
                    style="border-radius: 0px"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col style="padding-bottom: 0px; padding-top: 0px">
                  <v-text-field
                    v-model="registerEmail"
                    color="black"
                    label="Correo electrónico"
                    :rules="emailRules"
                    outlined
                    required
                    style="border-radius: 0px"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col style="padding-bottom: 0px; padding-top: 0px">
                  <v-text-field
                    v-model="registerPassword"
                    color="black"
                    label="Contraseña"
                    type="password"
                    :rules="[(v) => !!v || 'No puede ser vacía']"
                    outlined
                    required
                    style="border-radius: 0px"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col style="padding-bottom: 0px; padding-top: 0px">
                  <v-text-field
                    v-model="registerPassword2"
                    color="black"
                    label="Confirmar contraseña"
                    type="password"
                    :rules="passwordConfirmationRules"
                    outlined
                    required
                    style="border-radius: 0px"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <p style="font-size: 0.9em">
                    ¿Ya estás registrado?
                    <strong
                      style="cursor: pointer; text-decoration: underline"
                      v-on:click="loginVisible = !loginVisible"
                      >Inicia sesión</strong
                    >
                  </p>
                  <v-btn
                    block
                    dark
                    tile
                    style="height: 44px"
                    color="black"
                    :loading="emailLoading"
                    @click="actionRegisterEmail"
                  >
                    Registrarme
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider style="margin-top: 24px; margin-bottom: 24px" />
              <div
                id="gSignInWrapper"
                @click.prevent="actionLoginGoogle"
                style="display: flex"
              >
                <div id="customBtn" class="customGPlusSignIn">
                  <span class="icon"></span>
                  <span class="buttonText">Continuar con Google</span>
                </div>
              </div>
            </v-form>
          </v-col>
        </v-row>
        <!-- <input v-model="username">
        <input v-model="password">
        <button v-on:click="createUser">Crear usuario</button>
        <button v-on:click="cerrarSesion">Cerrar sesión usuario</button>
        <button v-on:click="iniciarSesion">Iniciar sesión usuario</button>
        <button v-on:click="irAlPerfil">Perfil</button> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  updateProfile,
} from "firebase/auth";

import {
  getFirestore,
  collection,
  doc,
  query,
  where,
  getDocs,
  getDoc,
  setDoc,
} from "firebase/firestore";
import Password from "@/components/profile/Password.vue";
import { sync_account } from "@/utils";
export default {
  name: "LoginRegister",
  props: {
    msg: String,
    action: {
      type: String,
      default: "login",
      required: false,
    },
  },
  computed: {
    loginWidth: function () {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100%";
      } else {
        return "40%";
      }
    },
  },
  components: {
    Password,
  },
  data() {
    return {
      loginValid: false,
      loginPassword: "",
      loginEmail: "",
      emailRules: [],
      emailLoading: false,

      googleLoading: false,

      registerEmail: "",
      registerPassword: "",
      registerPassword2: "",
      registerFirstName: "",
      registerLastName: "",
      passwordConfirmationRules: [
        (v) => !!v || "No puede ser vacía",
        (v) => v === this.registerPassword || "Las contraseñas no coinciden",
      ],
      registerValid: false,

      loginVisible: this.action == "login",
    };
  },
  mounted() {
    this.auth = getAuth();
    this.emailRules = [
      (v) => !!v.trim() || "No puede ser vacío",
      (v) => /.+@.+\..+/.test(v) || "El email es inválido",
    ];
  },
  methods: {
    onLogin: function () {
      sync_account();
      this.$router.push(this.$route.query.redirect || { name: "home" });
    },
    irAlPerfil() {
      this.$router.push({ path: "/profile" });
    },
    async getUserAndCreateIfNotExists(id) {
      const db = getFirestore();
      const docRef = doc(db, "users", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return docSnap.data();
      } else {
        setDoc(docRef, {});
        return {};
      }
    },
    actionRegisterEmail() {
      createUserWithEmailAndPassword(
        this.auth,
        this.registerEmail,
        this.registerPassword
      )
        .then((result) => {
          sync_account();
          const user = result.user;
          this.user = user;
          this.getUserAndCreateIfNotExists(user.uid);
          sendEmailVerification(user).then(() =>
            this.$router.push({ name: "verify_email" })
          );
          let dn = this.registerFirstName + " " + this.registerLastName;
          dn = dn
            .trim()
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
            .join(" ");
          updateProfile(user, { displayName: dn });
        })
        .catch((error) => this.showRegisterError(error));
    },
    showRegisterError(error) {
      let textError;
      switch (error.code) {
        case "auth/weak-password":
          textError = "La contraseña es demasiado débil";
          break;
        case "auth/email-already-in-use":
          textError = "El correo ya está en uso";
          break;
        default:
          textError = "Error desconocido";
          break;
      }
      alert("Error creando el usuario: " + textError);
    },
    showLoginError(error) {
      console.log(error.code);
      let textError;
      switch (error.code) {
        case "auth/wrong-password":
          textError = "La contraseña es incorrecta";
          break;
        case "auth/invalid-email":
          textError = "El correo es inválido";
          break;
        case "auth/user-not-found":
          textError = "No hay ningún usuario con ese correo";
          break;
        case "auth/user-disabled":
          textError = "La cuenta ha sido desactivada";
          break;
        default:
          textError = "Error desconocido";
          break;
      }
      alert("Error iniciando sesión: " + textError);
    },
    cerrarSesion() {
      signOut(this.auth);
    },
    actionLoginEmail() {
      signInWithEmailAndPassword(
        this.auth,
        this.loginEmail.trim(),
        this.loginPassword.trim()
      )
        .then(() => this.onLogin())
        .catch((error) => this.showLoginError(error));
    },
    actionLoginGoogle() {
      const provider = new GoogleAuthProvider();
      const auth = getAuth();
      signInWithPopup(auth, provider)
        .then((result) => {
          this.onLogin();
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.email;
          alert(
            `Ha habido un error iniciando sesión con ${email}: ${errorCode} - ${errorMessage}`
          );
          // The AuthCredential type that was used.
          const credential = GoogleAuthProvider.credentialFromError(error);
          // ...
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

#customBtn {
  display: inline-block;
  background: white;
  color: #444;
  border: thin solid #ccc;
  white-space: nowrap;
  width: 100%;
}
#customBtn:hover {
  cursor: pointer;
}
span.label {
  font-family: serif;
  font-weight: normal;
}
span.icon {
  background: url("https://developers-dot-devsite-v2-prod.appspot.com/identity/sign-in/g-normal.png")
    transparent 5px 50% no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 42px;
}
span.buttonText {
  display: inline-block;
  vertical-align: middle;
  padding-left: 42px;
  padding-right: 42px;
  font-size: 14px;
  /* Use the Roboto font that is loaded in the <head> */
  font-family: "Roboto", sans-serif;
}
</style>
