<template>
  <object
    :data="resourceUrl"
    type="application/pdf"
    width="100%"
    height="100%"
  ></object>
</template>

<script>
export default {
  props: {
    resourceUrl: {
      type: String,
      required: true,
    },
  },
  name: "DocumentViewerComponent",
  data() {
    return {};
  },
};
</script>

<style>
</style>