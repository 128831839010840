<template>
  <v-container
    style="
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20%;
    "
  >
    <div style="background: white; padding: 30px">
      <h2>¿Cuál es tu objetivo?</h2>
      <p>Podrás cambiarlo más adelante en tu perfil</p>
      <v-btn
        tile
        text
        color="red"
        @click="
          $opoIden.value = 'Bomberos';
          update();
        "
        >Bomberos</v-btn
      >
      <v-btn
        tile
        text
        color="indigo"
        @click="
          $opoIden.value = `Mossos d'Esquadra`;
          update();
        "
        >Mossos d'Esquadra</v-btn
      >
    </div>
  </v-container>
</template>

<script>
import { update_opoIden } from "@/utils.js";
export default {
  methods: {
    async update() {
      await update_opoIden(this.$opoIden.value);
      this.$router.push({ name: "profile" });
    },
  },
};
</script>

<style>
</style>