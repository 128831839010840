<template>
  <v-container>
    <h1>Recuperar contraseña</h1>
    <p>¿Quieres que te enviemos un link para recuperar o cambiar la contraseña?</p>
    <v-text-field
      v-model="email"
      label="Correo electrónico"
      outlined
      required
    ></v-text-field>
    <v-btn dark color="blue" v-on:click="sendLink">{{ buttonText }}</v-btn>
    <p></p>
    <router-link v-if='buttonText == "Enviado!"' :to="{'name': 'home'}">Volver al inicio</router-link>
  </v-container>
</template>

<script>
import { getAuth, sendPasswordResetEmail } from '@firebase/auth'
export default {
  name: 'ResetPassword',
  data() {
    return {
      email: null,
      buttonText: 'Enviar'
      
    }
  },
  methods: {
    async sendLink() {
      try {
        await sendPasswordResetEmail(getAuth(), this.email);
        this.buttonText = 'Enviado!'
      } catch (error) {
        alert('No se ha encontrado el usuario')
      }
    }
  },
}
</script>

<style>

</style>