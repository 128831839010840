<template>
  <v-container>
    <h1
      style="
        padding-bottom: 20px;
        font-family: 'Saira Semi Condensed', sans-serif;
      "
    >
      Historial
    </h1>
    <v-row v-if="loading" align="center" justify="center">
      <v-data-table
        v-if="loading || !submissionsData"
        :headers="submissionsTable.headers"
        loading
        loading-text="Cargando tus tests... Por favor espera"
      ></v-data-table>
    </v-row>
    <div v-else>
      <div style="padding-bottom: 20px; cursor: pointer">
        <v-data-table
          :headers="submissionsTable.headers"
          :items="submissionsData"
          :search="search"
          :sort-by="['ended']"
          :sort-desc="true"
          class="elevation-1"
          no-data-text="No has hecho ningún test todavía"
          @click:row="seeSubmission"
        >
          <template v-slot:item.acierto="{ item }">
            <v-chip
              :color="getColor(item.acierto)"
              dark
              small
              style="font-weight: bold"
            >
              {{ item.acierto }}
            </v-chip>
          </template>
        </v-data-table>
      </div>
    </div>
  </v-container>
</template>

<script>
// import { getFirestore } from "firebase/firestore";
import { getSubmissionsByUser } from "@/utils.js";
export default {
  name: "SubmissionHistory",
  data: () => ({
    loading: false,
    rawSubmissions: null,
    submissionsData: null,
    search: "",
    submissionsTable: {
      headers: [
        {
          text: "Test",
          value: "title",
        },
        {
          text: "Preguntas",
          value: "preguntas",
        },
        {
          text: "Realizado",
          value: "ended",
        },
        {
          text: "Corrección",
          value: "mode",
          sortable: false,
        },
        {
          text: "Acierto",
          value: "acierto",
          sortable: false,
        },
      ],
    },
  }),
  async created() {
    await this.loadSubmissions();
  },
  methods: {
    porcentajeAcierto(test) {
      if (test.length == 0) return "100%";
      const correctas = test.filter(
        (q) => q.answer == q.question.correcta
      ).length;
      const porc = Math.round((correctas / test.length) * 100);
      return `${porc}%`;
    },
    filterSubmissions(submissions) {
      return submissions.filter((submission) => {
        if (!submission.ended) return false;
        if (submission.mode == "timed" && submission.grade == "Próximamente")
          return false;
        return true;
      });
    },

    mapSubmissions(submissions) {
      return submissions.map((submission) => {
        return {
          id: submission.id,
          title: submission.testName,
          acierto: this.porcentajeAcierto(submission.test),
          ended: submission.ended
            ? submission.ended.toDate().toISOString().split("T")[0] +
              " " +
              submission.ended.toDate().toISOString().split("T")[1].slice(0, 5)
            : null,
          mode:
            submission.mode == "regular"
              ? "Al final"
              : submission.mode == "timed"
              ? "Simulacro"
              : "Instantánea",
          preguntas: submission.test.length,
        };
      });
    },
    async loadSubmissions() {
      this.loading = true;
      try {
        this.rawSubmissions = await getSubmissionsByUser(0);
        this.submissionsData = this.mapSubmissions(
          this.filterSubmissions(this.rawSubmissions)
        );
      } catch (error) {
        console.log(error);
        alert(error);
      } finally {
        this.loading = false;
      }
    },
    seeSubmission(item) {
      this.$router.push({
        name: "submission_review",
        params: { submissionId: item.id },
      });
    },
    getColor(acierto) {
      const value = parseInt(acierto.slice(0, -1)) / 100;
      var hue = (value * 120).toString(10);
      return ["hsl(", hue, ",80%,50%)"].join("");
    },

    // async deleteItem(item) {
    //   if (
    //     confirm(
    //       "¿Seguro que quieres eliminar el test " +
    //         item.title +
    //         "? Esta acción es definitiva y no se puede deshacer"
    //     )
    //   ) {
    //     const index = this.testsData.indexOf(item);
    //     this.testsData.splice(index, 1);
    //     const db = getFirestore();
    //     await deleteDoc(doc(db, "tests", item.id));
    //     alert("El test ha sido eliminado");
    //   }
    // },
  },
};
</script>
