<template>
  <v-card class="mx-auto" outlined>
    <v-card-text>
      <p class="display-1 text--primary">Administración de preguntas</p>
      <p class="caption-3 text--secondary">
        Estás viendo esto porque eres contribuidor
      </p>
      <v-select
        v-model="category"
        :items="availableCategories"
        label="Categoría"
        item-text="name"
        item-value="code"
        return-object
        @change="loadQuestions"
      ></v-select>
      <v-row v-if="loading" align="center" justify="center">
        <v-col class="text-center">
          <v-progress-circular :size="100" indeterminate />
        </v-col>
      </v-row>
      <div v-else-if="questionsData">
        <div style="padding-bottom: 20px">
          <v-expansion-panels accordion>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Preguntas en la plataforma
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card>
                  <v-card-title>
                    <v-text-field
                      v-model="search"
                      label="Búsqueda"
                      prepend-inner-icon="mdi-magnify"
                      single-line
                      filled
                      dense
                      rounded
                      hide-details
                    />
                  </v-card-title>
                  <v-data-table
                    :headers="questionsTable.headers"
                    :items="questionsData"
                    :search="search"
                    :sort-by="['detalle']"
                    :sort-desc="true"
                    class="elevation-1"
                  >
                    <template v-slot:item.actions="{ item }">
                      <v-icon small class="mr-2" @click="editItem(item)">
                        mdi-pencil
                      </v-icon>
                      <v-icon small @click="deleteItem(item)">
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-data-table>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
    </v-card-text>
    <v-dialog
      v-model="editQuestionDialog"
      v-if="questionBeingEdited"
      persistent
    >
      <QuestionEdit
        :qid="questionBeingEdited.id"
        :availableCategories="availableCategories"
        @close-dialog="editQuestionDialog = false"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { getQuestionsByTag, getTags } from "@/utils";
import {
  getDoc,
  doc,
  getFirestore,
  setDoc,
  deleteDoc,
} from "@firebase/firestore";

import QuestionEdit from "./QuestionEdit.vue";
export default {
  name: "AdministrarQuestions",
  components: {
    QuestionEdit,
  },
  data: () => ({
    loading: true,
    questionsData: null,
    availableCategories: null,
    category: null,
    search: "",
    questionsTable: {
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Pregunta",
          value: "text",
        },
        {
          text: "Creada",
          value: "created",
        },
        {
          text: "Explicación",
          value: "detalle",
        },
        {
          text: "Autor",
          value: "authorDisplayName",
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
        },
      ],
    },
    editQuestionDialog: false,
    questionBeingEdited: null,
  }),
  async created() {
    this.availableCategories = await getTags(true, true);
    this.loading = false;
  },

  methods: {
    mapQuestions(questions) {
      return questions.map((q) => {
        return {
          text: (q.imgUrl && q.imgUrl.length > 0 ? "🖼️ " : "") + q.text,
          created: q.created
            ? q.created.toDate().toISOString().split("T")[0]
            : null,
          id: q.id,
          detalle: q.detalle && q.detalle.length > 0 ? "✅" : "❌",
          authorDisplayName: q.authorDisplayName,
        };
      });
    },
    async loadQuestions() {
      this.loading = true;
      if (!this.category) {
        alert("Selecciona una categoría");
      } else {
        this.questionsData = this.mapQuestions(
          await getQuestionsByTag(this.category.code)
        );
        this.loading = false;
      }
    },
    // async loadTests() {
    //   this.loading = true;
    //   const db = getFirestore();
    //   const result = [];
    //   try {
    //     const querySnapshot = await getDocs(
    //       query(collection(db, "tests"), where("userCreated", "==", false))
    //     );
    //     querySnapshot.forEach(function (doc) {
    //       result.push({
    //         id: doc.id,
    //         ...doc.data(),
    //       });
    //     });
    //     this.testsData = this.mapQuestions(result);
    //   } catch (error) {
    //     alert(error);
    //   } finally {
    //     this.loading = false;
    //   }
    // },
    editItem(item) {
      this.questionBeingEdited = item;
      this.editQuestionDialog = true;
      // this.$router.push({
      //   name: "test_input",
      //   params: { testIdToEdit: item.id },
      // });
    },
    async deleteItem(item) {
      if (
        confirm(
          "¿Seguro que quieres eliminar la pregunta? Esta acción es definitiva y no se puede deshacer"
        )
      ) {
        const index = this.questionsData.indexOf(item);
        this.questionsData.splice(index, 1);
        const db = getFirestore();
        await deleteDoc(doc(db, "questions", item.id));
        alert("La pregunta ha sido eliminada");
      }
    },
  },
};
</script>
