var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',{staticStyle:{"padding-bottom":"20px","font-family":"'Saira Semi Condensed', sans-serif"}},[_vm._v(" Simulacros "),_c('v-icon',{on:{"click":function($event){_vm.newSimulationDialog = true}}},[_vm._v("mdi-plus-box")])],1),(!_vm.loading)?_c('div',{staticStyle:{"padding-bottom":"20px","padding-top":"10px","cursor":"pointer"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.simulationsTable.headers,"items":_vm.simulationsData,"search":_vm.search,"sort-by":['endDate'],"sort-desc":true,"no-data-text":"No hay simulacros"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"tile":"","small":"","color":"primary","disabled":new Date() <= new Date(item.resultsDate)},on:{"click":function($event){return _vm.corregirSimulacro(item.simulationId)}}},[_vm._v("Corregir")])]}}],null,false,4186612915)}),_c('v-snackbar',{staticStyle:{"margin-bottom":"60px"},attrs:{"timeout":"3000","text":"","color":"error"},model:{value:(_vm.showErrorSnackbar),callback:function ($$v) {_vm.showErrorSnackbar=$$v},expression:"showErrorSnackbar"}},[_c('span',[_vm._v(_vm._s(_vm.snackError))])]),_c('v-snackbar',{staticStyle:{"margin-bottom":"60px"},attrs:{"timeout":"3000","text":"","color":"success"},model:{value:(_vm.showSuccessSnackbar),callback:function ($$v) {_vm.showSuccessSnackbar=$$v},expression:"showSuccessSnackbar"}},[_c('span',[_vm._v(_vm._s(_vm.snackSuccess))])])],1):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","width":"450"},model:{value:(_vm.newSimulationDialog),callback:function ($$v) {_vm.newSimulationDialog=$$v},expression:"newSimulationDialog"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-card-title',[_vm._v("Nuevo simulacro")]),_c('v-card-text',[_c('v-select',{attrs:{"items":_vm.availableOpoIds,"label":"Oposición"},model:{value:(_vm.newSimulationData.opoId),callback:function ($$v) {_vm.$set(_vm.newSimulationData, "opoId", $$v)},expression:"newSimulationData.opoId"}}),_c('v-text-field',{attrs:{"label":"Título del simulacro"},model:{value:(_vm.newSimulationData.title),callback:function ($$v) {_vm.$set(_vm.newSimulationData, "title", $$v)},expression:"newSimulationData.title"}}),_c('v-text-field',{attrs:{"label":"Test ID","rules":[
            function (value) { return value.length == 20 || 'Los test ID son de 20 caracteres'; } ]},model:{value:(_vm.newSimulationData.testId),callback:function ($$v) {_vm.$set(_vm.newSimulationData, "testId", $$v)},expression:"newSimulationData.testId"}}),_c('v-text-field',{attrs:{"label":"Duración en segundos","type":"number","hint":_vm.newSimulationData.maxSeconds
              ? Math.floor(_vm.newSimulationData.maxSeconds / 3600) +
                ' h ' +
                Math.floor((_vm.newSimulationData.maxSeconds % 3600) / 60) +
                ' m'
              : ''},model:{value:(_vm.newSimulationData.maxSeconds),callback:function ($$v) {_vm.$set(_vm.newSimulationData, "maxSeconds", $$v)},expression:"newSimulationData.maxSeconds"}}),_c('v-text-field',{attrs:{"type":"datetime-local","label":"Inicio"},model:{value:(_vm.newSimulationData.startDate),callback:function ($$v) {_vm.$set(_vm.newSimulationData, "startDate", $$v)},expression:"newSimulationData.startDate"}}),_c('v-text-field',{attrs:{"type":"datetime-local","label":"Fin"},model:{value:(_vm.newSimulationData.endDate),callback:function ($$v) {_vm.$set(_vm.newSimulationData, "endDate", $$v)},expression:"newSimulationData.endDate"}}),_c('v-select',{attrs:{"items":['Numérico', 'Apto/No Apto'],"label":"Resultado"},model:{value:(_vm.newSimulationData.correctionMode),callback:function ($$v) {_vm.$set(_vm.newSimulationData, "correctionMode", $$v)},expression:"newSimulationData.correctionMode"}})],1),_c('v-card-actions',{staticClass:"pa-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.newSimulationData = {};
            _vm.newSimulationDialog = false;}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"tile":"","color":"success"},on:{"click":_vm.submitSimulation}},[_vm._v("Confirmar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }