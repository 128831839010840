<template>
  <v-container>
    <h1
      style="
        padding-bottom: 20px;
        font-family: 'Saira Semi Condensed', sans-serif;
      "
    >
      Test de errores
    </h1>
    <v-card>
      <div v-if="!loading" class="centered-content">
        <v-dialog v-model="cookingTest" persistent width="450">
          <v-card color="black" dark>
            <v-card-text style="padding: 20px">
              Preparando tu test... Esto puede tardar unos segundos
            </v-card-text>
            <v-progress-linear
              indeterminate
              color="white darken-4"
            ></v-progress-linear>
          </v-card>
        </v-dialog>
        <v-row justify="center">
          <v-col cols="12" sm="7" md="7" lg="7">
            <div class="pa-5">
              <v-chip-group
                v-model="timeOptionSelection"
                active-class="red--text text--accent-5"
                mandatory
                column
              >
                <v-chip
                  v-for="opt in timeOptions"
                  :key="opt"
                  label
                  style="font-weight: 600"
                >
                  {{ opt }}
                </v-chip>
              </v-chip-group>
            </div>
            <h2>Elige el número de preguntas</h2>
            <div class="pa-5">
              <v-chip-group
                v-model="numPreguntasSelection"
                active-class="deep-purple--text text--accent-4"
                column
                mandatory
              >
                <v-chip
                  v-for="num in numPreguntas"
                  :key="num"
                  label
                  style="font-weight: 600"
                >
                  {{ num }}
                </v-chip>
              </v-chip-group>
            </div>
            <h2>Elige el modo de corrección</h2>
            <div class="pa-5">
              <v-chip-group
                v-model="testModesSelection"
                active-class="green--text text--accent-5"
                mandatory
                column
              >
                <v-chip
                  v-for="mode in testModes"
                  :key="mode"
                  label
                  large
                  style="font-weight: 600"
                >
                  {{ mode }}
                </v-chip>
              </v-chip-group>
            </div>
            <div class="pa-5">
              <v-btn
                block
                class="white--text"
                color="black"
                @click="cookTest"
                style="font-weight: 600"
              >
                Hacer el test de falladas
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-container>
</template>
  
  <script>
import {
  getFirestore,
  collection,
  addDoc,
  onSnapshot,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getTags } from "@/utils";
export default {
  name: "FalladasTestComponent",
  data() {
    return {
      cookingTest: false,
      loading: true,
      timeOptions: ["Hoy", "Última semana", "Último mes", "Siempre"],
      numPreguntas: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      numPreguntasSelection: null,
      testModes: ["Corrección instantánea", "Corrección al final"],
      testModesSelection: [],
      timeOptionSelection: null,
    };
  },
  async created() {
    this.loading = false;
  },
  methods: {
    async cookTest() {
      this.cookingTest = true;
      const db = getFirestore();
      const auth = getAuth();
      const userId = auth.currentUser.uid;
      const userDisplayName = auth.currentUser.displayName;
      const falladasDesde = this.timeOptionSelection;
      const length = this.numPreguntas[this.numPreguntasSelection];
      const request = {
        requestedAt: new Date(),
        tags: [],
        falladasDesde: this.timeOptions[this.timeOptionSelection],
        length,
        userId,
        userDisplayName,
      };
      const docRef = await addDoc(
        collection(db, "users", userId, "test_requests"),
        request
      );
      onSnapshot(docRef, async (snap) => {
        const { error, testId } = snap.data();
        if (error) {
          alert(`Error: ${error}`);
          this.cookingTest = false;
        }
        if (testId) {
          const mode = this.testModesSelection == 0 ? "instant" : "regular";
          this.$router.push({ name: "test", params: { testId, mode } });
          this.cookingTest = false;
        }
      });
    },
    goToTest(testId) {
      if (testId) {
        const mode = this.testModesSelection == 0 ? "instant" : "regular";
        this.$router.push({ name: "test", params: { testId, mode } });
      }
    },
  },
};
</script>
  
  <style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.subclass {
  background: #42b983;
}

.test-link {
  background: #0a0d1a;
  margin-bottom: 10px;
  padding: 16px;
  border-radius: 10px;
}
</style>
  