<template>
  <v-app>
    <v-app-bar
      v-if="currentUser"
      app
      absolute
      flat
      style="background-color: rgb(250, 250, 252)"
    >
      <v-toolbar-title
        @click="$router.push({ name: 'home' })"
        style="cursor: pointer; font-family: 'Saira Semi Condensed', sans-serif"
      >
        <v-img
          src="@/assets/letter_logo.jpeg"
          @click="$router.push({ name: 'home' })"
          style="cursor: pointer"
          max-width="100"
          contain
        ></v-img>
      </v-toolbar-title>
      <v-spacer />
      <!-- <v-toolbar-title style="font-size: 15px">{{ $opoId }}</v-toolbar-title> -->
      <v-btn icon @click="$router.push({ name: 'profile' })">
        <v-icon v-if="!photoUrl" style="font-size: 30px; color: gray">
          mdi-account-circle-outline
        </v-icon>
        <v-avatar v-else>
          <img
            :src="photoUrl"
            referrerpolicy="no-referrer"
            style="height: 35px; width: 35px"
          />
        </v-avatar>
      </v-btn>
    </v-app-bar>
    <v-main>
      <!-- Provides the application the proper gutter -->
      <!-- <v-container fluid> -->
      <v-navigation-drawer
        v-if="currentUser"
        v-model="drawer"
        :mini-variant.sync="mini"
        permanent
        app
      >
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-img
              src="@/assets/logo_square.png"
              @click="$router.push({ name: 'home' })"
              style="cursor: pointer"
            ></v-img>
          </v-list-item-avatar>

          <v-list-item-title> {{ $opoIden.value }}</v-list-item-title>

          <v-btn icon @click.stop="mini = !mini">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>

        <v-divider></v-divider>

        <v-list :dense="$vuetify.breakpoint.smAndDown">
          <v-list-item
            v-for="item in $opoIden.value == 'Bomberos' ? items : itemsMosso"
            :key="item.title"
            link
            :to="item.to"
            @click="mini = true"
            @mouseenter="mini = false"
            @mouseleave="mini = true"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title style="font-weight: 500">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <div id="app">
        <router-view :key="$route.fullPath" />
      </div>
      <!-- </v-container> -->
    </v-main>
  </v-app>
</template>

<script>
import { getAuth, onAuthStateChanged } from "firebase/auth";
export default {
  name: "App",
  components: {
    // HelloWorld
  },
  data() {
    return {
      auth: null,
      photoUrl: null,
      currentUser: null,
      dn: null,
      drawer: true,
      items: [
        // {
        //   title: "Principal",
        //   icon: "mdi-home",
        //   to: { name: "home" },
        // },
        {
          title: "Test de errores",
          icon: "mdi-close-circle-outline",
          to: { name: "falladas_test" },
        },
        {
          title: "Exámenes oficiales",
          icon: "mdi-office-building-marker-outline",
          to: { name: "oficiales" },
        },
        {
          title: "Simulacros",
          icon: "mdi-clipboard-text-clock",
          to: { name: "simulacros" },
        },
        { title: "Historial", icon: "mdi-history", to: { name: "history" } },
        {
          title: "Mis preguntas",
          icon: "mdi-bookmark-outline",
          to: { name: "collections" },
        },
        {
          title: "Impugnaciones",
          icon: "mdi-account-voice",
          to: { name: "impugnations" },
        },
        {
          title: "Clasificación",
          icon: "mdi-shield-crown-outline",
          to: { name: "ranking" },
        },
      ],
      itemsMosso: [
        {
          title: "Test de errores",
          icon: "mdi-close-circle-outline",
          to: { name: "falladas_test" },
        },
        {
          title: "Tests por temas",
          icon: "mdi-checkbox-multiple-marked-outline",
          to: { name: "tests" },
        },
        {
          title: "Simulacros",
          icon: "mdi-clipboard-text-clock",
          to: { name: "simulacros" },
        },
        { title: "Historial", icon: "mdi-history", to: { name: "history" } },
        { title: "Calendario", icon: "mdi-calendar", to: { name: "clases" } },
        { title: "Temario", icon: "mdi-book", to: { name: "content" } },
      ],
      mini: true,
    };
  },
  async created() {
    onAuthStateChanged(getAuth(), (userFirebase) => {
      this.currentUser = userFirebase;
      if (this.currentUser) {
        this.photoUrl = this.currentUser.photoURL;
        this.dn = this.currentUser.displayName;
      }
    });
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  background-color: rgb(250, 250, 252);
}
</style>
