import {
  getFirestore,
  collection,
  doc,
  query,
  where,
  getDocs,
  getDoc,
  addDoc,
  onSnapshot,
  orderBy,
  limit,
  startAt,
  startAfter,
  setDoc,
  deleteDoc,
  getCountFromServer,
  updateDoc
} from "firebase/firestore";

import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
} from "@firebase/storage";

import { getAuth } from "firebase/auth";
import { async } from "@firebase/util";

const guidGenerator = function () {
  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * charactersLength)
      );
    }
    return result;
  }
  return makeid(20);
}

const getCurrentRanking = async function () {
  const db = getFirestore();
  const myCol = collection(db, 'resources', 'ranking', 'monthly');
  const orderClause = orderBy('periodEnd', 'desc');
  const myQuery = query(myCol, orderClause, limit(1));
  const snapshot = await getDocs(myQuery);
  return snapshot.docs[0].data();
}

const getAciertosCountFromUser = async function () {
  const db = getFirestore();
  const auth = getAuth();
  const userId = auth.currentUser.uid;
  const myCol = collection(db, 'answers')
  const myQuery = query(myCol, where('userId', '==', userId), where('answeredCorrectly', '==', true));
  const snapshot = await getCountFromServer(myQuery);
  return snapshot.data().count;
}

const getFalladasCountFromUser = async function () {
  const db = getFirestore();
  const auth = getAuth();
  const userId = auth.currentUser.uid;
  const myCol = collection(db, 'answers')
  const myQuery = query(myCol, where('userId', '==', userId), where('answeredCorrectly', '==', false));
  const snapshot = await getCountFromServer(myQuery);
  return snapshot.data().count;
}


const sync_account = async function () {
  const db = getFirestore();
  const auth = getAuth();
  const userId = auth.currentUser.uid;
  const userEmail = auth.currentUser.email;
  const userSubCollection = "acc_sync_requests";
  const request = { requestedAt: new Date(), email: userEmail };
  const docRef = await addDoc(
    collection(db, "users", userId, userSubCollection),
    request
  );
};

const update_opoIden = async function (opoId) {
  const db = getFirestore();
  const auth = getAuth();
  const userId = auth.currentUser.uid;
  await updateDoc(doc(db, 'users', userId), { opoId })
}

const deleteCategoryByCode = async function (code) {
  const db = getFirestore();
  const snapshot = await getDoc(doc(db, "resources", "test_tags"));
  console.log(snapshot.data().availableTags.length);
  const updatedTags = snapshot.data().availableTags.filter((tag) => tag.code != code)
  console.log(updatedTags.length);
  const newTestTags = { availableTags: updatedTags, oldTags: snapshot.data().availableTags }
  await updateDoc(doc(db, 'resources', 'test_tags'), newTestTags);
}

const addCategory = async function (category) {
  const db = getFirestore();
  const snapshot = await getDoc(doc(db, "resources", "test_tags"));
  const tags = snapshot.data().availableTags;
  tags.push(category);
  const newTestTags = { availableTags: tags, oldTags: snapshot.data().availableTags }
  await updateDoc(doc(db, 'resources', 'test_tags'), newTestTags);
}

const getTags = async function (desambiguar = false, include_hidden = false) {
  const db = getFirestore();
  const snapshot = await getDoc(doc(db, "resources", "test_tags"));
  let all_non_hidden = !include_hidden ? snapshot
    .data()
    .availableTags.filter((tag) => !tag.hidden) : snapshot
      .data()
    .availableTags;
  if (desambiguar) {
    const tags = {};
    all_non_hidden.forEach((t) => {
      tags[t.code] = t.name;
    });
    const names = Object.values(tags);

    const counts = {};
    for (const num of names) {
      counts[num] = counts[num] ? counts[num] + 1 : 1;
    }

    all_non_hidden = all_non_hidden.map(function (tag) {
      if (counts[tag.name] == 1) {
        return tag;
      } else {
        return {
          code: tag.code,
          name:
            tag.name +
            " (" +
            tags[tag.code.split("..").slice(0, -1).join("..")] +
            ")",
        };
      }
    });
  }
  all_non_hidden.sort(function (a, b) {
    var textA = a.name;
    var textB = b.name;
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
  return all_non_hidden;
};

const getTopLevelTags = async function () {
  const allTags = await getTags();
  return allTags.filter((t) => !t.code.includes(".."));
};

const getSubTags = async function (code) {
  if (!code) {
    return await getTopLevelTags();
  } else {
    const allTags = await getTags();
    return allTags.filter(
      (t) =>
        t.code.includes(code + "..") &&
        !t.code.split(code + "..")[1].includes("..")
    );
  }
};

const getParentTags = async function (code) {
  if (!code) return [];
  const allTags = await getTags();
  const tags = [];
  const codes = [];
  for (const s of code.split("..")) {
    codes.push(s);
    tags.push(allTags.filter((t) => t.code == codes.join(".."))[0]);
  }
  return tags;
};

const getTagByCode = async function (code) {
  if (!code) return null;
  const allTags = await getTags();
  return allTags.filter((t) => t.code == code)[0];
};

const getTestsByTag = async function (tag, startAtPosition = 0) {
  const db = getFirestore();
  const myCollection = collection(db, "tests");
  const condition = where("tags", "array-contains", tag);
  const notUserCreated = where("userCreated", "==", false);
  const orden = orderBy("title");
  const q = query(
    myCollection,
    condition,
    notUserCreated,
    orden
    // limit(10),
    // startAfter(startAtPosition)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => {
    return { id: doc.id, ...doc.data() };
  });
};

const getSubmissionsByUser = async function (startAtPosition) {
  const auth = getAuth();
  const userId = auth.currentUser.uid;
  const db = getFirestore();
  const myCollection = collection(db, "users", userId, "submissions");
  const orden = orderBy("ended", "desc");
  const q = query(myCollection, orden); //limit(10), startAfter(startAtPosition));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => {
    return { id: doc.id, ...doc.data() };
  });
};

const uploadImage = async function (uploadLocation, imageBlob) {
  if (imageBlob) {
    const storage = getStorage();
    const storageRef = ref(storage, uploadLocation);
    await uploadBytes(storageRef, imageBlob);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  } else {
    return null;
  }
};

const updateTagInfo = async function (code, tagInfo) {
  const availableTags = await getTags();
  for (const tag of availableTags) {
    if (tag["code"] == code) {
      tag["name"] = tagInfo.name;
      tag["color"] = tagInfo.color;
    }
  }
  const db = getFirestore();
  return setDoc(doc(db, "resources", "test_tags"), { availableTags });
};

const createCategory = async function (tagInfo) {
  const availableTags = await getTags();
  availableTags.push({
    code: tagInfo.code,
    name: tagInfo.name,
    color: tagInfo.color,
  });
  const db = getFirestore();
  return setDoc(doc(db, "resources", "test_tags"), { availableTags });
};

async function createDocIfNotExists(docu, collectionTitle) {
  const doc = await getDoc(docu);
  if (!doc.exists()) {
    await setDoc(docu, { title: collectionTitle });
  }
}

const saveQuestion = async function (question, collectionId, collectionTitle) {
  const auth = getAuth();
  const userId = auth.currentUser.uid;
  const db = getFirestore();
  const qid = question.question.qid;
  await createDocIfNotExists(
    doc(db, "users", userId, "question_collections", collectionId),
    collectionTitle
  );
  question.saved_at = new Date();
  return await setDoc(
    doc(
      db,
      "users",
      userId,
      "question_collections",
      collectionId,
      "questions",
      qid
    ),
    question
  );
};

const unsaveQuestion = async function (question, collectionId) {
  const auth = getAuth();
  const userId = auth.currentUser.uid;
  const db = getFirestore();
  const qid = question.question.qid;
  return await deleteDoc(
    doc(
      db,
      "users",
      userId,
      "question_collections",
      collectionId,
      "questions",
      qid
    )
  );
};

const getQuestionCollections = async function () {
  const auth = getAuth();
  const userId = auth.currentUser.uid;
  const db = getFirestore();
  const orden = orderBy("title");
  const myCollection = collection(db, "users", userId, "question_collections");
  const q = query(myCollection, orden);
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => {
    return { id: doc.id, ...doc.data() };
  });
};

const submitQuestionCollection = async function (collectionTitle) {
  const db = getFirestore();
  const auth = getAuth();
  const userId = auth.currentUser.uid;
  const myCollection = collection(db, "users", userId, "question_collections");
  return await addDoc(myCollection, { title: collectionTitle });
};

const getQuestionsByCollection = async function (
  collectionId,
  startAtPosition,
  pageSize
) {
  const db = getFirestore();
  const auth = getAuth();
  const userId = auth.currentUser.uid;
  const myCollection = collection(
    db,
    "users",
    userId,
    "question_collections",
    collectionId,
    "questions"
  );
  const orden = orderBy("saved_at", "desc");
  let q = null;
  if (!startAtPosition) {
    q = query(myCollection, orden, limit(pageSize));
  } else {
    q = query(
      myCollection,
      orden,
      limit(pageSize),
      startAfter(startAtPosition)
    );
  }
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => {
    return { id: doc.id, ...doc.data() };
  });
};

const getCollection = async function (collectionId) {
  const db = getFirestore();
  const auth = getAuth();
  const userId = auth.currentUser.uid;
  const myDoc = doc(db, "users", userId, "question_collections", collectionId);
  return (await getDoc(myDoc)).data();
};

const deleteCollection = async function (collectionId) {
  const db = getFirestore();
  const auth = getAuth();
  const userId = auth.currentUser.uid;
  const myDoc = doc(db, "users", userId, "question_collections", collectionId);
  return await deleteDoc(myDoc);
};

const deleteQuestionOnCollection = async function (collectionId, questionId) {
  const db = getFirestore();
  const auth = getAuth();
  const userId = auth.currentUser.uid;
  const myDoc = doc(
    db,
    "users",
    userId,
    "question_collections",
    collectionId,
    "questions",
    questionId
  );
  return await deleteDoc(myDoc);
};

const registerAnswerEvent = async function (question, answer) {
  const db = getFirestore();
  const auth = getAuth();
  const userId = auth.currentUser.uid;
  const myCollection = collection(db, "answers");
  return await addDoc(myCollection, {
    answeredAt: new Date(),
    userId: userId,
    answer: answer,
    answeredCorrectly: question.correcta == answer,
    ...question,
  });
};

const createImpugnation = async function (question, reason) {
  const db = getFirestore();
  const auth = getAuth();
  const userId = auth.currentUser.uid;
  const myCollection = collection(db, "impugnations");
  return await addDoc(myCollection, {
    impugnatedAt: new Date(),
    userId: userId,
    question: question,
    reason: reason,
    state: "En revisión",
  });
};

const getImpugnationsByUser = async function (startAtPosition) {
  const auth = getAuth();
  const userId = auth.currentUser.uid;
  const db = getFirestore();
  const myCollection = collection(db, "impugnations");
  const orden = orderBy("impugnatedAt", "desc");
  const whereClause = where("userId", "==", userId);
  const q = query(myCollection, orden, whereClause);
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => {
    return { id: doc.id, ...doc.data() };
  });
};

const getImpugnationsByState = async function (state = 'En revisión') {
  const auth = getAuth();
  const userId = auth.currentUser.uid;
  const db = getFirestore();
  const myCollection = collection(db, "impugnations");
  const orden = orderBy("impugnatedAt", "desc");
  const whereClause = where("state", "==", state);
  const q = query(myCollection, orden, whereClause);
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => {
    return { id: doc.id, ...doc.data() };
  });
};

const userClickedSubscribe = async function () {
  const auth = getAuth();
  const userId = auth.currentUser.uid;
  const db = getFirestore();
  const myCollection = collection(db, "clicked_subscribe");
  return await addDoc(myCollection, {
    clickedAt: new Date(),
    userId: userId,
  });
};

const getQuestionsByTag = async function (tag_code) {
  const db = getFirestore();
  const myCollection = collection(db, "questions");
  const whereClause = where("tags", "array-contains", tag_code);
  const q = query(myCollection, whereClause);
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => {
    return { id: doc.id, ...doc.data() };
  });
};

const userHasActiveSubscription = async function (prod_id) {
  const db = getFirestore();
  const auth = getAuth();
  const userId = auth.currentUser.uid;
  const myCollection = collection(db, "users", userId, "subscriptions");
  const whereClause = where("status", "in", ["trialing", "active"]);
  const whereClause2 = where("product", "==", doc(db, 'products', prod_id));
  const q = query(myCollection, whereClause, whereClause2);
  const querySnapshot = await getDocs(q);
  return querySnapshot.size > 0;
}

const getQuestionById = async function (qid) {
  const db = getFirestore();
  const myDoc = doc(db, "questions", qid);
  const data = (await getDoc(myDoc)).data();
  return { qid: qid, ...data };
}

const resolveImpugnation = async function (state, impugnation_id, message) {
  const db = getFirestore();
  const auth = getAuth();
  const userId = auth.currentUser.uid;
  const userDisplayName = auth.currentUser.displayName;
  const docRef = doc(db, 'impugnations', impugnation_id);
  const data = (await getDoc(docRef)).data();
  if (data.resolvedAt) {
    throw new Error('La impugnación ya ha sido procesada por ' + data.resolverDisplayName + ' con la decisión: ' + data.state + ' y el mensaje: ' + data.resolutionMessage);
  }
  return await updateDoc(docRef, {
    resolvedAt: new Date(),
    resolverUserId: userId,
    resolverDisplayName: userDisplayName,
    resolutionMessage: message,
    state: state,
  });
}

const approveImpugnation = async function (impugnation_id, message = "") {
  return await resolveImpugnation('Aprobada', impugnation_id, message);
}

const dismissImpugnation = async function (impugnation_id, message = "") {
  return await resolveImpugnation('Rechazada', impugnation_id, message);

}

const requestDuplicationOfTest = async function (testId) {
  const db = getFirestore();
  const auth = getAuth();
  const userId = auth.currentUser.uid;
  const userDisplayName = auth.currentUser.displayName;
  const myCollection = collection(db, "contrib", 'tests', 'duplicate_test_requests');
  return await addDoc(myCollection, {
    requestedAt: new Date(),
    requesterDisplayName: userDisplayName,
    requesterUserId: userId,
    testId: testId
  });
}

const getSimulations = async function () {
  const db = getFirestore();
  const myCollection = collection(db, "simulations");
  const orden = orderBy("startDate", "desc");
  const q = query(myCollection, orden);
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => {
    return { id: doc.id, ...doc.data() };
  });
}

const getClassesByOpoId = async function (opoId) {
  const db = getFirestore();
  const myCollection = collection(db, "classes");
  let q = 0;
  if (opoId == 'all') {
    q = query(myCollection);
  } else {
    q = query(myCollection, where('opoId', '==', opoId));
  }
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => {
    const start = doc.data().start.toDate()
    const end = doc.data().end.toDate()
    return { id: doc.id, ...doc.data(), start, end };
  });
}

const getContentCategories = async function (opoId) {
  const db = getFirestore();
  const snapshot = await getDoc(doc(db, "resources", opoId));
  const all_non_hidden = snapshot
    .data()
    .availableTags.filter((tag) => !tag.hidden);
  return all_non_hidden;
};

const getContentCategoryByCode = async function (code, opoId) {
  if (!code) return null;
  const allTags = await getContentCategories(opoId);
  return allTags.filter((t) => t.code == code)[0];
};

const getTopLevelContentCategories = async function (opoId) {
  const allTags = await getContentCategories(opoId);
  return allTags.filter((t) => !t.code.includes(".."));
};

const getContentSubCategories = async function (code, opoId) {
  if (!code) {
    return await getTopLevelContentCategories(opoId);
  } else {
    const allTags = await getContentCategories(opoId);
    return allTags.filter(
      (t) =>
        t.code.includes(code + "..") &&
        !t.code.split(code + "..")[1].includes("..")
    );
  }
};

const getParentContentCategories = async function (code, opoId) {
  if (!code) return [];
  const allTags = await getContentCategories(opoId);
  const tags = [];
  const codes = [];
  for (const s of code.split("..")) {
    codes.push(s);
    tags.push(allTags.filter((t) => t.code == codes.join(".."))[0]);
  }
  return tags;
};

const updateContentCategoryInfo = async function (code, tagInfo, opoId) {
  const availableTags = await getContentCategories(opoId);
  for (const tag of availableTags) {
    if (tag["code"] == code) {
      tag["name"] = tagInfo.name;
      tag["color"] = tagInfo.color;
    }
  }
  const db = getFirestore();
  return await setDoc(doc(db, "resources", opoId), { availableTags });
};

const createContentCategory = async function (tagInfo, opoId) {
  const availableTags = await getContentCategories(opoId);
  availableTags.push({
    code: tagInfo.code,
    name: tagInfo.name,
    color: tagInfo.color,
  });
  const db = getFirestore();
  return await setDoc(doc(db, "resources", opoId), { availableTags });
};

const deleteContentCategory = async function (code, opoId) {
  const og_availableTags = await getContentCategories(opoId);
  const availableTags = og_availableTags.filter(tag => tag.code != code);
  const db = getFirestore();
  return await setDoc(doc(db, "resources", opoId), { availableTags });
};

const addResourceToContentCategory = async function (code, opoId, resource) {
  return await addDoc(collection(getFirestore(), 'resources', opoId, 'content'), { ...resource, contentCategoryCode: code })
}

const deleteContentCategoryResourceById = async function (opoId, resourceId) {
  const docRef = doc(getFirestore(), 'resources', opoId, 'content', resourceId);
  console.log(docRef);
  await deleteDoc(docRef);
}

const getResourcesByContentCategory = async function (code, opoId) {
  const db = getFirestore();
  const myCollection = collection(db, "resources", opoId, "content");
  const q = query(myCollection, where('contentCategoryCode', '==', code));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => {
    return { id: doc.id, ...doc.data() };
  });
}

const getLatestCorrectionBySimulationId = async function (simulationId) {
  const db = getFirestore();
  const myCollection = collection(db, "simulations", simulationId, "corrections");
  const orden = orderBy("correctedAt", "desc");
  const q = query(myCollection, orden, limit(1));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => {
    return { id: doc.id, ...doc.data() };
  })[0];
}

export {
  userHasActiveSubscription,
  sync_account,
  getTags,
  getTestsByTag,
  getTopLevelTags,
  getSubTags,
  getTagByCode,
  getParentTags,
  uploadImage,
  updateTagInfo,
  createCategory,
  getSubmissionsByUser,
  saveQuestion,
  getQuestionCollections,
  submitQuestionCollection,
  getQuestionsByCollection,
  getCollection,
  deleteCollection,
  unsaveQuestion,
  deleteQuestionOnCollection,
  registerAnswerEvent,
  createImpugnation,
  getImpugnationsByUser,
  userClickedSubscribe,
  getQuestionsByTag,

  getAciertosCountFromUser,
  getFalladasCountFromUser,

  getCurrentRanking,

  getImpugnationsByState,

  getQuestionById,

  approveImpugnation,
  dismissImpugnation,

  requestDuplicationOfTest,

  guidGenerator,

  update_opoIden,

  getSimulations,
  getClassesByOpoId,

  getContentCategories,
  getContentCategoryByCode,
  getContentSubCategories,
  getParentContentCategories,
  updateContentCategoryInfo,
  createContentCategory,
  deleteContentCategory,
  addResourceToContentCategory,
  getResourcesByContentCategory,
  deleteContentCategoryResourceById,

  getLatestCorrectionBySimulationId,

  deleteCategoryByCode,
  addCategory
};
