<template>
  <v-container>
    <h1
      style="
        padding-bottom: 20px;
        font-family: 'Saira Semi Condensed', sans-serif;
      "
    >
      Calendario
    </h1>
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="64">
          <v-toolbar flat>
            <v-btn
              tile
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
            >
              Hoy
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small> mdi-chevron-right </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  tile
                  outlined
                  color="grey darken-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Día</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Semana</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Mes</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>4 días</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="events"
            :event-color="getEventColor"
            :type="type"
            :weekdays="weekdays"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
          ></v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-card color="grey lighten-4" width="400px" flat>
              <v-toolbar :color="selectedEvent.color" dark>
                <v-toolbar-title>
                  <strong
                    ><small>{{ selectedEvent.name }}</small></strong
                  ></v-toolbar-title
                >
                <v-spacer></v-spacer>
                <v-btn icon @click="selectedOpen = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <div>
                  <!-- <v-chip
                    tile
                    label
                    large
                    outlined
                    :color="selectedEvent.color"
                    v-for="res in selectedEvent.resources"
                    :key="res.url"
                    style="cursor: pointer; display: block"
                    class="my-3"
                    @click="openLink(res.url)"
                  >
                    <v-icon v-if="res.type == 'link'" class="mx-2"
                      >mdi-link</v-icon
                    >
                    <v-icon v-else-if="res.type == 'video'" class="mx-2"
                      >mdi-video-box</v-icon
                    >
                    <v-icon v-else-if="res.type == 'file'" class="mx-2"
                      >mdi-file</v-icon
                    >
                    {{ res.name }}
                  </v-chip> -->

                  <v-chip
                    tile
                    label
                    large
                    outlined
                    v-if="
                      selectedEvent.live_class_url &&
                      !selectedEvent.recorded_class_url
                    "
                    :color="selectedEvent.color"
                    style="cursor: pointer; display: block"
                    class="my-3"
                    @click="openLink(selectedEvent.live_class_url)"
                  >
                    <v-icon class="mx-2">mdi-link</v-icon>

                    Clase en directo
                  </v-chip>
                  <v-chip
                    tile
                    label
                    large
                    outlined
                    v-else-if="
                      selectedEvent.recorded_class_url &&
                      selectedEvent.recorded_class_url.length > 0
                    "
                    :color="selectedEvent.color"
                    style="cursor: pointer; display: block"
                    class="my-3"
                    @click="openLink(selectedEvent.recorded_class_url)"
                  >
                    <v-icon class="mx-2">mdi-play-box</v-icon>

                    Clase grabada
                  </v-chip>
                  <v-chip
                    tile
                    label
                    large
                    outlined
                    v-if="selectedEvent.testId"
                    :color="selectedEvent.color"
                    style="cursor: pointer; display: block"
                    class="my-3"
                    @click="
                      $router.push({
                        name: 'test',
                        params: { testId: selectedEvent.testId },
                      })
                    "
                  >
                    <v-icon class="mx-2">mdi-checkbox-marked-outline</v-icon>
                    Test
                  </v-chip>
                  <v-chip
                    tile
                    label
                    large
                    outlined
                    v-if="selectedEvent.documentUrl"
                    :color="selectedEvent.color"
                    style="cursor: pointer; display: block"
                    class="my-3"
                    @click="
                      $router.push({
                        name: 'document',
                        params: { resourceUrl: selectedEvent.documentUrl },
                      })
                    "
                  >
                    <v-icon class="mx-2">mdi-file-pdf-box</v-icon>
                    Temario
                  </v-chip>
                </div>
              </v-card-text>
              <!-- <v-card-actions style="display: flow-root">
                <v-btn
                  text
                  small
                  color="secondary"
                 
                >
                  Entendido
                </v-btn>
              </v-card-actions> -->
            </v-card>
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getClassesByOpoId } from "@/utils";
export default {
  data: () => ({
    focus: "",
    type: "month",
    weekdays: [1, 2, 3, 4, 5, 6, 0],
    typeToLabel: {
      month: "Mes",
      week: "Semana",
      day: "Día",
      "4day": "4 Días",
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
  }),
  async mounted() {
    // this.$refs.calendar.checkChange();
    this.events = await getClassesByOpoId(this.$opoIden.value);
  },
  methods: {
    openLink(link) {
      console.log("link ", link);
      window.open(link, "_blank");
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      const lightgray = "#b9babb";
      if (event.start > new Date()) {
        return event.color || "primary";
      } else {
        return lightgray;
      }
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
  },
};
</script>

<style>
</style>