<template>
  <div style="width: 100%">
    <v-dialog v-model="editCategoryDialog" persistent max-width="450">
      <v-card class="pa-4">
        <v-card-title>Editar tema</v-card-title>
        <v-card-subtitle style="text-align: start">
          Estás viendo esto porque eres administrador
        </v-card-subtitle>
        <v-row class="pa-3">
          <v-col>
            <v-text-field
              label="Título"
              outlined
              dense
              v-model="editCategoryDialogDataCategoryName"
              :disabled="!code"
            ></v-text-field>
            <v-file-input
              style="cursor: pointer"
              v-model="editCategoryDialogDataCategoryImage"
              prepend-icon="mdi-camera"
              accept="image/png, image/jpeg"
              label="Subir nueva imagen de categoría"
              show-size
              :multiple="false"
              v-on="{
                change: (e) =>
                  imageUpload(
                    `category_images/${code}`,
                    editCategoryDialogDataCategoryImage
                  ),
              }"
            />
            <v-color-picker
              v-model="editCategoryDialogDataCategoryColor"
              style="width: 100%"
              type="color"
              hide-canvas
              hide-inputs
              hide-sliders
              show-swatches
              label="Color de la categoría"
            />
          </v-col>
        </v-row>
        <v-btn
          text
          color="error"
          @click.prevent="
            editCategoryDialogDataCategoryName = pageTitle;
            editCategoryDialogDataCategoryColor = categoryColor;
            editCategoryDialog = false;
          "
        >
          Cancelar
        </v-btn>
        <v-btn
          text
          @click.prevent="updateCategory"
          :disabled="!editCategoryDialogDataCategoryName"
        >
          Confirmar
        </v-btn>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addResourceDialog" persistent max-width="450">
      <v-card class="pa-4">
        <v-card-title>Añadir contenido</v-card-title>
        <v-card-subtitle style="text-align: start">
          Estás viendo esto porque eres administrador
        </v-card-subtitle>
        <v-row class="pa-3">
          <v-col>
            <v-text-field
              label="Nombre de la clase"
              outlined
              dense
              v-model="newResourceBeingAdded.name"
            ></v-text-field>
            <v-text-field
              label="Enlace de la clase"
              outlined
              dense
              v-model="newResourceBeingAdded.url"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-btn
          text
          color="error"
          @click.prevent="
            addResourceDialog = false;
            newResourceBeingAdded = {};
          "
        >
          Cancelar
        </v-btn>
        <v-btn text @click.prevent="addResource"> Confirmar </v-btn>
      </v-card></v-dialog
    >
    <v-dialog v-model="addPDFDialog" persistent max-width="450">
      <v-card class="pa-4">
        <v-card-title>Añadir PDF</v-card-title>
        <v-card-subtitle style="text-align: start">
          Estás viendo esto porque eres administrador
        </v-card-subtitle>
        <v-row class="pa-3">
          <v-col>
            <v-text-field
              label="Nombre del documento"
              outlined
              dense
              v-model="newResourceBeingAdded.name"
            ></v-text-field>
            <v-file-input
              style="cursor: pointer"
              v-model="newFileBeingUploaded"
              prepend-icon="mdi-file-pdf-box"
              accept=".pdf"
              label="Subir nuevo documento"
              show-size
              :multiple="false"
              v-on="{
                change: (e) => {
                  documentUpload(
                    `resources/${newResourceBeingAdded.name}`,
                    newFileBeingUploaded
                  );
                },
              }"
            />
          </v-col>
        </v-row>
        <v-btn
          text
          color="error"
          @click.prevent="
            addPDFDialog = false;
            newResourceBeingAdded = {};
          "
        >
          Cancelar
        </v-btn>
      </v-card></v-dialog
    >
    <v-dialog v-model="addSubcategoryDialog" persistent max-width="450">
      <v-card class="pa-4">
        <v-card-title>Añadir subtema</v-card-title>
        <v-card-subtitle style="text-align: start">
          Estás viendo esto porque eres administrador
        </v-card-subtitle>
        <v-row class="pa-3">
          <v-col>
            <v-text-field
              label="Título"
              outlined
              dense
              v-model="addSubcategoryDialogDataCategoryName"
            ></v-text-field>
            <v-color-picker
              v-model="addSubcategoryDialogDataCategoryColor"
              style="width: 100%"
              type="color"
              hide-canvas
              hide-inputs
              hide-sliders
              show-swatches
              label="Color de la categoría"
            />
          </v-col>
        </v-row>
        <v-btn
          text
          color="error"
          @click.prevent="
            addSubcategoryDialogDataCategoryName = null;
            addSubcategoryDialogDataCategoryColor = null;
            addSubcategoryDialog = false;
          "
        >
          Cancelar
        </v-btn>
        <v-btn
          text
          @click.prevent="createSubcategory"
          :disabled="
            addSubcategoryDialogDataCategoryName
              ? !addSubcategoryDialogDataCategoryName.trim()
              : true
          "
        >
          Confirmar
        </v-btn>
      </v-card></v-dialog
    >
    <div
      v-if="currentUser && currentUser.userIsAdmin"
      style="background: #eeeeee; padding: 10px"
    >
      <span
        v-if="code"
        style="text-decoration: underline; cursor: pointer"
        @click.prevent="editCategoryDialog = true"
        >Editar tema</span
      ><span v-if="code" style="margin-left: 20px; margin-right: 20px">|</span>
      <span
        style="text-decoration: underline; cursor: pointer"
        @click.prevent="addSubcategoryDialog = true"
        >Añadir subtema</span
      >
      <span v-if="code" style="margin-left: 20px; margin-right: 20px">|</span>
      <span
        v-if="code"
        style="text-decoration: underline; cursor: pointer"
        @click.prevent="deleteCat"
        >Eliminar tema</span
      >
      <span v-if="code" style="margin-left: 20px; margin-right: 20px">|</span>
      <span
        v-if="code"
        style="text-decoration: underline; cursor: pointer"
        @click.prevent="addResourceDialog = true"
        >Añadir contenido</span
      >
      <span v-if="code" style="margin-left: 20px; margin-right: 20px">|</span>
      <span
        v-if="code"
        style="text-decoration: underline; cursor: pointer"
        @click.prevent="addPDFDialog = true"
        >Añadir PDF</span
      >
    </div>
    <div v-if="!loading" class="page-header">
      <div
        class="page-title"
        style="font-family: 'Saira Semi Condensed', sans-serif"
      >
        {{ pageTitle }}
      </div>
    </div>
    <div
      v-if="!loading && parentTags && parentTags.length > 1"
      class="breadcrumbs"
    >
      <span v-for="tag in parentTags" :key="tag.code">
        <span
          class="pa-1 breadcrumb"
          @click.prevent="
            $router.push({ name: 'content', params: { code: tag.code } })
          "
        >
          {{ tag.name }}
        </span>
        <span v-if="!tag.last" class="pa-2"> > </span>
      </span>
    </div>
    <div v-if="!loading" style="color: white" class="page-container">
      <div class="features">
        <div v-for="tag in subTags" :key="tag.name">
          <div class="feature-item">
            <category-card :categoryInfo="tag" />
          </div>
        </div>
      </div>
      <v-chip
        label
        x-large
        v-for="r in filterNotPDF(contentCategoryResources)"
        :key="r.id"
        @click="goTo(r.url)"
      >
        <v-icon class="mx-3">mdi-play</v-icon>{{ r.name }}
      </v-chip>
      <br /><br />
      <div v-if="currentUser && currentUser.userIsAdmin">
        <v-chip
          label
          color="error"
          small
          v-for="r in filterNotPDF(contentCategoryResources)"
          :key="r.id + '_delete'"
          @click="deleteContentCategoryResource(r.id)"
        >
          <v-icon class="mx-3">mdi-delete</v-icon>{{ r.name }}
        </v-chip>
      </div>
      <div class="pa-5">
        <v-chip
          label
          x-large
          v-for="r in filterPDF(contentCategoryResources)"
          :key="r.id"
          @click="goTo(r.url)"
        >
          <v-icon class="mx-3">mdi-file-pdf-box</v-icon>{{ r.name }}
        </v-chip>
        <br /><br />
        <div v-if="currentUser && currentUser.userIsAdmin">
          <v-chip
            label
            color="error"
            small
            v-for="r in filterPDF(contentCategoryResources)"
            :key="r.id + '_delete'"
            @click="deleteContentCategoryResource(r.id)"
          >
            <v-icon class="mx-3">mdi-delete</v-icon>{{ r.name }}
          </v-chip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getTestsByTag,
  getSubTags,
  getTagByCode,
  getParentTags,
  uploadImage,
  updateTagInfo,
  createCategory,
  getContentCategories,
  getContentCategoryByCode,
  getContentSubCategories,
  getParentContentCategories,
  updateContentCategoryInfo,
  createContentCategory,
  deleteContentCategory,
  getResourcesByContentCategory,
  deleteContentCategoryResourceById,
  addResourceToContentCategory,
} from "@/utils";
import CustomTestComponent from "../components/CustomTestComponent.vue";
import CategoryCard from "../components/CategoryCard.vue";
import getCurrentUser from "@/getCurrentUser";
export default {
  name: "TestCategoryView",
  components: { CustomTestComponent, CategoryCard },
  props: {
    code: {
      type: String || null,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      pageTag: null,
      subTags: null,
      parentTags: null,
      testList: null,
      categoryColor: "#000000",
      pageTitle: null,
      loadingMore: false,
      itemsLimitReached: false,
      pageSize: 10,
      customTestDialog: false,
      currentUser: null,
      editCategoryDialog: false,
      editCategoryDialogDataCategoryName: null,
      editCategoryDialogDataCategoryImage: null,
      editCategoryDialogDataCategoryColor: null,

      addSubcategoryDialog: false,
      addSubcategoryDialogDataCategoryName: null,
      addSubcategoryDialogDataCategoryColor: null,

      addResourceDialog: false,
      contentCategoryResources: null,
      newResourceBeingAdded: {},
      newFileBeingUploaded: null,
      addPDFDialog: false,
    };
  },
  async created() {
    [
      this.parentTags,
      this.pageTag,
      this.subTags,
      this.testList,
      this.currentUser,
      this.contentCategoryResources,
    ] = await Promise.all([
      getParentContentCategories(this.code, this.$opoIden.value),
      getContentCategoryByCode(this.code, this.$opoIden.value),
      getContentSubCategories(this.code, this.$opoIden.value),
      getTestsByTag(this.code, 0),
      getCurrentUser(),
      getResourcesByContentCategory(this.code, this.$opoIden.value),
    ]);
    this.itemsLimitReached = this.testList.length < 10;
    this.parentTags.unshift({ name: "Temario", code: null });
    this.parentTags[this.parentTags.length - 1].last = true;
    this.pageTitle = this.pageTag ? this.pageTag.name : "Temario";
    this.editCategoryDialogDataCategoryName = this.pageTitle;
    this.categoryColor = this.pageTag
      ? this.pageTag.color
        ? this.pageTag.color
        : this.categoryColor
      : this.categoryColor;
    this.editCategoryDialogDataCategoryColor = this.categoryColor;
    this.loading = false;
  },
  methods: {
    filterNotPDF(array) {
      return array.filter((x) => x.type != "pdf");
    },
    filterPDF(array) {
      return array.filter((x) => x.type == "pdf");
    },
    async deleteCat() {
      if (
        confirm(
          "¿Seguro que quieres eliminar el tema? Esta acción no se puede deshacer"
        )
      ) {
        await deleteContentCategory(this.code, this.$opoIden.value);
        this.$router.push({ name: "content" });
      }
    },
    goTo(url) {
      window.open(url, "_blank");
    },
    async addResource() {
      await addResourceToContentCategory(
        this.code,
        this.$opoIden.value,
        this.newResourceBeingAdded
      );
      this.addResourceDialog = false;
      await this.loadMore();
    },
    async deleteContentCategoryResource(id) {
      if (
        confirm(
          "¿Seguro que quieres eliminar el recurso " +
            id +
            "? Esta acción no se puede deshacer"
        )
      ) {
        this.loading = true;
        await deleteContentCategoryResourceById(this.$opoIden.value, id);
        this.contentCategoryResources = await getResourcesByContentCategory(
          this.code,
          this.$opoIden.value
        );
        this.loading = false;
      }
    },
    async loadMore() {
      this.loading = true;
      this.contentCategoryResources = await getResourcesByContentCategory(
        this.code,
        this.$opoIden.value
      );
      this.loading = false;
      // this.loadingMore = true;
      // const newTests = await getTestsByTag(
      //   this.code,
      //   this.testList[this.testList.length - 1].title
      // );
      // this.testList = this.testList.concat(newTests);
      // if (
      //   !newTests ||
      //   newTests.length == 0 ||
      //   newTests.length < this.pageSize
      // ) {
      //   this.itemsLimitReached = true;
      // }
      // this.loadingMore = false;
    },
    async imageUpload(path, img) {
      return uploadImage(path, img);
    },

    async documentUpload(path, doc) {
      const downloadURL = await uploadImage(path, doc);
      console.log(downloadURL);
      await addResourceToContentCategory(this.code, this.$opoIden.value, {
        name: this.newResourceBeingAdded.name,
        url: downloadURL,
        type: "pdf",
      });
      this.newResourceBeingAdded = {};
      this.addPDFDialog = false;
      this.loadMore();
    },
    async updateCategory() {
      await updateContentCategoryInfo(
        this.code,
        {
          code: this.code,
          name: this.editCategoryDialogDataCategoryName,
          color: this.editCategoryDialogDataCategoryColor,
        },
        this.$opoIden.value
      );
      this.pageTitle = this.editCategoryDialogDataCategoryName;
      this.categoryColor = this.editCategoryDialogDataCategoryColor;
      this.editCategoryDialog = false;
    },
    async createSubcategory() {
      const newCode =
        (this.code ? this.code + ".." : "") +
        this.addSubcategoryDialogDataCategoryName
          .trim()
          .split(" ")
          .map((x) => x.replace(/[^a-z0-9]/gi, ""))
          .join("_")
          .toUpperCase();
      await createContentCategory(
        {
          code: newCode,
          name: this.addSubcategoryDialogDataCategoryName,
          color: this.addSubcategoryDialogDataCategoryColor.hex,
        },
        this.$opoIden.value
      );
      this.addSubcategoryDialogDataCategoryName = null;
      this.addSubcategoryDialogDataCategoryColor = null;
      this.addSubcategoryDialog = false;
      window.location.reload();
    },
  },
};
</script>

<style>
.page-header {
  /* background: linear-gradient(117deg, rgba(40,43,131,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%); */
  padding: 50px;
}

.breadcrumbs {
  padding: 10px;
  background: white;
}

.breadcrumb:hover {
  text-decoration: underline;
  cursor: pointer;
}

.page-title {
  font-size: 36px;
  font-weight: 800;
}

.features {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding-top: 40px;
}

.feature-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: whitesmoke;
  margin: 0px 40px 40px 40px;
  border-radius: 0px;
}
</style>
