var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',{staticStyle:{"padding-bottom":"20px","font-family":"'Saira Semi Condensed', sans-serif"}},[_vm._v(" Impugnaciones ")]),(_vm.loading)?_c('v-row',{attrs:{"align":"center","justify":"center"}},[(_vm.loading || !_vm.impugnationsData)?_c('v-data-table',{attrs:{"headers":_vm.impugnationsTable.headers,"loading":"","loading-text":"Cargando tus impugnaciones... Por favor espera"}}):_vm._e()],1):_c('div',[_c('div',{staticStyle:{"padding-bottom":"20px","cursor":"pointer"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.impugnationsTable.headers,"items":_vm.impugnationsData,"search":_vm.search,"sort-by":['timestamp'],"sort-desc":true,"no-data-text":"No has hecho ninguna impugnación todavía"},scopedSlots:_vm._u([{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticStyle:{"font-weight":"bold"},attrs:{"color":_vm.getColor(item.state),"dark":"","small":""}},[_vm._v(" "+_vm._s(item.state)+" ")])]}},{key:"item.message",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.message)?_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-message ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.message))])])]}}])},[_vm._v(" > ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }