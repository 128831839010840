<template>
  <v-card class="mx-auto" outlined>
    <v-card-text>
      <p class="display-1 text--primary">Administración de etiquetas</p>
      <p class="caption-3 text--secondary">
        Estás viendo esto porque eres contribuidor
      </p>
      <v-btn v-if="!loading" @click="newCategoryDialog = true">Añadir</v-btn>
      <v-select
        v-model="category"
        :items="availableCategories"
        label="Categoría"
        item-text="name"
        item-value="code"
        return-object
      ></v-select>
      <v-row v-if="loading" align="center" justify="center">
        <v-col class="text-center">
          <v-progress-circular :size="100" indeterminate />
        </v-col>
      </v-row>
      <div v-else-if="category">
        <div>nombre: {{ category.name }}</div>
        <div>código: {{ category.code }}</div>
        <div>oculta: {{ !!category.hidden ? "Sí" : "No" }}</div>
        <v-btn small color="error" @click="deleteCategory">eliminar</v-btn>
      </div>
    </v-card-text>
    <v-dialog v-model="newCategoryDialog" persistent width="450">
      <v-card>
        <v-card-text>
          <v-text-field label="Nombre" v-model="newCategoryData.name" />
          <v-select
            v-model="newCategoryData.parent"
            :items="availableCategories"
            label="Supercategoría"
            item-text="name"
            item-value="code"
            return-object
          ></v-select>
          <v-checkbox v-model="newCategoryData.hidden" label="Oculta" />
          <v-btn @click="submitCategory">Ok</v-btn>
          <v-btn
            @click="
              newCategoryDialog = false;
              newCategoryData = {};
            "
            >cancelar</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { getTags, deleteCategoryByCode, addCategory } from "@/utils";

export default {
  name: "AdministrarTags",
  data: () => ({
    loading: true,
    availableCategories: null,
    category: null,
    search: "",
    newCategoryDialog: false,
    newCategoryData: {},
  }),
  async created() {
    this.availableCategories = await getTags(true, true);
    this.loading = false;
  },

  methods: {
    async submitCategory() {
      const newCode =
        (this.newCategoryData.parent
          ? this.newCategoryData.parent.code + ".."
          : "") +
        this.newCategoryData.name
          .trim()
          .split(" ")
          .map((x) => x.replace(/[^a-z0-9]/gi, ""))
          .join("_")
          .toUpperCase();
      const categoryToSubmit = {
        name: this.newCategoryData.name,
        hidden: this.newCategoryData.hidden == true,
        code: newCode,
      };
      await addCategory(categoryToSubmit);
      alert("Nueva categoría subida correctamente");
      this.newCategoryDialog = false;
      this.availableCategories = await getTags(true, true);
    },
    async deleteCategory() {
      if (
        confirm(
          "¿Seguro que quieres eliminar la categoría? Esta acción es definitiva y no se puede deshacer"
        )
      ) {
        this.loading = true;
        await deleteCategoryByCode(this.category.code);
        alert("Categoría borrada correctamente");
        this.category = null;
        this.availableCategories = await getTags(true, true);
        this.loading = false;
      }
    },
  },
};
</script>
