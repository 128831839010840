var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('h1',{staticStyle:{"padding":"20px","font-family":"'Saira Semi Condensed', sans-serif"}},[_vm._v(" Mi cuenta ")]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"7","md":"7","lg":"7"}},[(_vm.userIsAdmin || _vm.userIsContributor)?_c('v-list',{staticStyle:{"text-align":"start","background":"#192f44"},attrs:{"subheader":"","dark":""}},[_c('v-subheader',[_vm._v("Panel de administración")]),(_vm.userIsAdmin)?_c('v-list-item',[_c('v-list-item-content',[_c('span',[_vm._v("Ir a la página de "),_c('a',{staticStyle:{"color":"lightgoldenrodyellow"},attrs:{"href":"/admin"}},[_vm._v("administrador")])])])],1):_vm._e(),_c('v-divider'),_c('v-list-item',[_c('v-list-item-content',[_c('span',[_vm._v(" Ir a la página de "),_c('a',{staticStyle:{"color":"lightgoldenrodyellow"},attrs:{"href":"/contributor"}},[_vm._v("contribuidor")])])])],1)],1):_vm._e(),_c('p'),(_vm.userIsLead && _vm.$opoIden.value == 'Bomberos')?_c('Pricing',{on:{"subscribe":_vm.suscribirme}}):(_vm.userIsLead && _vm.$opoIden.value == "Mossos d'Esquadra")?_c('PricingMossos',{on:{"subscribe":_vm.suscribirmeMossos}}):_vm._e(),_c('p'),_c('v-list',{staticStyle:{"text-align":"start"},attrs:{"subheader":""}},[_c('v-subheader',[_vm._v("General")]),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Nombre")]),(_vm.editingName)?_c('v-text-field',{attrs:{"rules":[
                  function (value) { return !!value || 'Indica tu nombre'; },
                  function (value) { return value.length < 20 || 'Máximo 20 caracteres'; } ],"counter":"20"},model:{value:(_vm.nameBeingEdited),callback:function ($$v) {_vm.nameBeingEdited=$$v},expression:"nameBeingEdited"}}):_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.auth.currentUser.displayName))])],1),_c('v-list-item-action',[(!_vm.editingName)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.editingName = true}}},[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-pencil-outline")])],1):_c('span',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.editingName = false}}},[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-close")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.updateDisplayName}},[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-check")])],1)],1)],1)],1),_c('v-divider'),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Correo electrónico")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.auth.currentUser.email)+" "+_vm._s(_vm.userEmailIsVerified ? "" : "(No verificado)"))])],1)],1),_c('v-divider'),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Oposición")]),(_vm.editingOpo)?_c('v-select',{attrs:{"items":_vm.availableOpoIds},on:{"change":function($event){_vm.editingOpo = false;
                  _vm.updateOpoId();}},model:{value:(_vm.$opoIden.value),callback:function ($$v) {_vm.$set(_vm.$opoIden, "value", $$v)},expression:"$opoIden.value"}}):_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$opoIden.value))])],1),_c('v-list-item-action',[(!_vm.editingOpo)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.editingOpo = true}}},[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-pencil-outline")])],1):_c('span',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.editingOpo = false}}},[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-close")])],1)],1)],1)],1),_c('v-divider'),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Estado de la suscripción")]),(_vm.userIsPremium)?_c('v-list-item-subtitle',[_vm._v("Activa "),_c('span',{on:{"click":_vm.gestionarSuscripcion}},[_c('a',{staticStyle:{"text-decoration":"underline"}},[_vm._v("Gestionar suscripción")])])]):(_vm.userIsPremiumAcademy)?_c('v-list-item-subtitle',[_vm._v("Activa y sincronizada con la academia")]):(_vm.userIsLead)?_c('v-list-item-subtitle',[_c('strong',[_vm._v("Inactiva")]),_vm._v(". "),_c('span',{on:{"click":_vm.suscribirme}},[_c('a',{staticStyle:{"text-decoration":"underline"}},[_vm._v("Suscríbete para acceder al contenido")])])]):_vm._e()],1)],1)],1),_c('p'),_c('v-list',{staticStyle:{"text-align":"start"},attrs:{"subheader":""}},[_c('v-subheader',[_vm._v("Mis estadísticas")]),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Nivel: "+_vm._s(Math.floor( _vm.my_stats.total_aciertos / _vm.my_stats.aciertos_para_subir_de_nivel )))]),_c('v-list-item-subtitle',{staticStyle:{"margin-top":"10px"}},[_vm._v("Acierta preguntas para subir de nivel y conseguir logros ")]),_c('v-progress-linear',{staticStyle:{"margin-top":"10px"},attrs:{"color":"green","rounded":"","height":"30","value":(100 *
                    (_vm.my_stats.total_aciertos %
                      _vm.my_stats.aciertos_para_subir_de_nivel)) /
                  _vm.my_stats.aciertos_para_subir_de_nivel},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var value = ref.value;
return [_c('small',[_vm._v(_vm._s(_vm.my_stats.total_aciertos)+" / "+_vm._s(_vm.my_stats.aciertos_para_subir_de_nivel * Math.ceil( _vm.my_stats.total_aciertos / _vm.my_stats.aciertos_para_subir_de_nivel )))])]}}])})],1)],1),_c('v-divider'),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Preguntas respondidas")]),_c('small',{staticStyle:{"margin-top":"10px"}},[_vm._v(_vm._s(_vm.my_stats.total_respondidas))])],1)],1),_c('v-divider'),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Preguntas acertadas")]),_c('small',{staticStyle:{"margin-top":"10px"}},[_vm._v(_vm._s(_vm.my_stats.total_aciertos))])],1)],1),_c('v-divider'),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Preguntas falladas")]),_c('small',{staticStyle:{"margin-top":"10px"}},[_vm._v(_vm._s(_vm.my_stats.total_fallos))])],1)],1)],1),_c('p'),_c('p')],1)],1),_c('div',{staticClass:"commonZone"},[_c('v-btn',{attrs:{"small":"","text":""},on:{"click":_vm.cerrarSesion}},[_vm._v("Cerrar sesión")]),_c('p')],1),_c('v-dialog',{attrs:{"persistent":"","width":"300"},model:{value:(_vm.redirecting),callback:function ($$v) {_vm.redirecting=$$v},expression:"redirecting"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',{staticStyle:{"padding":"20px"}},[_vm._v(" Espera mientras te redirigimos... ")]),_c('v-progress-linear',{attrs:{"indeterminate":"","color":"blue darken-4"}})],1)],1),_c('v-dialog',{attrs:{"persistent":"","width":"450"},model:{value:(_vm.syncing),callback:function ($$v) {_vm.syncing=$$v},expression:"syncing"}},[_c('v-card',{attrs:{"color":"black","dark":""}},[_c('v-card-text',{staticStyle:{"padding":"20px"}},[_vm._v(" Vinculando cuenta... Esto puede tardar unos segundos ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }