<template>
  <v-container>
    <h1
      style="
        padding-bottom: 20px;
        font-family: 'Saira Semi Condensed', sans-serif;
      "
    >
      Impugnaciones
    </h1>

    <v-row v-if="loading" align="center" justify="center">
      <v-data-table
        v-if="loading || !impugnationsData"
        :headers="impugnationsTable.headers"
        loading
        loading-text="Cargando tus impugnaciones... Por favor espera"
      ></v-data-table>
    </v-row>

    <div v-else>
      <div style="padding-bottom: 20px; cursor: pointer">
        <v-data-table
          :headers="impugnationsTable.headers"
          :items="impugnationsData"
          :search="search"
          :sort-by="['timestamp']"
          :sort-desc="true"
          class="elevation-1"
          no-data-text="No has hecho ninguna impugnación todavía"
        >
          >
          <template v-slot:item.state="{ item }">
            <v-chip
              :color="getColor(item.state)"
              dark
              small
              style="font-weight: bold"
            >
              {{ item.state }}
            </v-chip>
          </template>
          <template v-slot:item.message="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" v-if="item.message">
                  mdi-message
                </v-icon>
              </template>
              <span>{{ item.message }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </div>
    </div>
  </v-container>
</template>

<script>
import { getImpugnationsByUser } from "@/utils.js";
export default {
  name: "MyImpugnations",
  data: () => ({
    loading: false,
    rawImpugnations: null,
    impugnationsData: null,
    search: "",
    impugnationsTable: {
      headers: [
        {
          text: "Pregunta",
          value: "question_text",
          sortable: false,
        },
        {
          text: "Motivo",
          value: "reason",
          sortable: false,
        },
        {
          text: "Fecha",
          value: "timestamp",
        },
        {
          text: "Estado",
          value: "state",
          sortable: false,
        },
        {
          text: "Mensaje",
          value: "message",
          sortable: false,
        },
      ],
    },
  }),
  async created() {
    await this.loadImpugnations();
  },
  methods: {
    mapImpugnations(impugnations) {
      return impugnations.map((impugnation) => {
        return {
          id: impugnation.id,
          timestamp: impugnation.impugnatedAt
            ? impugnation.impugnatedAt.toDate().toISOString().split("T")[0]
            : null,
          reason: impugnation.reason,
          state: impugnation.state,
          question_text: impugnation.question.text,
          message: impugnation.resolutionMessage,
        };
      });
    },
    async loadImpugnations() {
      this.loading = true;
      try {
        this.rawImpugnations = await getImpugnationsByUser(0);
        this.impugnationsData = this.mapImpugnations(this.rawImpugnations);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    // seeImpugnation(item) {
    //   this.$router.push({
    //     name: "impugnation_review",
    //     params: { impugnationId: item.id },
    //   });
    // },
    getColor(state) {
      if (state == "En revisión") return "orange";
      if (state == "Aprobada") return "success";
      if (state == "Rechazada") return "red";
    },
  },
};
</script>
