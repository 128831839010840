<template>
  <v-container>
    <v-row>
      <v-col>
        <administrar-contributors />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AdministrarContributors from "@/components/admin/AdministrarContributors.vue";
export default {
  name: "AdminPanel",
  components: {
    AdministrarContributors,
  },
};
</script>

<style></style>
