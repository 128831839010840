<template>
  <v-text-field
    v-model="myPassword"
    color="black"
    label="Contraseña"
    :rules="rules"
    outlined
    required
    style="border-radius: 0px"
    :append-icon="visible ? 'mdi-eye' : 'mdi-eye-off'"
    :type="!visible ? 'password' : 'text'"
    @click:append="() => (visible = !visible)"
  ></v-text-field>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "Password",
  props: {
    password: {
      required: true,
      default: "",
      type: String,
    },
    rules: {
      required: false,
      default: function () {
        return [(v) => !!v || "No puede ser vacía"];
      },
    },
  },
  data() {
    return {
      visible: false,
      myPassword: this.password,
    };
  },
  watch: {
    myPassword(newValue) {
      this.$emit("update:password", newValue);
    },
  },
});
</script>
