<template>
  <v-container>
    <h1
      style="
        padding-bottom: 20px;
        font-family: 'Saira Semi Condensed', sans-serif;
      "
    >
      Impugnaciones
    </h1>
    <v-dialog v-model="impugnationResolutionDialog" persistent max-width="450">
      <v-card class="pa-4">
        <v-card-title
          >{{ impugnationResolutionDecision }} impugnación</v-card-title
        >
        <v-card-subtitle style="text-align: start">
          Si quieres dar alguna aclaración al alumno hazlo aquí
        </v-card-subtitle>
        <v-row class="pa-3">
          <v-col>
            <v-textarea
              v-model="impugnationResolutionText"
              label="Explicación"
              outlined
              counter="120"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              impugnationResolutionDialog = false;
              impugnationResolutionText = '';
            "
          >
            Cancelar
          </v-btn>
          <v-btn tile color="success" @click="resolveImpugnation">
            {{ impugnationResolutionDecision }}
          </v-btn>
        </v-card-actions>
      </v-card></v-dialog
    >
    <v-dialog
      v-model="editQuestionDialog"
      v-if="questionIdBeingEdited"
      persistent
    >
      <QuestionEdit
        :qid="questionIdBeingEdited"
        :availableCategories="availableCategories"
        @close-dialog="editQuestionDialog = false"
      />
    </v-dialog>
    <v-row v-if="loading" align="center" justify="center">
      <v-data-table
        v-if="loading || !impugnationsData"
        :headers="impugnationsTable.headers"
        loading
        loading-text="Cargando tus impugnaciones... Por favor espera"
      ></v-data-table>
    </v-row>
    <div v-else>
      <v-chip-group mandatory active-class="primary--text">
        <v-chip @click="search = ''">Todas</v-chip>
        <v-chip @click="search = ' Barrios'"
          ><v-icon left> mdi-account-circle-outline </v-icon>Ramón</v-chip
        >
        <v-chip @click="search = 'Arnau Velasco'"
          ><v-icon left> mdi-account-circle-outline </v-icon>Arnau</v-chip
        >
        <v-chip @click="search = 'Marcial'"
          ><v-icon left> mdi-account-circle-outline </v-icon>Marcial</v-chip
        >
      </v-chip-group>
      <div style="padding-bottom: 20px; padding-top: 10px; cursor: pointer">
        <v-data-table
          :headers="impugnationsTable.headers"
          :items="impugnationsData"
          :search="search"
          :sort-by="['timestamp']"
          :sort-desc="true"
          class="elevation-1"
          no-data-text="Todo resuelto"
        >
          <template v-slot:item.edit_q="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="
                {
                  impugnationBeingResolved = item.id;
                  impugnationResolutionDecision = 'Rechazar';
                  impugnationResolutionDialog = true;
                }
              "
            >
              mdi-close
            </v-icon>
            <v-icon
              small
              @click="
                {
                  impugnationBeingResolved = item.id;
                  impugnationResolutionDecision = 'Aprobar';
                  impugnationResolutionDialog = true;
                }
              "
            >
              mdi-check
            </v-icon>
          </template>
        </v-data-table>
        <v-snackbar
          v-model="showImpugnationSnackbar"
          timeout="3000"
          text
          color="success"
          style="margin-bottom: 60px"
        >
          Impugnación resuelta correctamente
        </v-snackbar>
      </div>
    </div>
  </v-container>
</template>
  
  <script>
import {
  getImpugnationsByState,
  getImpugnationsByUser,
  getTags,
  approveImpugnation,
  dismissImpugnation,
} from "@/utils.js";
import QuestionEdit from "./QuestionEdit.vue";

export default {
  name: "AdminImpugnations",
  components: { QuestionEdit },
  data: () => ({
    loading: false,
    rawImpugnations: null,
    impugnationsData: null,
    search: "",
    editQuestionDialog: false,
    questionIdBeingEdited: null,
    availableCategories: null,
    impugnationResolutionDialog: false,
    impugnationResolutionText: "",
    impugnationResolutionDecision: "",
    impugnationBeingResolved: null,
    showImpugnationSnackbar: false,
    impugnationsTable: {
      headers: [
        {
          text: "Pregunta",
          value: "question_text",
          sortable: true,
        },
        {
          text: "Motivo",
          value: "reason",
          sortable: false,
        },
        {
          text: "Fecha",
          value: "timestamp",
        },
        {
          text: "Autor",
          value: "authorDisplayName",
        },
        {
          text: "Editar pregunta",
          value: "edit_q",
          sortable: false,
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
        },
      ],
    },
  }),
  async created() {
    await this.loadImpugnations();
    this.availableCategories = await getTags(true);
  },
  methods: {
    async resolveImpugnation() {
      try {
        if (this.impugnationResolutionDecision === "Aprobar") {
          await approveImpugnation(
            this.impugnationBeingResolved,
            this.impugnationResolutionText
          );
          this.impugnationResolutionDialog = false;
        } else if (this.impugnationResolutionDecision === "Rechazar") {
          await dismissImpugnation(
            this.impugnationBeingResolved,
            this.impugnationResolutionText
          );
          this.impugnationResolutionDialog = false;
        }
        this.impugnationsData = this.impugnationsData.filter(
          (x) => x.id !== this.impugnationBeingResolved
        );
        this.impugnationResolutionText = "";
        this.showImpugnationSnackbar = true;
      } catch (error) {
        alert(error);
        this.impugnationResolutionDialog = false;
      }
    },
    editItem(item) {
      this.impugnationBeingResolved = item.id;
      this.questionIdBeingEdited = item.question_id;
      this.editQuestionDialog = true;
    },
    mapImpugnations(impugnations) {
      return impugnations.map((impugnation) => {
        return {
          id: impugnation.id,
          timestamp: impugnation.impugnatedAt
            ? impugnation.impugnatedAt.toDate().toISOString().split("T")[0]
            : null,
          reason: impugnation.reason,
          state: impugnation.state,
          question_text: impugnation.question.text,
          question_id: impugnation.question.qid,
          authorDisplayName: impugnation.question.authorDisplayName,
        };
      });
    },
    async loadImpugnations() {
      this.loading = true;
      try {
        this.rawImpugnations = await getImpugnationsByState();
        this.impugnationsData = this.mapImpugnations(this.rawImpugnations);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
  