<template>
  <div v-if="!loading">
    <h2 style="margin-top: 20px">
      Revisión del test {{ submission.testName }}
    </h2>
    <a v-if="simulation_correction" @click="rankingDialog = true"
      >Ver ranking</a
    >
    <p></p>
    <div>
      <h3 v-if="submission.position" class="my-4">
        Clasificación en simulacro: {{ submission.position }}
      </h3>
      <h2 v-if="binaryGrade">{{ displayableBinaryGrade }}</h2>
      <v-progress-circular
        v-if="!binaryGrade"
        rotate="270"
        :size="100"
        :width="15"
        :value="displayableGrade"
        :color="
          ['hsl(', (displayableGrade * 1.2).toString(10), ',80%,50%)'].join('')
        "
      >
        <strong style="font-size: x-large">{{ displayableGrade / 10 }}</strong>
      </v-progress-circular>
    </div>
    <small
      v-if="!binaryGrade"
      style="text-decoration: underline; cursor: pointer"
      @click="gradeTooltip = true"
      >¿Cómo se calcula tu nota?</small
    >
    <v-dialog
      v-if="simulation_correction"
      v-model="rankingDialog"
      max-width="450"
    >
      <v-card>
        <v-card-title> Clasificación </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item
              v-for="(u, i) in simulation_correction.ranking"
              :key="u.uid + i"
            >
              {{ i + 1 }}.- {{ u.userDisplayName }}</v-list-item
            >
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="gradeTooltip" max-width="450">
      <v-card>
        <v-card-title> ¿Cómo se calcula tu nota? </v-card-title>
        <p></p>
        <v-card-title style="font-size: medium"> Tu nota </v-card-title>

        <v-card-text style="text-align: start">
          <p>
            Los tests están puntuados sobre 10. Cada pregunta acertada suma 10
            entre el número de preguntas.
          </p>
          <p>Las preguntas que dejes en blanco ni suman ni restan nota.</p>
          <p>
            Las preguntas respondidas incorrectamente descuentan un cuarto de lo
            que sumarían si estuvieran bien respondidas.
          </p>
          <p>
            Por ejemplo, si el examen tiene 10 preguntas, por cada una que
            aciertes se sumará 1 a la nota. Y por cada error, descontará 0.25.
          </p>
        </v-card-text>
        <v-card-title style="font-size: medium"> % de acierto </v-card-title>
        <v-card-text style="text-align: start">
          <p>
            El porcentaje de acierto se calcula solo con las preguntas
            acertadas.
          </p>

          <p>
            No tiene en cuenta los errores y por tanto siempre será igual o
            superior a la nota. Por ejemplo, si el test tiene 10 preguntas, y
            aciertas 5 de ellas, dejando las demás en blanco, tu nota será 5 y
            el porcentaje de acierto 50%. Pero si aciertas 5 y fallas las otras
            5, tu porcentaje seguirá siendo 50% mientras que la nota se reducirá
            a 3.75 porque cada fallo descontará 0.25.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="gradeTooltip = false">Entendido</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <p></p>
    <v-container>
      <v-dialog v-model="impugnationDialog" persistent max-width="450">
        <v-card class="pa-4">
          <v-card-title>Impugnar pregunta</v-card-title>
          <v-card-subtitle style="text-align: start">
            Por favor indica brevemente el motivo de tu impugnación
          </v-card-subtitle>
          <v-row class="pa-3">
            <v-col>
              <v-textarea
                v-model="impugnationText"
                label="Explicación"
                outlined
                counter="120"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="
                impugnationDialog = false;
                impugnationText = '';
              "
            >
              Cancelar
            </v-btn>
            <v-btn
              tile
              color="success"
              @click="impugnateQuestion"
              :disabled="impugnationText.length == 0"
            >
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-card></v-dialog
      >
      <v-row>
        <v-col>
          <div>
            <span
              class="question-num"
              :class="{ wrong: q.answer != q.question.correcta }"
              v-on="{
                click: (e) => {
                  selectQuestion(e, q);
                  indexSelected = q.index;
                },
              }"
              v-for="q in submission.test"
              :key="q.index"
            >
              {{ q.index + 1 }}
            </span>
          </div>
          <div style="margin-bottom: 40px" />
          <div v-if="questionSelected">
            <div id="q">
              <v-img
                v-if="questionSelected.question.imgUrl"
                :src="questionSelected.question.imgUrl"
                :aspect-ratio="16 / 9"
                style="margin-bottom: 20px"
                contain
              />
              <h4 style="text-align: start">
                {{ questionSelected.index + 1 }}.-
                {{ questionSelected.question.text }}
              </h4>
              <v-radio-group
                :value="questionSelected.answer"
                :mandatory="false"
                readonly
              >
                <v-radio
                  v-for="t in Object.keys(
                    questionSelected.question.options
                  ).sort()"
                  :key="t"
                  :value="t"
                >
                  <template v-slot:label>
                    <div v-if="questionSelected.question.correcta == t">
                      <strong style="color: darkgreen">{{
                        questionSelected.question.options[t]
                      }}</strong>
                    </div>
                    <div v-else>
                      {{ questionSelected.question.options[t] }}
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
              <p v-if="questionSelected.certainty">
                Marcaste la respuesta con el siguiente nivel de seguridad:
                <strong>{{ questionSelected.certainty }}</strong>
              </p>
              <div
                v-if="
                  ('detalle' in questionSelected.question &&
                    questionSelected.question.detalle !== null &&
                    questionSelected.question.detalle.length > 0) ||
                  questionSelected.question.detailImgUrl
                "
                class="text-body-2 explicacion"
              >
                <div>
                  {{ questionSelected.question.detalle }}
                </div>
                <v-img
                  v-if="questionSelected.question.detailImgUrl"
                  :src="questionSelected.question.detailImgUrl"
                  :aspect-ratio="16 / 9"
                  style="margin-bottom: 20px"
                  contain
                />
              </div>
              <div v-if="$vuetify.breakpoint.smAndDown" style="height: 80px">
                <v-btn
                  fab
                  tile
                  style="display: inline; float: left"
                  :text="!pressingLeft"
                  @click="selectPrev"
                >
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-btn
                  fab
                  tile
                  style="display: inline; float: right"
                  @click="selectNext"
                  :text="!pressingRight"
                >
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
              </div>
              <v-divider style="margin-bottom: 20px"></v-divider>
              <div style="float: left">
                <v-btn color="black" text @click="impugnationDialog = true"
                  >Impugnar</v-btn
                >
                <v-snackbar
                  v-model="showImpugnationSnackbar"
                  timeout="3000"
                  text
                  color="success"
                  style="margin-bottom: 60px"
                >
                  Impugnación enviada a revisión. Muchas gracias por ayudarnos a
                  mejorar
                </v-snackbar>
              </div>
              <div style="float: right">
                <v-icon
                  v-if="!questionSelected.saved"
                  @click="saveQuestionToCollection(null, null)"
                  large
                  >mdi-bookmark-outline</v-icon
                >
                <v-icon v-else @click="questionSelected.saved = false" large
                  >mdi-bookmark</v-icon
                >
                <v-snackbar
                  v-model="showSnackbar"
                  timeout="3000"
                  text
                  color="primary"
                  style="margin-bottom: 60px"
                >
                  Guardada en
                  <router-link
                    :to="{
                      name: 'collection',
                      params: { collectionId: lastCollectionSaved.id },
                    }"
                    ><strong>{{
                      lastCollectionSaved.title
                    }}</strong></router-link
                  >.
                  <span
                    style="text-decoration: underline; cursor: pointer"
                    @click="chooseCollectionDialog = true"
                    >Cambiar colección</span
                  >
                </v-snackbar>
                <v-dialog v-model="chooseCollectionDialog" max-width="300">
                  <v-card class="mx-auto" tile>
                    <v-list>
                      <v-subheader>Guardar en...</v-subheader>
                      <v-list-item-group color="primary">
                        <v-list-item
                          v-for="(item, i) in userQuestionCollections"
                          :key="i"
                          v-on="{
                            click: (e) => saveQuestionToCollection(item, true),
                          }"
                        >
                          <v-list-item-icon>
                            <v-icon>mdi-bookmark</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="item.title"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card>
                </v-dialog>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-btn
      v-if="!$vuetify.breakpoint.smAndDown"
      fab
      tile
      class="float"
      @click="selectNext"
      :text="!pressingRight"
    >
      <v-icon>mdi-arrow-right</v-icon>
    </v-btn>
    <v-btn
      v-if="!$vuetify.breakpoint.smAndDown"
      fab
      tile
      :text="!pressingLeft"
      class="float"
      style="left: 60px; right: auto"
      @click="selectPrev"
    >
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { collection, doc, getDoc, getFirestore } from "@firebase/firestore";
import { getAuth } from "@firebase/auth";
import {
  saveQuestion,
  getQuestionCollections,
  unsaveQuestion,
  createImpugnation,
  getLatestCorrectionBySimulationId,
} from "@/utils";

export default {
  name: "SubmissionReview",
  props: {
    submissionId: {
      required: true,
      type: String,
    },
  },
  created() {
    window.addEventListener("keydown", (e) => {
      // e.preventDefault();
      if (this.impugnationDialog) return;
      if (e.key == "ArrowRight" || e.key == "ArrowDown") {
        this.pressingRight = true;

        this.selectNext();
      } else if (e.key == "ArrowLeft" || e.key == "ArrowUp") {
        this.pressingLeft = true;

        this.selectPrev();
      }
    });
    window.addEventListener("keyup", (e) => {
      // e.preventDefault();
      if (this.impugnationDialog) return;
      if (e.key == "ArrowRight" || e.key == "ArrowDown") {
        this.pressingRight = false;
      } else if (e.key == "ArrowLeft" || e.key == "ArrowUp") {
        this.pressingLeft = false;
      }
    });
  },
  async mounted() {
    this.submission = await this.getSubmission();
    this.submission.test = this.submission.test.map((x) => ({
      ...x,
      saved: false,
    }));
    this.indexSelected = 0;
    this.questionSelected = this.submission.test[this.indexSelected];
    this.userQuestionCollections = await getQuestionCollections();

    this.simulation_correction = await getLatestCorrectionBySimulationId(
      this.submissionId
    );
    if (["Apto", "No apto"].includes(this.submission.grade)) {
      this.binaryGrade = true;
      this.displayableBinaryGrade = this.submission.grade;
    } else {
      this.displayableGrade =
        parseFloat(this.submission.grade.replace(",", ".")) * 10;

      var hue = (this.displayableGrade * 1.2).toString(10);
      this.gradeColor = ["hsl(", hue, ",80%,50%)"].join("");
    }

    this.loading = false;
  },
  data() {
    return {
      loading: true,
      submission: null,
      indexSelected: null,
      questionSelected: null,
      pressingLeft: false,
      pressingRight: false,
      showSnackbar: false,
      userQuestionCollections: null,
      chooseCollectionDialog: false,
      selectedItem: null,
      lastCollectionSaved: {
        id: "mis_preguntas",
        title: "Mis preguntas",
      },
      impugnationDialog: false,
      impugnationText: "",
      showImpugnationSnackbar: false,
      displayableGrade: null,
      binaryGrade: false,
      displayableBinaryGrade: null,
      gradeTooltip: false,

      simulation_correction: null,
      rankingDialog: false,
    };
  },
  methods: {
    selectNext() {
      if (this.indexSelected < this.submission.test.length - 1) {
        this.indexSelected += 1;
        this.questionSelected = this.submission.test[this.indexSelected];
      }
    },
    selectPrev() {
      if (this.indexSelected > 0) {
        this.indexSelected -= 1;
        this.questionSelected = this.submission.test[this.indexSelected];
      }
    },
    selectQuestion(e, q) {
      this.questionSelected = q;
    },

    saveQuestionToCollection(collection = null, unsaving = false) {
      if (!collection) {
        collection = this.lastCollectionSaved;
      }
      if (unsaving) {
        console.log("unsaving from ", this.lastCollectionSaved.title);
        unsaveQuestion(this.questionSelected, this.lastCollectionSaved.id);
      }
      console.log("saving to ", collection.title);
      saveQuestion(this.questionSelected, collection.id, collection.title);
      this.lastCollectionSaved = collection;
      this.questionSelected.saved = true;
      this.showSnackbar = true;
      this.chooseCollectionDialog = false;
    },

    async getSubmission() {
      try {
        const db = getFirestore();
        const auth = getAuth();
        const userId = auth.currentUser.uid;
        const userSubCollection = "submissions";
        const submissionSnapshot = await getDoc(
          doc(db, "users", userId, userSubCollection, this.submissionId)
        );
        return submissionSnapshot.data();
      } catch (error) {
        alert(error);
      }
    },
    async impugnateQuestion() {
      await createImpugnation(
        this.questionSelected.question,
        this.impugnationText
      );
      this.impugnationDialog = false;
      this.impugnationText = "";
      this.showImpugnationSnackbar = true;
    },
  },
};
</script>

<style scoped>
.question-num {
  font-size: 20px;
  margin: 8px;
  /* border: 1px solid red; */
}

.question-num:hover {
  text-decoration: underline;
  cursor: pointer;
}

.wrong {
  color: red;
  font-weight: bold;
}

#q {
  margin-bottom: 20px;
  padding-left: 10%;
  padding-right: 10%;
  alignment: center;
  cursor: auto;
}
.explicacion {
  background: hsl(0, 0%, 90%);
  padding: 16px;
  text-align: justify;
  margin-bottom: 25px;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  text-align: center;
}
</style>
