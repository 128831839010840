<template>
  <div style="height: 100%">
    <v-dialog v-model="savingDialog" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text style="padding: 20px">
          Registrando tus respuestas... Esto puede tardar unos segundos.
        </v-card-text>
        <v-progress-linear
          indeterminate
          color="blue darken-4"
        ></v-progress-linear>
      </v-card>
    </v-dialog>
    <v-card
      v-if="readyDialog"
      style="
        background: unset;
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      "
    >
      <div
        style="
          padding: 40px;
          background: white;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <v-card-title>{{ simulationMetadata.title }}</v-card-title>
        <v-card-text style="padding: 20px; font-size: large">
          <p>El simulacro está listo. <br /></p>
          <p>Una vez lo empiezes no podrás parar el temporizador. <br /></p>
          ¿Quieres empezar ahora?
        </v-card-text>
        <v-btn
          dark
          tile
          @click="
            readyDialog = false;
            registerStartedSubmission();
            startTimer();
          "
        >
          Estoy listo</v-btn
        >
      </div>
    </v-card>
    <v-card
      v-if="submissionFinished"
      style="
        background: unset;
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      "
    >
      <div
        style="
          padding: 40px;
          background: white;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <v-card-title>{{ simulationMetadata.title }}</v-card-title>
        <v-card-text style="padding: 20px; font-size: large">
          <p v-if="snackError">
            <v-icon color="error" style="margin-right: 10px">mdi-alert</v-icon
            >{{ snackError }}
          </p>
          <p v-else>
            <v-icon color="green" style="margin-right: 10px"
              >mdi-check-circle-outline</v-icon
            >Tu examen ya ha sido registrado <br />
          </p>
        </v-card-text>
        <v-btn dark tile @click="$router.push({ name: 'home' })">
          Ir al inicio</v-btn
        >
      </div>
    </v-card>
    <div v-else>
      <v-btn
        v-if="!loading && testMode == 'timed'"
        tile
        x-large
        style="margin-top: 40px; border: 6px solid black"
        elevation="6"
      >
        <div v-if="testMode == 'timed'" id="clockdiv_exam">{{ clock }}</div>
      </v-btn>
      <pager v-if="!loading" v-model="pager" @finish-exam="finishExam" />
      <Question
        v-for="q in displayedQuestions"
        :key="q.index"
        :number="q.index + 1"
        :question="q"
        :last="(q.index + 1) % pager.perPage == 0"
        :readonly="1 == 2"
        :instant="testMode == 'instant'"
        @option-selected="registerAnswer"
        @set-certainty="setCertainty"
      />
      <pager
        v-if="displayedQuestions.length > 1"
        v-model="pager"
        @finish-exam="finishExam"
      />
    </div>
    <v-snackbar
      v-model="showSnackbar"
      timeout="3000"
      text
      color="error"
      style="margin-bottom: 60px"
    >
      <span>{{ snackError }}</span>
    </v-snackbar>
    <v-snackbar
      v-model="showSuccessSnackbar"
      timeout="3000"
      text
      color="success"
      style="margin-bottom: 60px"
    >
      <span>{{ snackSuccess }}</span>
    </v-snackbar>
  </div>
</template>

<script>
// import * as firebase from "firebase/app";
// import "firebase/firestore";
import { saveQuestion, registerAnswerEvent } from "@/utils";
import Question from "@/components/test/Question.vue";
import Pager from "@/components/test/Pager.vue";
import {
  addDoc,
  collection,
  getDoc,
  doc,
  getFirestore,
  setDoc,
  updateDoc,
} from "@firebase/firestore";
import { getAuth } from "@firebase/auth";

// import { default as QuestionType } from "@/models/question/interface";
// import { Answer } from "@/models/answer/interface";
// import UserSubmission from "@/models/user_submission/interface";
// import { LogLevels } from "@/models/log/interface";
// import Test from "@/models/test/interface";
// import { PermissionLevel } from "@/models/permissions/interface";
// import SubscriptionNeeded from "@/components/SubscriptionNeeded.vue";
// import { SimulationMode } from "@/models/simulation_mode/interface";

// export interface ExamQuestion {
//   index: number;
//   question;
//   answer;
// }

// export enum QuestionsToShow {
//   ALL,
//   WRONG
// }

export default {
  name: "Test",
  components: {
    Question,
    Pager,
  },
  props: {
    testId: {
      type: String,
      default: "example",
      // required: true
    },
    mode: {
      type: String,
      required: false,
      default: "instant",
      // regular | timed | instant
    },
  },
  data() {
    return {
      pager: {
        page: 1,

        disabled: false,
        length: 10,
        perPage: 3,
        finishEnabled: true,
        filter: "ALL",
      },
      loading: true,
      pages: [],
      exam: [],
      isEditing: false,
      currentSubmissionId: null,
      testMetadata: { testName: "example" },
      submissionStarted: new Date(),
      db: null,
      savingDialog: false,
      showSnackbar: false,
      snackError: "",
      showSuccessSnackbar: false,
      snackSuccess: "",
      testMode: null,
      simulationMetadata: null,
      readyDialog: false,
      clock: "00:00:00",
      submissionFinished: false,
    };
  },
  computed: {
    displayedQuestions() {
      return this.paginate(this.exam);
    },

    // filteredQuestions() {
    //   let filter = q => true;
    //   if (this.pager.filter == 'WRONG') {
    //     filter = q => q.answer != q.question.correcta;
    //   }
    //   return this.exam.filter(filter);
    // }
  },
  watch: {
    "pager.perPage"() {
      this.setPages();
      this.pager.page = 1;
    },
    "pager.filter"() {
      this.setPages();
    },
    snackError() {
      this.showSnackbar = true;
    },
    snackSuccess() {
      this.showSuccessSnackbar = true;
    },
  },
  async created() {
    this.loading = true;
    this.testMode = this.determineMode();
    this.db = getFirestore();
    try {
      if (this.testMode == "timed") {
        await this.initTimedExam(this.testId);
      } else {
        await this.initNonTimedExam(this.testId);
      }
    } catch (error) {
      console.log(error);
      this.snackError = "Hubo un error al cargar el test";
    }
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (
        !window.confirm(
          "¿Seguro que quieres salir? Los cambios no se guardarán."
        )
      ) {
        return;
      }
    }
    next();
  },

  methods: {
    async initNonTimedExam(testId) {
      this.exam = await this.getExam(testId);
      this.setPages();
      this.initQuestions();
      this.isEditing = true;
      this.loading = false;
    },
    async registerStartedSubmission() {
      const db = getFirestore();
      const auth = getAuth();
      const userId = auth.currentUser.uid;
      const submission = {
        mode: this.testMode,
        started: new Date(),
        ended: null,
        simulationId: this.testId,
        testId: this.simulationMetadata.testId,
        testName: this.simulationMetadata.title,
        userId: userId,
        userDisplayName: auth.currentUser.displayName,
      };
      await setDoc(
        doc(db, "users", userId, "submissions", this.testId),
        submission
      );
    },
    async registerEndedSubmission() {
      this.savingDialog = true;
      const db = getFirestore();
      const auth = getAuth();
      const userId = auth.currentUser.uid;
      const submission = {
        ended: new Date(),
        test: this.exam,
        grade: "Próximamente",
        userId: userId,
        userDisplayName: auth.currentUser.displayName,
      };
      await updateDoc(
        doc(db, "users", userId, "submissions", this.testId),
        submission
      );
      this.savingDialog = false;
      this.submissionFinished = true;
      this.isEditing = false;
    },
    async startTimer(consumedSeconds = 0) {
      this.initializeClock(
        this.getUnixSeconds() +
          parseInt(this.simulationMetadata.maxSeconds) -
          consumedSeconds
      );
      await this.initNonTimedExam(this.simulationMetadata.testId);
    },
    async getExistingSubmission() {
      const subm = await getDoc(
        doc(
          getFirestore(),
          "users",
          getAuth().currentUser.uid,
          "submissions",
          this.testId
        )
      );
      return subm.data();
    },
    async initTimedExam(simulationId) {
      const simulation = await getDoc(
        doc(this.db, "simulations", simulationId)
      );
      const simulationData = simulation.data();
      this.simulationMetadata = simulationData;
      if (
        !this.isExamPeriod(
          simulationData.startDate.toDate(),
          simulationData.endDate.toDate()
        )
      ) {
        this.snackError = "El plazo del simulacro se encuentra cerrado";
        this.submissionFinished = true;
      } else {
        const existingSubmission = await this.getExistingSubmission();
        if (existingSubmission) {
          if (existingSubmission.ended) {
            this.submissionFinished = true;
          } else if (
            !existingSubmission.ended &&
            this.getUnixSeconds() - existingSubmission.started.seconds >=
              this.simulationMetadata.maxSeconds
          ) {
            this.snackError = "Tu examen no se ha entregado dentro del plazo";
            this.submissionFinished = true;
          } else {
            this.startTimer(
              this.getUnixSeconds() - existingSubmission.started.seconds
            );
          }
        } else {
          this.readyDialog = true;
        }
      }
    },
    determineMode() {
      if (this.$route.path.includes("/simulacro/")) {
        return "timed";
      } else {
        return this.mode;
      }
    },
    async getQuestion(qid) {
      const q = await getDoc(doc(this.db, "questions", qid));
      return { qid: q.id, ...q.data() };
    },
    async getExam(testId) {
      const slimTest = await getDoc(doc(this.db, "tests", testId));
      const slimTestData = slimTest.data();
      this.testMetadata = slimTestData;
      slimTestData.questions = await Promise.all(
        slimTestData.questionIds.map((qid) => this.getQuestion(qid))
      );
      return slimTestData.questions;
    },

    async initQuestions() {
      for (let index = 0; index < this.exam.length; index++) {
        const examQuestion = {
          index: index,
          question: this.exam[index],
          answer: null,
        };
        this.exam[index] = examQuestion;
      }
    },
    isExamPeriod(startDate, endDate) {
      const currentDate = new Date();
      const isExamPeriod = startDate <= currentDate && currentDate <= endDate;
      return isExamPeriod;
    },
    // getActiveSimulationTestId() {
    //   return this.$store.dispatch("tests/getActiveSimulationTestId")
    // },
    // async userCanAccessTest(testId) {
    //   if (this.isReviewMode) return true;

    //   if (this.testMetadata.isTimed) {
    //     switch (this.testMetadata.simulation_mode) {
    //       case SimulationMode.OPEN:
    //         return true;
    //       case SimulationMode.ONLY_PURCHASERS: {
    //         const access = await this.userIsPurchaser(testId);
    //         if (!access) {
    //           this.$store.commit("showMessage", {
    //             text: "Este simulacro es de pago",
    //             color: "error"
    //           });
    //         }
    //         return access;
    //       }
    //       case SimulationMode.PURCHASERS_AND_PREMIUM: {
    //         const prem = this.userIsPremium();
    //         if (prem) return true;
    //         const purc = await this.userIsPurchaser(testId);
    //         if (!purc) {
    //           this.$store.commit("showMessage", {
    //             text: "Este simulacro es para compradores y alumnos",
    //             color: "error"
    //           });
    //         }
    //         return purc;
    //       }
    //       case SimulationMode.ONLY_PREMIUM: {
    //         const prem = this.userIsPremium();
    //         if (!prem) {
    //           this.$store.commit("showMessage", {
    //             text: "Este simulacro es solo para alumnos",
    //             color: "error"
    //           });
    //         }
    //         return prem;
    //       }
    //     }
    //   } else {
    //     const access = this.userIsPremium();
    //     if (!access) {
    //       this.$store.commit("showMessage", {
    //         text: "Necesitas una suscripción a la academia para acceder al contenido",
    //         color: "error"
    //       });
    //     }
    //     return access;
    //   }
    // },
    // async userIsPurchaser(testId) {
    //     const userEmail = this.$store.state.user.email;
    //     return new Promise((resolve, reject) => {
    //     firebase
    //       .firestore()
    //       .collection("resources")
    //       .doc("restricted_tests")
    //       .get()
    //       .then(doc => {
    //         if (doc.exists) {
    //           if (doc.get(testId)) {
    //             const data = doc.data()
    //             if (data[testId].includes(userEmail)) {
    //               resolve(true)
    //             } else {
    //               resolve(false);
    //             }
    //           } else {
    //             resolve(false);
    //           }
    //         } else {
    //           reject("Document restricted_tests does not exist");
    //         }
    //       })
    //       .catch(error => {
    //         console.log(error);
    //         resolve(false);
    //       });
    //   });
    // },
    // initRegularSubmission() {
    //   const emptySubmission = {
    //     submissionId: null,
    //     userId: this.$store.state.user.id,
    //     started: new Date(),
    //     ended: null,
    //     answers: null,
    //     testId: this.testId,
    //     position: null,
    //     grade: null,
    //     testName: this.testMetadata.title
    //   };
    //   this.$store
    //     .dispatch("tests/startSubmission", emptySubmission)
    //     .then((submission) => {
    //       this.currentSubmissionId = submission.submissionId;
    //     })
    //     .catch(error => {

    //     });
    // },

    // initTimedSubmission(test) {
    //   // First check if user has existing submission for the test
    //   // If there is a submission it is taken and time is continued
    //   // If there isn't any, a fresh submission is created
    //   const currentDate = new Date();
    //   const isExamPeriod =
    //     test.startDate.toDate() <= currentDate &&
    //     currentDate <= test.endDate.toDate();
    //   if (test.startDate.toDate() > currentDate) {
    //     this.exitPage("El examen todavía no ha empezado");
    //     return;
    //   }
    //   else if (!isExamPeriod) {
    //     this.exitPage("El examen no está disponible");
    //     return;
    //   } else {
    //     this.$store
    //       .dispatch("tests/getUserSubmission", {
    //         testId: this.testId,
    //         userId: this.$store.state.user.id
    //       })
    //       .then(existingSubmission => {
    //         if (existingSubmission != null) {
    //           if (existingSubmission.ended) {
    //             this.exitPage("Tu examen ya ha sido registrado.");
    //           } else if (
    //             !existingSubmission.ended &&
    //             this.getUnixSeconds() - existingSubmission.started.seconds >=
    //               test.maxSeconds
    //           ) {
    //             this.exitPage("Tu examen no se ha entregado dentro del plazo");
    //           } else {
    //             this.currentSubmissionId = existingSubmission.submissionId;
    //             this.initializeClock(
    //               "clockdiv_exam",
    //               existingSubmission.started.seconds + test.maxSeconds
    //             );
    //           }
    //         } else {
    //           const emptySubmission: UserSubmission = {
    //             submissionId: null,
    //             userId: this.$store.state.user.id,
    //             started: new Date(),
    //             ended: null,
    //             answers: null,
    //             testId: this.testId,
    //             position: null,
    //             grade: null,
    //             testName: this.testMetadata.title
    //           };
    //           if (confirm('¿Estás listo para empezar el examen? Una vez empiece a contar el tiempo tendrás que entregarlo en el plazo establecido.')) {
    //             this.$store
    //               .dispatch("tests/startSubmission", emptySubmission)
    //               .then(submission => {
    //                 this.currentSubmissionId = submission.submissionId;
    //                 this.initializeClock(
    //                   "clockdiv_exam",
    //                   submission.started.seconds + test.maxSeconds
    //                 );
    //               })
    //               .catch(error => {
    //                 this.$store.dispatch("log", {
    //                   level: LogLevels.ERROR,
    //                   message: error
    //                 });
    //               });
    //           } else {
    //             this.isEditing = false;
    //             this.$router.go(-1);
    //           }
    //         }
    //       });
    //   }
    // },

    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = "";
    },

    getTimeRemaining: function (endtimeSecs) {
      const total = (endtimeSecs - this.getUnixSeconds()) * 1000;
      const seconds = Math.floor((total / 1000) % 60);
      const minutes = Math.floor((total / 1000 / 60) % 60);
      const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
      const days = Math.floor(total / (1000 * 60 * 60 * 24));
      return {
        total,
        days,
        hours,
        minutes,
        seconds,
      };
    },

    initializeClock: function (endtime) {
      this.examinterval = setInterval(() => {
        const t = this.getTimeRemaining(endtime);
        this.clock =
          // (t.seconds % 2 == 0 ? "⌛️ " : "⏳ ") +
          (t.hours < 10 ? "0" : "") +
          t.hours +
          ":" +
          (t.minutes < 10 ? "0" : "") +
          t.minutes +
          ":" +
          (t.seconds < 10 ? "0" : "") +
          t.seconds;
        // + (t.seconds % 2 != 0 ? " ⌛️" : " ⏳");
        if (t.total <= 0) {
          clearInterval(this.examinterval);
          this.registerEndedSubmission();
        }
      }, 1000);
    },

    getUnixSeconds() {
      return Math.floor(Date.now() / 1000);
    },

    initTest(questions) {
      for (let index = 0; index < questions.length; index++) {
        const examQuestion = {
          index: index,
          question: questions[index],
          answer: null,
        };
        this.exam.push(examQuestion);
      }
    },

    setPages() {
      this.pager.length = Math.ceil(this.exam.length / this.pager.perPage);
      for (let index = 1; index <= this.pager.length; index++) {
        this.pages.push(index);
      }
    },

    paginate(exam) {
      const page = this.pager.page;
      const perPage = this.pager.perPage;
      const from = page * perPage - perPage;
      const to = page * perPage;
      return exam.slice(from, to);
    },

    nextPage() {
      this.pager.page = this.pager.page + 1;
    },

    registerAnswer(id, selected) {
      this.exam[id].answer = selected;
      if (this.testMode == "instant") {
        registerAnswerEvent(this.exam[id].question, selected);
      }
    },

    setCertainty(id, value) {
      this.exam[id].certainty = value;
    },

    checkUnansweredQuestionsAndNotify() {
      const c = [];
      for (let i = 0; i < this.exam.length; ++i) {
        if (this.exam[i].answer == null) {
          c.push(i);
        }
      }
      if (c.length > 0) {
        // TODO: Use a dialog
        return confirm(
          "Todavía tienes preguntas por contestar. Quieres terminar?"
        );
      } else {
        return true;
      }
    },

    gradeTest() {
      const sumPerCorrect = 10.0 / this.exam.length;
      const substractPerWrong = sumPerCorrect / 4.0;
      let grade = 0.0;
      this.exam.forEach((question) => {
        if (this.testMode == "regular") {
          registerAnswerEvent(question.question, question.answer);
        }
        if (question.question.correcta == question.answer) {
          grade += sumPerCorrect;
        } else {
          // Unselected questions do not substract
          if (question.answer) {
            grade -= substractPerWrong;
            saveQuestion(question, "falladas", "Preguntas falladas");
            if (question.certainty) {
              saveQuestion(
                question,
                question.certainty,
                `Respuesta ${question.certainty}`
              );
            }
          }
        }
      });
      if (grade < 0) grade = 0.0;
      return grade.toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    },

    async finishExam() {
      //this.checkUnanswered();
      if (
        confirm(
          "Estás a punto de finalizar el examen. No podrás editar tus respuestas ¿Quieres continuar?"
        )
      ) {
        if (this.testMode == "timed") {
          await this.registerEndedSubmission();
        } else {
          await this.submitExam();
        }
      }
    },

    async submitExam() {
      try {
        if (this.testMode == "regular") {
          this.savingDialog = true;
        }
        const grade = this.gradeTest();
        const db = getFirestore();
        const auth = getAuth();
        const userId = auth.currentUser.uid;
        const userSubCollection = "submissions";
        const submission = {
          mode: this.testMode,
          started: this.submissionStarted,
          ended: new Date(),
          testId: this.testId,
          testName: this.testMetadata.title || null,
          test: this.exam,
          grade: this.testMode == "timed" ? "Próximamente" : grade,
          userId: userId,
          userDisplayName: auth.currentUser.displayName,
        };
        const docRef = await addDoc(
          collection(db, "users", userId, userSubCollection),
          submission
        );
        this.savingDialog = false;
        alert("Tu test ha sido registrado satisfactoriamente");
        this.isEditing = false;
        if (this.testMode == "regular") this.goToCorrection(docRef.id);
        else this.$router.go(-1);
      } catch (error) {
        alert("Algo no ha ido como debía: " + error);
      }
    },

    goToCorrection(submissionId) {
      this.$router.push({
        name: "submission_review",
        params: { submissionId: submissionId },
      });
    },

    exitPage(message) {
      clearInterval(this.examinterval);
      this.isEditing = false;
      alert(message);
      //this.$router.push({ name: "landing_simulacro" });
    },
  },
};
</script>

<style></style>
