import Vue from 'vue'
import App from './App.vue'
import router from './router'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, getDoc, doc } from "firebase/firestore";
import vuetify from './plugins/vuetify'


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAarUpPoSdmQqArqmAHBs_38dxHB9OF8Dc",
  authDomain: "plataforma-de-tests.firebaseapp.com",
  projectId: "plataforma-de-tests",
  storageBucket: "plataforma-de-tests.appspot.com",
  messagingSenderId: "917724398124",
  appId: "1:917724398124:web:74bfea83960409b78f4789",
  measurementId: "G-P1W3F7J5NW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore();
const auth = getAuth();

let callback = null;
let metadataRef = null;
onAuthStateChanged(auth, (user) => {
  if (callback) {
    metadataRef.off('value', callback);
  }
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    const uid = user.uid;
    auth.currentUser.getIdToken(true).then(token => {
      //     console.log(token)
      auth.currentUser.getIdTokenResult().then(decodedToken => {
        const role = decodedToken.claims.stripeRole;
        //console.log('user role:',role)
      })
    })
    console.log('User signed in');

  } else {
    console.log('User signed out')
    router.push({ 'name': 'entrar' })
    // User is signed out
    // ...
  }
});


Vue.config.productionTip = false

Vue.prototype.$opoIden = { value: 'unknown' };

// let globalData = new Vue({
//   data: { $opoId: 'Bomberos' }
// });
// Vue.mixin({
//   computed: {
//     $opoId: {
//       get: function () { return globalData.$data.$opoId },
//       set: function (newOpoId) { globalData.$data.$opoId = newOpoId; }
//     }
//   }
// })

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
