<template>
  <v-container>
    <h1
      style="
        padding-bottom: 20px;
        font-family: 'Saira Semi Condensed', sans-serif;
      "
    >
      Clasificación {{ monthDisplay }} {{ year }}
    </h1>
    <v-row v-if="loading" align="center" justify="center">
      <v-data-table
        v-if="loading || !rankingData"
        :headers="rankingTable.headers"
        loading
        loading-text="Cargando el ranking... Por favor espera"
      ></v-data-table>
    </v-row>
    <div v-else>
      <div style="padding-bottom: 20px">
        <v-data-table
          :headers="rankingTable.headers"
          :items="rankingData"
          :items-per-page="-1"
          :hide-default-footer="true"
          :sort-by="['position']"
          :sort-desc="false"
          :selectable="false"
          :disable-sort="true"
          class="elevation-1"
          no-data-text="No hay respuestas todavía"
        >
          <template v-slot:item.position="{ item }"
            ><div style="text-align: center; align-items: center">
              <div v-if="item.position === 1" style="display: inline-block">
                <v-img src="@/assets/gold.png" height="30" width="30"></v-img>
              </div>
              <div
                v-else-if="item.position === 2"
                style="display: inline-block"
              >
                <v-img src="@/assets/silver.png" height="30" width="30"></v-img>
              </div>
              <div
                v-else-if="item.position === 3"
                style="display: inline-block"
              >
                <v-img src="@/assets/bronze.png" height="30" width="30"></v-img>
              </div>
              <strong v-else :class="{ podium: item.position <= 3 }">{{
                item.position
              }}</strong>
            </div>
          </template>
          <template v-slot:item.userDisplayName="{ item }">
            <span
              v-if="item.position <= 3"
              style="font-weight: 500; font-size: larger"
              >{{ item.userDisplayName }}</span
            >
            <span v-else>
              {{ item.userDisplayName }}
            </span>
          </template>
        </v-data-table>
        <small style="float: right; margin-top: 10px"
          >Actualizado: {{ updatedAt }}</small
        >
      </div>
    </div>
  </v-container>
</template>

<script>
import { getCurrentRanking } from "@/utils.js";
export default {
  name: "Ranking",
  data: () => ({
    loading: false,
    rawRanking: null,
    rankingData: null,
    updatedAt: null,
    monthDisplay: "",
    year: null,
    rankingTable: {
      headers: [
        {
          text: "Posición",
          value: "position",
          sortable: true,
        },
        {
          text: "Nombre",
          value: "userDisplayName",
          sortable: true,
        },
        {
          text: "Aciertos",
          value: "correct",
          sortable: true,
        },
        {
          text: "Respondidas",
          value: "answered",
          sortable: true,
        },
        {
          text: "Puntuación",
          value: "score",
          sortable: true,
        },
      ],
    },
  }),
  async created() {
    await this.loadRanking();
  },
  methods: {
    mapRanking(ranking) {
      this.year = ranking.year;
      this.monthDisplay = ranking.monthDisplay;
      this.updatedAt =
        ranking.periodEnd.toDate().toLocaleDateString() +
        " " +
        ranking.periodEnd.toDate().toLocaleTimeString();

      ranking.ranking.forEach((obj, i) => {
        obj.position = i + 1;
        obj.score = obj.score.toFixed(2);
      });
      return ranking.ranking;
    },

    async loadRanking() {
      this.loading = true;
      try {
        this.rawRanking = await getCurrentRanking();
        this.rankingData = this.mapRanking(this.rawRanking);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.v-data-table tbody tr {
  pointer-events: none;
}

@media (min-width: 600px) {
  .v-data-table tbody tr:nth-child(1) {
    height: 208px;
    font-weight: 500;
    background: linear-gradient(to right, #ffd90036, #ffa60036);
  }

  v-data-table tbody tr:nth-child(1) td {
    font-size: larger !important;
  }

  v-data-table tbody tr:nth-child(2) td {
    font-size: larger !important;
  }

  v-data-table tbody tr:nth-child(3) td {
    font-size: larger !important;
  }

  v-data-table tbody tr:nth-child(2) {
    height: 129px;
    font-weight: 500;
    background: linear-gradient(to right, #c0c0c036, #e5e5e536);
  }
  v-data-table tbody tr:nth-child(3) {
    height: 80px;
    font-weight: 500;
    background: linear-gradient(to right, #cd7f3236, #965a3836);
  }
}
</style>