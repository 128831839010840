<template>
  <v-card>
    <v-col v-if="!loading">
      <v-textarea
        v-model="q.text"
        auto-grow
        outlined
        rows="1"
        type="text"
        autocomplete="off"
        label="Pregunta"
      />
      <v-select
        v-model="q.dificulty"
        :items="['Fácil', 'Media', 'Difícil']"
        label="Dificultad"
      />
      <v-select
        v-model="q.tags"
        :items="availableCategories"
        label="Categorías"
        item-text="name"
        item-value="code"
        multiple
        chips
      ></v-select>
      <v-img
        v-if="q.imgUrl"
        :src="q.imgUrl"
        :aspect-ratio="16 / 9"
        :height="200"
        style="margin-bottom: 20px"
        contain
      />
      <v-btn
        v-if="q.imgUrl"
        color="error"
        x-small
        @click="
          q.imgUrl = null;
          q.questionImage = null;
        "
      >
        Quitar imagen
      </v-btn>
      <v-file-input
        v-model="q.questionImage"
        prepend-icon="mdi-camera"
        accept="image/png, image/jpeg"
        label="Subir nueva imagen de pregunta"
        show-size
        :multiple="false"
        v-on="{ change: (e) => onChangeFileInput(e, q) }"
      />
      <div>
        <v-textarea
          v-for="opt in Object.keys(q.options).sort()"
          :key="opt"
          v-model="q.options[opt]"
          auto-grow
          outlined
          rows="1"
          type="text"
          autocomplete="off"
          :label="opt"
        />
        <v-row>
          <v-radio-group v-model="q.correcta" row>
            <v-radio
              v-for="opt in Object.keys(q.options).sort()"
              :key="opt"
              :label="opt"
              color="red"
              :value="opt"
            />
          </v-radio-group>
        </v-row>
      </div>
      <v-textarea
        v-model="q.detalle"
        auto-grow
        outlined
        rows="1"
        type="text"
        autocomplete="off"
        label="Explicación"
      />
      <v-img
        v-if="q.detailImgUrl"
        :src="q.detailImgUrl"
        :aspect-ratio="16 / 9"
        :height="200"
        style="margin-bottom: 20px"
        contain
      />
      <v-btn
        v-if="q.detailImgUrl"
        color="error"
        x-small
        @click="
          q.detailImgUrl = null;
          q.detailImage = null;
        "
      >
        Quitar imagen
      </v-btn>
      <v-file-input
        v-model="q.detailImage"
        prepend-icon="mdi-camera"
        accept="image/png, image/jpeg"
        label="Subir nueva imagen de explicación"
        show-size
        :multiple="false"
        v-on="{ change: (e) => onChangeDetailFileInput(e, q) }"
      />
    </v-col>
    <v-card-actions style="display: inline-flex">
      <v-btn @click="$emit('close-dialog')"> Cancelar </v-btn>
      <v-btn @click="updateQuestion" color="success"> Actualizar </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { getDoc, doc, getFirestore, setDoc } from "@firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
} from "@firebase/storage";

import { getQuestionById } from "@/utils";

export default {
  name: "QuestionEdit",
  props: {
    qid: {
      required: true,
    },
    availableCategories: {
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      q: null,
      db: null,
    };
  },
  watch: {
    qid: async function (value) {
      console.log("hello");
      this.loading = true;
      this.q = await this.getQuestion(value);
      this.loading = false;
    },
  },
  async created() {
    console.log("in created");
    this.db = getFirestore();
    this.q = await this.getQuestion(this.qid);
    this.loading = false;
  },
  methods: {
    onChangeFileInput(e, q) {
      if (q.questionImage) {
        const storage = getStorage();
        //const storageRef = ref(storage, 'question_images/qid.jpeg');
        const storageRef = ref(storage, `question_images/${q.qid}.jpeg`);
        console.log(q.questionImage);

        uploadBytes(storageRef, q.questionImage).then(() => {
          getDownloadURL(storageRef).then((downloadURL) => {
            console.log("File at ", downloadURL);
            q.imgUrl = downloadURL;
          });
          console.log("Uploaded a blob or file!");
        });
      } else {
        q.imgUrl = null;
      }
    },
    onChangeDetailFileInput(e, q) {
      if (q.detailImage) {
        const storage = getStorage();
        const storageRef = ref(storage, `detail_images/${q.qid}.jpeg`);
        console.log(q.detailImage);

        uploadBytes(storageRef, q.detailImage).then(() => {
          getDownloadURL(storageRef).then((downloadURL) => {
            console.log("File at ", downloadURL);
            q.detailImgUrl = downloadURL;
          });
          console.log("Uploaded a blob or file!");
        });
      } else {
        q.detailImgUrl = null;
      }
    },
    async getQuestion(qid) {
      return await getQuestionById(qid);
    },

    async updateQuestion() {
      await this.registerQuestion(this.q);
      this.$emit("close-dialog");
    },

    async registerQuestion(question) {
      function amplifyTags(tags) {
        function amplify(tag) {
          if (!tag.includes("..")) return [tag];
          const nodes = tag.split("..");
          const subtags = [nodes[0]];
          let subst = nodes[0];
          const otherNodes = nodes.slice(1);
          for (const node of otherNodes) {
            subtags.push(subst + ".." + node);
            subst = subst + ".." + node;
          }
          return subtags;
        }
        function onlyUnique(value, index, self) {
          return self.indexOf(value) === index;
        }
        const amplified = [];
        for (const tag of tags) {
          amplified.push(...amplify(tag));
        }
        return amplified.filter(onlyUnique);
      }
      const clean = question;
      delete clean.questionImage;
      delete clean.detailImage;
      question.tags = amplifyTags(question.tags);
      const questionRef = doc(getFirestore(), "questions", question.qid);
      await setDoc(questionRef, clean);
    },
  },
};
</script>
